import {Component, OnDestroy, OnInit} from '@angular/core';
import {LocalState, SortingRule} from "../../utils/sorting-utils";
import {SchoolStudentStatus, Student} from "../../../auth_profile/model/casa/casa-model";
import {Page, Pageable} from "../../../utils/pageable";
import {debounceTime, forkJoin, map, Subject, Subscription, switchMap, take, tap, zip} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {LocalStateService} from "../../../utils/services/local-state.service";
import {PersonUtils} from "../../../auth_profile/utils/person-utils";
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {AuthorizationServiceProvider} from "../../../auth_profile/services/authorization-service.provider";
import {SpinnerService} from "../../../utils/services/spinner.service";

class StudentsSortingRule extends SortingRule {
  constructor(public name: string, sorting: string[], public status: string[], public totalLabel: string) {
    super(sorting);
  }

  static Natural = new StudentsSortingRule("All", null, null, "All students");
  static Newest = new StudentsSortingRule("Active (newest first)", ["createDate,DESC"], [SchoolStudentStatus.ACTIVE], "Active students")
  static NameAsc = new StudentsSortingRule("All (ascending)", ["student.person.surname,ASC"], null, "All students");
  static NameDesc = new StudentsSortingRule("All (descending)", ["student.person.surname,DESC"], null, "All students");
  static Inactive = new StudentsSortingRule("Inactive", ["expiryDate,ASC"], [SchoolStudentStatus.INACTIVE], "Inactive students");
  static Expiring = new StudentsSortingRule("Expiring first", ["expiryDate,ASC"], [SchoolStudentStatus.ACTIVE], "Active students");
  static values = [StudentsSortingRule.Natural, StudentsSortingRule.Newest, StudentsSortingRule.NameAsc, StudentsSortingRule.NameDesc, StudentsSortingRule.Inactive, StudentsSortingRule.Expiring];
}

@Component({
  selector: 'app-teacher-students-list-page',
  templateUrl: './teacher-students-list-page.component.html',
  styleUrls: ['./teacher-students-list-page.component.scss']
})
export class TeacherStudentsListPageComponent implements OnInit, OnDestroy {

  currentState: LocalState<StudentsSortingRule>;
  studentSortingRules = StudentsSortingRule.values;
  private schoolId: number;
  private studentsPage: Page<Student>;
  private studentsStateUrl = "dashboard/students";
  private searchTermUpdates = new Subject<string>();
  private readonly searchTermUpdatesSubscription: Subscription;

  constructor(
    route: ActivatedRoute,
    private authService: AuthorizationServiceProvider,
    private provider: NativeServiceApiProvider,
    private localState: LocalStateService,
    private router: Router,
    private spinner: SpinnerService
  ) {
    this.loadSchoolId().pipe(
      switchMap(_ => forkJoin([
        this.findLocalState(),
        this.getTermQueryParam(route)
      ])),
      switchMap(([state, term]) => {
        if (term) {
          state.term = term;
        }
        return this.loadStudentsPage(state);
      }),
    ).subscribe()

    this.searchTermUpdatesSubscription = this.searchTermUpdates.pipe(
      debounceTime(400)
    ).subscribe(_ => this.doSearch());
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.searchTermUpdatesSubscription) {
      this.searchTermUpdatesSubscription.unsubscribe();
    }
  }

  private loadSchoolId() {
    return this.authService.getAuthDetailsService().pipe(
      switchMap(api => api.getSelfSchoolId()),
      tap(schoolId => this.schoolId = schoolId)
    )
  }

  private getTermQueryParam(route: ActivatedRoute) {
    return route.queryParamMap.pipe(
      take(1),
      map(paramMap => paramMap.get('term'))
    );
  }

  private findLocalState() {
    return this.localState.get<LocalState<StudentsSortingRule>>(
      this.studentsStateUrl,
      () => new LocalState("", StudentsSortingRule.Natural, Pageable.of(0, 50, null)))
  }


  set searchTerm(value: string) {
    if (this.currentState) this.currentState.term = value;
    this.searchTermUpdates.next(value);
  }

  get searchTerm(): string {
    if (this.currentState) return this.currentState.term;
    return '';
  }

  private loadStudentsPage(state: LocalState<StudentsSortingRule>) {
    this.currentState = state;
    return this.spinner.trace(
      zip(this.provider.video(), this.localState.set(this.studentsStateUrl, state)).pipe(
        switchMap(([api, state]) => api.getTeacherStudents(this.schoolId, state.term, state.sortingRule.status, null, state.page)),
        tap(studentsPage => this.storeStudentsPage(studentsPage))
      )
    )
  }

  private storeStudentsPage(studentsPage: Page<Student>) {
    this.studentsPage = studentsPage;
  }

  getName(student: Student) {
    return PersonUtils.getName(student.person);
  }

  getExpiry(student: Student) {
    return student.expiryDate;
  }

  getEmail(student: Student) {
    return student.person.registrationEmail;
  }

  getInitials(student: Student) {
    return PersonUtils.getInitials(student.person.details)
  }

  countTotalStudents() {
    if (!this.studentsPage) return 0;
    return this.studentsPage.totalElements;
  }

  doSearch() {
    this.loadStudentsPage(this.currentState.first()).subscribe();
  }

  searchUpdated() {
    this.currentState.applySorting();
  }

  getStudentsPage() {
    return this.studentsPage.content;
  }

  hasPages() {
    return this.hasPrev() || this.hasNext();
  }

  prev() {
    this.loadStudentsPage(this.currentState.prev()).subscribe();
  }

  hasPrev() {
    return this.studentsPage && !this.studentsPage.first;
  }

  getPageNumber() {
    return this.studentsPage.number + 1;
  }

  hasNext() {
    return this.studentsPage && !this.studentsPage.last;
  }

  next() {
    this.loadStudentsPage(this.currentState.next()).subscribe();
  }

  getStatus(student: Student) {
    return student.status;
  }

  hasStudents() {
    return this.studentsPage;
  }

  openStudent(student: Student) {
    this.router.navigate(['teacher', 'students', student.id, 'cspa-monitor']).then();
  }

  getCreated(student: Student) {
    return student.created;
  }
}
