<div class="workspace bg-radial">
  <top-bar [titleTranslationCode]="'NAVIGATION.STUDENTS'"
           [redirectLink]="'../'"
           [redirectTranslationCodes]="'NAVIGATION.STUDENTS'"
           class="accent"
  >
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" aria-current="page">
                <a routerLink="../">
                  {{ "NAVIGATION.STUDENTS" | translate }}
                </a>
              </li>
              <li class="breadcrumb-item active" *ngIf="student">{{ getPersonName() }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <div class="container mt-md-3">
    <div class="row position-relative mb-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
    <div class="card border-0 py-2" *ngIf="student">
      <div class="card-body row">
        <div class="col-auto order-0">
          <div class="d-flex rounded-circle text-center bg-secondary me-2 initials-circle-lg my-auto">
            <span class="m-auto small text-white">{{ getInitials(student) }}</span>
          </div>
        </div>
        <div class="col-12 order-2 mt-3 col-sm-auto order-sm-1 mt-sm-0">
          <div class="my-auto" style="max-width: 15rem;">
            <p class="text-muted small mb-0">
              {{ 'LESSONS.STUDENTS.STUDENT' | translate }}
            </p>
            <span class="me-2">{{ getPersonName() }}</span>
            <span class="text-uppercase badge xsmall rounded-2 bg-warning w-fit-content">{{ getStatus() }}</span>
            <p class="text-muted small mb-0">{{ getAccountEmail() }}</p>
          </div>
        </div>
      </div>
    </div>

    <ul class="nav nav-pills py-4">
      <li class="nav-item p-0 m-1">
        <a class="btn rounded-pill btn-sm nav-link px-4 py-2" href="#"
           [ngClass]="{active: currentFragment === 'cspa-monitor'}"
           routerLink="/teacher/students/{{studentId}}/cspa-monitor">
          {{ 'LESSONS.STUDENTS.CSPA_MONITOR.TITLE' | translate }}
        </a>
      </li>
      <li class="nav-item p-0 m-1">
        <a class="btn rounded-pill btn-sm nav-link px-4 py-2" href="#"
           [ngClass]="{active: currentFragment === 'exam-results'}"
           routerLink="/teacher/students/{{studentId}}/exam-results">
          {{ 'LESSONS.STUDENTS.EXAM_RESULTS.TITLE' | translate }}
        </a>
      </li>
    </ul>
    <router-outlet></router-outlet>
  </div>
</div>
