export enum RepoEntryType {
  File = "file", Dir = "dir"
}

export class RepoEntry {
  '@type': RepoEntryType;
  key: string;
  name: string;
  shortName: string;
  relativeKey: string;
}

export class FolderRepoEntry extends RepoEntry {
  innerEntries: RepoEntry[];
}

export class FileRepoEntry extends RepoEntry {
  size: number;
  extension: string;
}
