<div *ngFor="let q of questions" class="row">
  <p class="xsmall text-muted mb-1">
    {{ 'LESSONS.STUDENTS.EXAM_RESULTS.STUDENTS_ANSWER' | translate }}
  </p>
  <div class="d-flex mb-2">
    <ng-container *ngFor="let item of getStudentAnswer(q)">
      <span class="small" *ngIf="item" [ngClass]="item.kind">{{ item.content || "..." }}</span>
      <span class="small" *ngIf="!item">{{ "..." }}</span>
    </ng-container>
  </div>
  <p class="xsmall text-muted mb-1">
    {{ 'LESSONS.STUDENTS.EXAM_RESULTS.CORRECT_ANSWER' | translate }}
  </p>
  <span class="small">{{ getCorrectAnswer(q) }}</span>
</div>
