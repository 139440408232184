<div class="workspace bg-radial">
  <top-bar [ngClass]="getAccent()" class="d-none d-md-block">
    <content class="mb-md-4">
      <button
        type="button"
        class="btn btn-outline-secondary rounded-pill btn-back btn-back-size"
        routerLink="../../"
      >
        <app-icon name="arrow-back"></app-icon>
        <span class="d-none d-lg-block ms-3">
          {{ "NAVIGATION.EXERCISE_SET" | translate }}
        </span>
      </button>
      <div class="text-center">
        <h4>{{ getExerciseType() }}</h4>
        <span class="d-md-none text-muted">
          {{ getChapter() }}, {{ getExamName() }}, {{ getExamPartName() }}
        </span>
      </div>
    </content>
    <footer class="d-none d-md-block">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a routerLink="../../../../../../../../">
              {{ "NAVIGATION.EXERCISES" | translate }}
            </a>
          </li>
          <li class="breadcrumb-item">
            <a (click)="goToExerciseSetsWithFilter()">{{ getExerciseSet() }}</a>
          </li>
          <li class="breadcrumb-item">
            <a routerLink="../../../../../../">{{
                getChapter()
              }}</a>
          </li>
          <li class="breadcrumb-item">
            <a routerLink="../../">
              {{ getExamName() }}
            </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ getExamPartName() }}
          </li>
        </ol>
      </nav>
    </footer>
  </top-bar>

  <div class="exercise-navbar d-md-none d-flex mx-3 mx-sm-4 justify-content-between align-items-center">
    <div>
      <button
        type="button"
        class="btn btn-outline-secondary rounded-pill btn-back-size"
        (click)="goBack()"
      >
        <app-icon name="arrow-back"></app-icon>
      </button>
    </div>
    <div class="d-flex flex-column">
      <span>{{ getChapter() }} {{ (isSampleExam() ? 'EXAM.START.SAMPLE.TITLE' : 'EXAM.START.TITLE') | translate }}</span>
      <span>Part {{ getCurrentPartNr() }}: {{ getExamPartName() }}</span>
    </div>
    <div></div>
  </div>

  <div class="container mt-md-3 mb-5">
    <div class="row position-relative pb-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
    <div class="card">
      <div class="card-body">
        <app-instruction [withSettings]="true" [isOffcanvas]="false"></app-instruction>
      </div>
      <div class="card-footer border-0 text-center px-4 pb-3">
        <button type="button" class="btn btn-primary rounded-pill mx-auto py-2 px-5"
          routerLink="./1">
          {{ "BUTTON.START" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
