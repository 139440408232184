import {Component, OnDestroy, OnInit} from '@angular/core';
import {ExamSession} from "../../../cspa/model/cspa/personal";
import {Student} from "../../../auth_profile/model/casa/casa-model";
import {ExerciseSet} from "../../../cspa/model/cspa/struct";
import {ActivatedRoute} from "@angular/router";
import {map, Observable, of, switchMap, tap} from "rxjs";
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {AuthorizationServiceProvider} from "../../../auth_profile/services/authorization-service.provider";
import {SpinnerService} from "../../../utils/services/spinner.service";

@Component({
  selector: 'app-teacher-student-exam-results',
  templateUrl: './teacher-student-exam-results.component.html',
  styleUrls: ['./teacher-student-exam-results.component.scss']
})
export class TeacherStudentExamResultsComponent implements OnInit, OnDestroy {

  private schoolId: number
  private student: Student;
  exams: ExamSession[]
  exerciseSets: Map<string, ExerciseSet> = null;
  exerciseSetExams: Map<ExerciseSet, ExamSession[]> = new Map<ExerciseSet, ExamSession[]>();

  constructor(private route: ActivatedRoute,
              private provider: NativeServiceApiProvider,
              private authService: AuthorizationServiceProvider,
              private spinnerService: SpinnerService,
  ) {
  }

  ngOnInit(): void {
    this.loadSchoolId().pipe(
      switchMap(_ => this.loadStudent()),
      switchMap(_ => this.loadExerciseSets()),
      switchMap(_ => this.loadExamSessions()),
    ).subscribe()
  }

  ngOnDestroy(): void {
  }

  private loadSchoolId() {
    return this.authService.getAuthDetailsService().pipe(
      switchMap(api => api.getSelfSchoolId()),
      tap(schoolId => this.schoolId = schoolId)
    )
  }

  private loadStudent() {
    return this.route.parent.paramMap.pipe(
      switchMap(params =>
        this.getStudent(this.schoolId, +params.get('studentId'))
      ),
      tap<Student>(student => this.student = student)
    )
  }

  private getStudent(schoolId: number, studentId: number) {
    return this.provider.video().pipe(
      switchMap(api => api.getStudent(schoolId, studentId))
    )
  }

  loadExerciseSets(): Observable<any> {
    if (this.exerciseSets && this.exerciseSets.size > 0) return of(this.exerciseSets)
    return this.provider.cspa().pipe(
      switchMap(api => api.listExerciseSets()),
      map(sets =>
        sets.reduce((res, current) => res.set(current.path, current)
          , new Map<string, ExerciseSet>())),
      tap(setsMap => this.exerciseSets = setsMap)
    )
  }

  private loadExamSessions() {
    return this.spinnerService.trace(
      this.provider.cspa().pipe(
        switchMap(api => api.findExamSessions(this.schoolId, this.student.person.id, "")),
        tap(exams => {
          this.exams = exams.sort((a, b) => a.path.localeCompare(b.path))
          this.setExerciseSetExams()
        })
      )
    )
  }

  setExerciseSetExams() {
    for (let set of this.exerciseSets.values()) {
      this.exerciseSetExams.set(
        set,
        this.exams.filter(e => e.path.split("_")[0] == set.path)
          .sort((a, b) => a.path.localeCompare(b.path))
      )
    }
  }

  getExamScore(exam: ExamSession) {
    return exam.parts.reduce((sum, p) => sum + (p.score * p.weight), 0)
  }
}
