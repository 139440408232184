<div class="ratio-1 h-100 position-relative" role="button">
  <div
    [@inverse]="isReversed() ? 'inversed' : 'aversed'"
    class="picture-card card-aversed h-100"
    [ngClass]="{ selected: isSelected() }"
    (click)="onClick()"
  >
    <div class="d-flex h-100 p-1 p-sm-3">
      <img class="w-100 pe-none" *ngIf="isPicture()" [src]="getImageSrc()" />
      <span *ngIf="isWord()" class="m-auto fs-5 fs-sm-2">{{ getWord() }}</span>
    </div>
  </div>
  <div
    [@inverse]="!isReversed() ? 'inversed' : 'aversed'"
    class="picture-card card-inversed"
    role="button"
    (click)="onClick()"
  >
    <div class="d-flex h-100 p-md-3">
      <app-icon name="logo" class="logo m-auto"></app-icon>
    </div>
  </div>
</div>
