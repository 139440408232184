import {Component, Input, OnInit} from '@angular/core';
import {Correction, CorrectionAnswer} from "../../../../cspa/model/cspa/questions";
import {ExerciseSessionQuestion} from "../../../../cspa/model/cspa/personal";

@Component({
  selector: 'app-cor-result',
  templateUrl: './cor-result.component.html',
  styleUrls: ['./cor-result.component.scss']
})
export class CorResultComponent implements OnInit {
  @Input() questions: ExerciseSessionQuestion<CorrectionAnswer, Correction> []

  constructor() { }

  ngOnInit(): void {
  }

  getCorrectAnswer(q: ExerciseSessionQuestion<CorrectionAnswer, Correction>) {
    return q.question.definition.answer.correct
  }

  getStudentAnswer(q: ExerciseSessionQuestion<CorrectionAnswer, Correction>) {
    return q.answer?.correct
  }

  isIncorrect(q: ExerciseSessionQuestion<CorrectionAnswer, Correction>) {
    return !q.correct
  }
}
