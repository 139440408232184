import {Component, Input, OnInit} from '@angular/core';
import {FillMulti, FillMultiAnswer} from "../../../../cspa/model/cspa/questions";
import {ExerciseSessionQuestion} from "../../../../cspa/model/cspa/personal";

@Component({
  selector: 'app-fill-multi-result',
  templateUrl: './fill-multi-result.component.html',
  styleUrls: ['./fill-multi-result.component.scss']
})
export class FillMultiResultComponent implements OnInit {
  @Input() questions: ExerciseSessionQuestion<FillMultiAnswer, FillMulti> []

  constructor() { }

  ngOnInit(): void {
  }

  getQuestion(q: ExerciseSessionQuestion<FillMultiAnswer, FillMulti>) {
    return q.question.definition.question.replace(/{\d}/g, "...")
  }

  getStudentAnswer(q: ExerciseSessionQuestion<FillMultiAnswer, FillMulti>) {
    return q.answer?.answer?.map(a => a.val.join("; ")) || "-"
  }

  getCorrectAnswer(q: ExerciseSessionQuestion<FillMultiAnswer, FillMulti>) {
    return q.question.definition.answer.answer.map(a => a.val.join(", ")).join("; ")
  }

  isAnswerIncorrect(q: ExerciseSessionQuestion<FillMultiAnswer, FillMulti>) {
    return q.score == 0 && q.answer
  }
}
