import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {switchMap, finalize, of, Observable, tap, map} from 'rxjs';
import { ApiCountry } from 'src/app/auth_profile/model/casa/casa-model';
import { PersonUtils } from 'src/app/auth_profile/utils/person-utils';
import {
  ApiLearningUnitTeacher,
  ApiLessonInstance,
  ApiLessonProgress,
  ApiLessonStatus, ApiPersonalProfile,
  ApiPersonTechnicalProfile, ApiStudentProductContext,
  LessonStatusUtils,
} from 'src/app/col/model/rest-model';
import { TeacherRestServiceImpl } from 'src/app/col/services/teacher/teacher-rest-impl.service';
import { LessonTypeUtils } from 'src/app/col/utils/lesson-utils';
import { Dates, TimeUnits } from 'src/app/utils/calendar-utils';
import { ModalComponent } from 'src/app/utils/modal/modal.component';

@Component({
  selector: 'app-teacher-lesson-details-header',
  templateUrl: './teacher-lesson-details-header.component.html',
  styleUrls: ['./teacher-lesson-details-header.component.scss'],
})
export class TeacherLessonDetailsHeaderComponent implements OnInit {
  @ViewChild('notificationConfirmationModal', { static: true })
  notificationConfirmationModal: ModalComponent;

  public static READING_RECOMMENDATION = 22;

  private _lessonDetails: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>;
  private _teacherId: number;
  private _studentId: number;
  private _lessonId: number;
  private _currentProgress: ApiLessonProgress;
  private doubleVideoReservationLocked = false;

  isComing = false;
  isRunning = false;
  readyToStart = false;
  allowNotification = true;
  needConfirmation = false;
  cancelable = false;
  mayBeStartedTime = false;
  shouldBeStarted = false;
  shouldBeFinished = false;
  isSkype = false;
  isComplete = false;
  canUpdatePlannedProgress = false;
  roomUrl: SafeResourceUrl;
  studentTechnicalProfile: ApiPersonTechnicalProfile;
  _clReason: string;
  countries: ApiCountry[];
  plannedLessonFinish: Date;
  plannedLessonStart: Date;
  progressCommitLimit: Date;
  newLessonType: string;
  form: NgForm;
  studentContext: ApiStudentProductContext;

  @Output() canUpdateProgress = new EventEmitter();
  @Output() updateLessonDetails = new EventEmitter();
  @Output() showCancelLesson = new EventEmitter();

  @Input()
  set currentProgress(progress: ApiLessonProgress) {
    this._currentProgress = progress;
  }

  get currentProgress() {
    return this._currentProgress;
  }

  @Input()
  set lessonDetails(lessonDetails: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (lessonDetails) {
      this._lessonDetails = lessonDetails;
      this._studentId = lessonDetails.students[0].id;
      this._lessonId = lessonDetails.id;
      this.loadStudentContext().subscribe();
      this.setLessonDetails(lessonDetails);
      this.loadStudentTechnicalProfile().subscribe();
    }
  }

  get lessonDetails() {
    return this._lessonDetails;
  }

  @Input()
  set teacherId(teacherId: number) {
    this._teacherId = teacherId;
  }

  constructor(
    private sanitizer: DomSanitizer,
    private teacherRest: TeacherRestServiceImpl
  ) {
    teacherRest
      .getCountries()
      .subscribe((countries) => (this.countries = countries));
  }

  ngOnInit(): void {}

  public getLessonDate(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return lesson.lessonMetric.plannedStartDate;
  }

  public getLessonStartedDate(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return lesson.lessonMetric.started;
  }

  public getRevisionPage() {
    return Math.max(this.currentProgress.nwp - 6, 1);
  }

  public getRecommendedReading() {
    if (!this.currentProgress || !this.currentProgress.nwp) {
      return 1;
    }
    return Math.max(
      this.currentProgress.nwp -
      TeacherLessonDetailsHeaderComponent.READING_RECOMMENDATION,
      1
    );
  }

  public getLessonStudentPhotoUrl(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): string {
    let studentPerson = lesson?.students[0]?.person;
    return studentPerson?.personalProfile?.profilePhoto
      ? PersonUtils.getPersonProfilePhoto(studentPerson)
      : null;
  }

  public getStudentName(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (!lesson || !lesson.students || lesson.students.length === 0) {
      return 'Unknown student';
    }
    return PersonUtils.getPersonName(lesson.students[0].person);
  }

  private getStudentPerson() {
    if (
      !this.lessonDetails ||
      !this.lessonDetails.students ||
      this.lessonDetails.students.length < 1 ||
      !this.lessonDetails.students[0].person
    ) {
      return null;
    }
    return this.lessonDetails.students[0].person;
  }

  getStudentNationality() {
    if (!this.countries) {
      return '';
    }
    const studentPerson = this.getStudentPerson();
    if (!studentPerson?.personalProfile?.nationalityIso) {
      return '';
    }
    const studentCountry = this.countries.find(
      (c) => c.isoCode === studentPerson.personalProfile.nationalityIso
    );

    return studentCountry ? studentCountry.name : '';
  }

  getStudentCountry() {
    if (!this.countries) {
      return '';
    }
    const studentPerson = this.getStudentPerson();
    if (!studentPerson?.personalProfile?.countryIso) {
      return '';
    }

    const studentCountry = this.countries.find(
      (c) => c.isoCode === studentPerson.personalProfile.countryIso
    );
    return studentCountry? studentCountry.name : '';
  }

  getStudentGender() {
    const studentPerson = this.getStudentPerson();
    if(!studentPerson?.personalProfile?.gender)
      return ''
    return `PROFILE.FORM.PERSONAL_INFO.${studentPerson?.personalProfile?.gender.toUpperCase()}`
  }

  reserveVideoClassroom() {
    if (this.doubleVideoReservationLocked) return;
    this.doubleVideoReservationLocked = true;
    this.teacherRest
      .createVideoClassroom(this._teacherId, this._lessonId)
      .pipe(tap(() => this.updateLessonDetails.emit()))
      .pipe(
        finalize(() => {
          this.doubleVideoReservationLocked = false;
        })
      )
      .subscribe();
  }

  sendStudentStartNotification() {
    this.allowNotification = false;
    this.teacherRest
      .notifyStudentAboutLessonStart(this._teacherId, this._lessonId)
      .subscribe(() => {
        this.allowNotification = true;
        this.notificationConfirmationModal.show();
      });
  }

  confirm() {
    of(null)
      .pipe(
        switchMap(() =>
          this.teacherRest.commitLessonBooking(this._teacherId, this._lessonId)
        ),
        tap(() => this.updateLessonDetails.emit())
      )
      .subscribe();
  }

  startLesson() {
    of(null)
      .pipe(
        switchMap(() =>
          this.teacherRest.startLesson(this._teacherId, this._lessonId, null)
        ),
        tap(() => this.updateLessonDetails.emit())
      )
      .subscribe();
  }

  public showCancelLessonModal() {
    this._clReason = null;
    this.showCancelLesson.emit()
  }

  public enterTheRoom() {
    let observable: Observable<null | ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> = of(null);
    if (this.lessonDetails.lessonStatus === 'Due') {
      observable = this.teacherRest
        .startLesson(this._teacherId, this._lessonId, null)
        .pipe(tap(() => this.updateLessonDetails.emit()));
    }
    observable.subscribe();
  }

  private setLessonDetails(lessonDetails: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    const lessonStatus = ApiLessonStatus[this.lessonDetails.lessonStatus];
    this.isComing = LessonStatusUtils.isComing.indexOf(lessonStatus) >= 0;
    this.readyToStart = lessonStatus === ApiLessonStatus.Due;
    this.isRunning = lessonStatus === ApiLessonStatus.InProgress;
    this.needConfirmation = lessonStatus === ApiLessonStatus.Booked;
    this.isComplete = lessonStatus === ApiLessonStatus.Complete;
    this.canUpdatePlannedProgress = this.isComing;
    this.plannedLessonStart = new Date(
      lessonDetails.lessonMetric.plannedStartDate
    );
    this.newLessonType = lessonDetails.lessonType;
    this.roomUrl = null;
    this.isSkype = false;
    if (lessonDetails.roomUrl) {
      if (lessonDetails.roomUrl.startsWith('skype:')) {
        this.isSkype = true;
        this.roomUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          lessonDetails.roomUrl
        );
      } else if (lessonDetails.roomUrl.startsWith('video_room_url:')) {
        this.roomUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          lessonDetails.roomUrl.substring('video_room_url:'.length)
        );
      } else {
        this.roomUrl = null;
      }
    }
    this.plannedLessonFinish = new Date(
      this.plannedLessonStart.getTime() +
        lessonDetails.lessonMetric.plannedDuration
    );
    this.progressCommitLimit = new Date(
      this.plannedLessonStart.getTime() + 1000 * 60 * 60 * 6
    );

    this.updateTimingVariables();
  }

  loadStudentTechnicalProfile(): Observable<ApiPersonTechnicalProfile> {
    return this.teacherRest
      .getStudentTechnicalProfileByTeacher(this._teacherId, this._studentId)
      .pipe(
        tap(
          (studentTechnicalProfile) =>
            (this.studentTechnicalProfile = studentTechnicalProfile)
        )
      );
  }

  loadStudentContext() {
    return this.teacherRest.getStudentProductContext(
          this._teacherId,
          this._studentId,
          this.lessonDetails.course.product.code
        ).pipe(
          map((context) => (context ? context : new ApiStudentProductContext())),
          tap((context) => (this.studentContext = context))
        );
  }

  updateTimingVariables(): void {
    if (!this.lessonDetails) {
      return;
    }
    const lessonStatus = ApiLessonStatus[this.lessonDetails.lessonStatus];
    this.shouldBeStarted =
      this.plannedLessonStart.getTime() < new Date().getTime() && this.isComing;
    this.shouldBeFinished =
      this.plannedLessonFinish.getTime() < new Date().getTime() &&
      this.shouldBeStarted;
    this.canUpdateProgress.emit(
      this.shouldBeFinished ||
        this.isRunning ||
        this.shouldBeStarted ||
        (this.isComplete &&
          this.progressCommitLimit.getTime() > new Date().getTime())
    );

    this.mayBeStartedTime =
      Dates.diff(new Date(), this.plannedLessonStart) <
      TimeUnits.Minutes(15).toMilis();
    this.cancelable =
      LessonStatusUtils.cancelable.indexOf(lessonStatus) >= 0 &&
      !this.shouldBeStarted &&
      !this.mayBeStartedTime;
  }

  getInitials(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return PersonUtils.getInitials(lesson.students[0].person);
  }

  getLessonStatusTranslation() {
    return LessonTypeUtils.getStudentLessonStatus(this.lessonDetails)
  }

  buildVersionNumber(courseCode: string, productVersion: string) {
    if (!courseCode.startsWith("en.s") && !productVersion) return null;
    if (!productVersion) return '2nd Ed.';
    const intVer = parseInt(productVersion);
    if (isNaN(intVer)) return `v: ${productVersion}`;
    const lastDigit = intVer % 10;
    let postfix: string;
    if (intVer > 3 && intVer < 20) postfix = 'th';
    else if (lastDigit == 1) postfix = 'st';
    else if (lastDigit == 2) postfix = 'nd';
    else if (lastDigit == 3) postfix = 'rd';
    else postfix = 'th';
    return `${intVer}${postfix} Ed.`;
  }
  getLessonProductVersion(): string {
    return this.buildVersionNumber(this.lessonDetails.course.code, this.lessonDetails.productVersion);
  }
  getProductVersion() {
    return this.buildVersionNumber(this.lessonDetails.course.code, this.studentContext?.productVersion);
  }

  isRedLabel() {
    return this.lessonDetails.course.code.startsWith('en.s')
      && this.studentContext?.productVersion == null
  }

  isLessonInOldVersion() {
    if (!this.lessonDetails.course.code.startsWith('en.s')) return false;
    return this.lessonDetails.productVersion == null;

  }

}
