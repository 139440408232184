import { Component, OnInit } from '@angular/core';
import {ExerciseSetEnum} from "../../model/cspa/struct";
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {iif, of, switchMap, tap, zip} from "rxjs";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {CspaApi} from "../../services/api/cspa.api";
import {ExamSession, ExamSessionPart} from "../../model/cspa/personal";
import {SpinnerService} from "../../../utils/services/spinner.service";

@Component({
  selector: 'app-exam-details',
  templateUrl: './exam-details.component.html',
  styleUrls: ['./exam-details.component.scss']
})
export class ExamDetailsComponent implements OnInit {
  uuId: string
  section: string
  chapter: string
  exerciseSet: string
  session: ExamSession
  sessionParts: ExamSessionPart[]
  isSummary: boolean = false
  basePath: string
  isOffline = false
  constructor(private provider: NativeServiceApiProvider,
              private route: ActivatedRoute,
              private spinner: SpinnerService,
              private router: Router) { }

  ngOnInit(): void {
    this.prepareBasePath()
    this.route.paramMap.pipe(
      tap( (params: ParamMap) => {
        this.uuId = params.get("sessionId")
        this.section = params.get("section");
        this.chapter = params.get("chapter");
        this.exerciseSet = params.get("exerciseSet");
      }),
      switchMap( _ => this.loadData()),
    ).subscribe()
  }

  prepareBasePath() {
    zip(this.route.parent.url, this.route.url).subscribe(([p, c]) => {
        let segments = [p[0], ...c.slice(0, 1)]
        this.basePath = segments.join("/")
        this.isSummary = c.reverse()[0].path == 'finish'
      })
  }

  loadData() {
    return this.spinner.trace(
      this.provider.cspa().pipe(
        switchMap( (api: CspaApi) => zip(of(api), api.isOffline())),
        switchMap(([api, isOffline]) => iif(() => isOffline,
          of(null).pipe(
            tap(_ => this.isOffline = true)
          ),
          api.getExamSession(this.uuId))
        ),
        tap(session => {
          this.session = session
          this.sessionParts = session?.parts
        })
      )
    )
  }

  getExerciseRouterLink() {
    return `/${this.basePath}`
  }

  getChapterRouterLink() {
    return `/${this.basePath}/${this.exerciseSet}/${this.chapter}`
  }

  getAccent() {
    return `accent stg-${this.session?.chapterName.slice(-2).trim()}`
  }

  getChapter() {
    return this.session?.chapterName || '-'
  }

  getExamName() {
    return this.session?.examName || '-'
  }

  getExerciseSet() {
    return ExerciseSetEnum[this.exerciseSet]
  }

  isSampleExam() {
    return this.session?.examName.split(" ")[1] == "0"
  }

  getBackButtonRouterLink() {
    this.route.url.subscribe(paths => {
      if(paths.find(el => el.path == 'finish')){
        this.router.navigate(["../../../../../"], { relativeTo: this.route })
      }
      else {
        this.router.navigate(["../../../../"], { relativeTo: this.route })
      }
    })
  }
}
