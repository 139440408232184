import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {ExamSession, ExerciseSession, ItemAvailability} from '../../model/cspa/personal';
import {Exam, Exercise, Section, SectionType} from '../../model/cspa/struct';
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {of, switchMap} from "rxjs";
import { ModalComponent } from 'src/app/utils/modal/modal.component';

@Component({
  selector: 'question-set',
  templateUrl: './question-set.component.html',
  styleUrls: ['./question-set.component.scss']
})
export class QuestionSetComponent implements OnInit {

  @ViewChild('instructionModal', {static: true})
    instructionModal: ModalComponent;

  @Input() section?: Section;
  @Input() availabilitiesMap?: Map<string,ItemAvailability>;
  chapter: string


  constructor(
    private router: Router,
    private provider: NativeServiceApiProvider,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.chapter = params.get("chapter")
    })
  }

  getFullType() {
    return this.section.name
  }

  getShortName() {
    return this.section.name.split(',')[0]
  }

  getStage(){
    return this.section.name.split(',')[1]
  }

  getDescriptionCode() {
    return this.section.path.toUpperCase()
  }

  getAccentColor() {
    return `stg-${this.section?.name.slice(-2).trim()}`
  }

  getExercises() {
    return this.section.exercises
  }

  getAvailability(exercise: Exercise) {
    return [...this.availabilitiesMap.values()].filter(av =>
      av.path.endsWith(exercise.path))[0]
  }

  getFullPath(exercise: Exercise) {
  return `${this.chapter}_${this.section.path}_${exercise.path}`
  }

  onContinue() {
    for (const ex of this.getExercises()) {
      //find the first with bad score or never done
      if(this.getAvailability(ex).score < 0.5 || this.getAvailability(ex).score === 0)
        return this.startSession(ex)
        //find the first with good score but not perfect (below full green)
      else if(this.getAvailability(ex).score >= 0.5 && this.getAvailability(ex).score < 1)
        return this.startSession(ex)
    }
    //find the one which is the oldest lastUpdate
    const ex = this.getExercises().sort((aKey, bKey) =>
    aKey.lastUpdate - bKey.lastUpdate)[0]
    return this.startSession(ex)
  }

  startSession(exercise: Exercise | Exam) {
    this.section.type == SectionType.S_EXAM
      ? this.startExamSession(exercise as Exam)
      : this.startExerciseSession(exercise)
  }

  private startExerciseSession(exercise: Exercise) {
    this.provider
      .cspa()
      .pipe(switchMap((api) => api.startSession(this.getFullPath(exercise))))
      .subscribe((session) => {
        this.routeToTheSession(session, exercise);
      });
  }

  private routeToTheSession(session: ExerciseSession, exercise: Exercise) {
    this.router.navigate(
      [`${this.section.path}/${exercise.path}/session/${session.deviceUUID}/1`],
      {
        relativeTo: this.route,
        queryParamsHandling: 'merge'
      },
    ).then()
  }

  private startExamSession(exam: Exam) {
    this.provider
      .cspa()
      .pipe(
        switchMap((api) => api.isOffline().pipe(
          switchMap(isOffline => isOffline ? of(null) : api.startExamSession(this.getFullPath(exam)))
        ))
      )
      .subscribe((session) => {
        this.routeToTheExamSession(session, exam);
      });
  }

  private routeToTheExamSession(session: ExamSession, exam: Exam) {
    this.router.navigate(
      [`${this.section.path}/${exam.path}/exam-session/${session?.uuid}`],
      {
        relativeTo: this.route,
        queryParamsHandling: 'merge'
      },
    ).then()
  }
}
