<div *ngFor="let q of questions" class="row mb-3">
  <p class="xsmall text-muted mb-1">
    {{ 'LESSONS.STUDENTS.EXAM_RESULTS.STUDENTS_ANSWER' | translate }}
  </p>
  <span class="small" [ngClass]="{ 'text-danger': isIncorrect(q) }">{{ getStudentAnswer(q) }}</span>
  <p class="xsmall text-muted mb-1">
    {{ 'LESSONS.STUDENTS.EXAM_RESULTS.CORRECT_ANSWER' | translate }}
  </p>
  <span class="small">{{ getCorrectAnswer(q) }}</span>
</div>
