<div class="row g-1">
  <div class="col-6">
    <p class="xsmall text-muted">
      {{ 'LESSONS.STUDENTS.EXAM_RESULTS.QUESTION' | translate }}
    </p>
  </div>
  <div class="col-3">
    <p class="xsmall text-muted">
      {{ 'LESSONS.STUDENTS.EXAM_RESULTS.STUDENTS_ANSWER' | translate }}
    </p>
  </div>
  <div class="col-3">
    <p class="xsmall text-muted">
      {{ 'LESSONS.STUDENTS.EXAM_RESULTS.CORRECT_ANSWER' | translate }}
    </p>
  </div>
</div>
<div *ngFor="let q of questions" class="row">
  <div class="col-6">
    <p class="small">{{ getQuestion(q) }}</p>
  </div>
  <div class="col-3">
    <p class="small" [ngClass]="{ 'text-danger': isAnswerIncorrect(q)}">{{ getStudentAnswer(q) }}</p>
  </div>
  <div class="col-3">
    <p class="small">{{ getCorrectAnswer(q) }}</p>
  </div>
</div>
