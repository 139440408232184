import {Component, Input, OnInit} from '@angular/core';
import {Jumble, JumbleAnswer} from "../../../../cspa/model/cspa/questions";
import {ExerciseSessionQuestion} from "../../../../cspa/model/cspa/personal";

@Component({
  selector: 'app-jumble-result',
  templateUrl: './jumble-result.component.html',
  styleUrls: ['./jumble-result.component.scss']
})
export class JumbleResultComponent implements OnInit {
  @Input() questions: ExerciseSessionQuestion<JumbleAnswer, Jumble> []

  constructor() { }

  ngOnInit(): void {
  }

  getQuestion(q: ExerciseSessionQuestion<JumbleAnswer, Jumble>) {
    return q.question.definition.question.replace(/{\d}/g, "...")
  }

  getStudentAnswer(q: ExerciseSessionQuestion<JumbleAnswer, Jumble>) {
    return q.answer?.answer?.map(a => a.val.join("; ")) || "-"
  }

  getCorrectAnswer(q: ExerciseSessionQuestion<JumbleAnswer, Jumble>) {
    return q.question.definition.answer.answer.map(a => a.val.join(", ")).join("; ")
  }

  isAnswerIncorrect(q: ExerciseSessionQuestion<JumbleAnswer, Jumble>) {
    return q.score == 0 && q.answer
  }
}
