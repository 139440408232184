<div class="workspace bg-radial">
  <top-bar [titleTranslationCode]="'NAVIGATION.DASHBOARD'">
    <footer>
      <div class="row d-none d-md-flex">
        <div class="col-10">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                  {{ "NAVIGATION.DASHBOARD" | translate }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </footer>
  </top-bar>
  <footer *ngIf="technicalProfile?.introductionState === introductionState.WelcomedOnColNew || technicalProfile?.introductionState === introductionState.InvitedToColNew">
   <div class="invite-banner d-flex px-3 px-md-5 flex-column flex-lg-row justify-content-between">
      <span class="f-14 ps-lg-5 py-2">
        {{ "REDIRECT.WELCOME_BANNER.BODY" | translate}} 
      </span>
      <button class="btn btn-outline-primary rounded-pill px-5 mb-2 mb-lg-0 ms-lg-5 ms-xxl-0 me-xl-4" (click)="backToOldCallan()" >
        {{ "REDIRECT.WELCOME_BANNER.BUTTON_GO_BACK" | translate }}
      </button>  
    </div>
  </footer>
  <div class="container mt-md-3 mb-5">
    <div class="row position-relative pb-3">
      <spinner class="w-100 spinner"></spinner>
    </div>
    <div class="row row-cols-1 row-cols-xl-2 gy-5 mb-5">
      <!-- NEXT LESSON -->
      <div class="col">
        <div class="card h-100">
          <div class="card-header">
            <div class="row">
              <div class="col d-flex">
                <h4 class="my-auto">
                  {{ "DASHBOARD.NEXT_LESSON" | translate }}
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div *ngIf="lessons?.content.length > 0; else noResults" class="d-flex flex-column gap-3">
              <app-lesson-card *ngFor="let lesson of lessons?.content" [lesson]="lesson"
              [redirectPathPrefix]="'/student/col-lessons/school/' + schoolId + '/student/' + studentId + '/lesson'"></app-lesson-card>
            </div>
            <ng-template #noResults>
              <div class="row justify-content-center justify-content-sm-between gy-3">
                <div class="col-auto d-flex">
                  <p class="my-auto">
                    {{ "LESSONS.NO_FUTURE_LESSONS" | translate }}
                  </p>
                </div>
                <div class="col-auto">
                  <button routerLink="../reserve-lesson"
                          class="btn btn-primary rounded-pill">
                    {{ "BUTTON.BOOK_LESSON" | translate }}
                  </button>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="card-footer border-0 text-end px-4 pb-3">
            <button routerLink="../col-lessons/school/{{schoolId}}/student/{{studentId}}"
                    class="btn btn-outline-primary rounded-pill me-2">
              {{ "DASHBOARD.ALL_LESSONS" | translate }}
            </button>
          </div>
        </div>
      </div>
      <!-- PROGRESS -->
      <div class="col">
        <div class="card h-100">
          <div class="card-header">
            <div class="row">
              <div class="col d-flex">
                <h4 class="my-auto">
                  {{ "NAVIGATION.PROGRESS" | translate }}
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <app-progress-card-list></app-progress-card-list>
          </div>
        </div>
      </div>
      <!-- CREDITS -->
      <div class="col">
        <div class="card h-100">
          <div class="card-header">
            <div class="row">
              <div class="col d-flex">
                <h4 class="my-auto">
                  {{ "NAVIGATION.CREDITS" | translate }}
                </h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <app-student-credits-short [studentId]="studentId"></app-student-credits-short>
          </div>
        </div>
      </div>
    </div>
    <!-- CALENDAR -->
    <div class="card h-100">
      <app-student-lessons-calendar [studentId]="studentId"></app-student-lessons-calendar>
    </div>
  </div>
</div>

<app-modal #welcomeModal>
  <div class="modal-content">
    <div class="modal-header border-0">
      <h5 class="modal-title">{{ "REDIRECT.WELCOME_MODAL.TITLE" | translate }}</h5>
      <button type="button" class="btn-close" (click)="welcomeToNewCallan()" aria-label="Close"></button>
    </div>
    <div class="modal-body welcome-modal">
      {{ "REDIRECT.WELCOME_MODAL.BODY" | translate }}
    </div>
    <div class="modal-footer border-0">
      <button type="submit" class="btn btn-primary rounded-pill" (click)="welcomeToNewCallan()">
        {{'BUTTON.OK' | translate}}
      </button>
    </div>
  </div>
</app-modal>
