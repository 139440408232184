import { Injectable } from '@angular/core';
import {VideoApi} from "../../../video-rest.service";
import {Page, Pageable} from "../../../../../utils/pageable";
import {IdentifiedRoomTemplate, RoomDef, RoomProgressReport, ScheduleRow} from "../../../../models/video.model";
import {Observable} from "rxjs";
import {LogsService} from "../../../../../utils/services/logs.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {AndroidBridgeBase} from "../../../../../mobile/api-services/bridge/android-bridge-base";
import {Constraints, Platform} from "../../../../../constraints";
import {Student} from "../../../../../auth_profile/model/casa/casa-model";

@Injectable({
  providedIn: 'root'
})
export class VideoAndroidService implements VideoApi {

  constructor(
    private logger: LogsService,
    private http: HttpClient,
    private bridgeBase: AndroidBridgeBase
  ){}

  private videoEndpoint = Constraints.androidGenericInternalApiEndpoint;

  private studentUrl(schoolId: number, studentId: number, path: string): string {
    return `${this.videoEndpoint}/platform/${Platform.Video}/sec/school/${schoolId}/student/${studentId}${path}`;
  }

  private teacherUrl(schoolId: number, teacherId: number, path: string) {
    return `${this.videoEndpoint}/platform/${Platform.Video}/sec/school/${schoolId}/teacher/${teacherId}${path}`;
  }
  private buildPath(path: string): string {
    return `${this.videoEndpoint}/platform/${Platform.Video}${path}`
  }

  private static buildRequestIdHeader(id: number){
    return new HttpHeaders({"X-Request-Id" : id.toString()});
  }

  private nextRequestIdHeader(){
    return VideoAndroidService.buildRequestIdHeader(this.bridgeBase.getNextRequestId());
  }
  getProgressHistory(schoolId: number, templateId: number, pageable: Pageable): Observable<Page<RoomProgressReport>> {
    this.logger.log(`getting progress history for templateId=${templateId}`)
    const params: HttpParams = pageable ? pageable.getHttpParams() : null;
    const page = this.cachePageNumber(pageable)
    return this.http.get<Page<RoomProgressReport>>(this.buildPath(`/sec/school/${schoolId}/templates/${templateId}/progress-history`), {
      headers: this.nextRequestIdHeader()
        .append(Constraints.cacheNameHeader, `template-${templateId}-video-progress-history-${page}`)
        .append(Constraints.syncTimeHeader, (Constraints.syncMinute).toString()),
      params: params
    });
  }

  private cachePageNumber(pageable: Pageable): number {
    if (!pageable || !pageable.page) return 0
    return pageable.page
  }

  getStudentRoomDetails(schoolId: number, studentId: number, roomUuid: string): Observable<RoomDef> {
    this.logger.log(`getting student room details for roomUuid=${roomUuid}`)
    return this.http.get<RoomDef>(this.studentUrl(schoolId, studentId, `/rooms/${roomUuid}`), {
      headers: this.nextRequestIdHeader()
    });
  }

  getStudentScheduleDetails(schoolId: number, studentId: number, scheduleId: number): Observable<ScheduleRow> {
    this.logger.log(`getting student schedule details for scheduleId=${scheduleId}`)
    return this.http.get<ScheduleRow>(this.studentUrl(schoolId, studentId, `/schedules/${scheduleId}`), {
      headers: this.nextRequestIdHeader()
        .append(Constraints.cacheNameHeader, `student-schedule-${scheduleId}-details`)
        .append(Constraints.syncTimeHeader, (Constraints.syncDay).toString())
    });
  }

  getTeacherRoomDetails(schoolId: number, teacherId: number, roomUuid: string): Observable<RoomDef> {
    this.logger.log(`getting teacher room details for roomUuid=${roomUuid}`)
    return this.http.get<RoomDef>(this.teacherUrl(schoolId, teacherId, `/rooms/${roomUuid}`), {
      headers: this.nextRequestIdHeader()
    });
  }

  getTeacherScheduleDetails(schoolId: number, teacherId: number, scheduleId: number): Observable<ScheduleRow> {
    this.logger.log(`getting teacher schedule details for scheduleId=${scheduleId}`)
    return this.http.get<ScheduleRow>(this.teacherUrl(schoolId, teacherId, `/schedules/${scheduleId}`), {
      headers: this.nextRequestIdHeader()
    });
  }

  getTeacherTemplateDetails(schoolId: number, teacherId: number, templateId: number): Observable<IdentifiedRoomTemplate> {
    this.logger.log(`getting teacher template details for templateId=${templateId}`)
    return this.http.get<IdentifiedRoomTemplate>(this.buildPath(`/sec/school/${schoolId}/templates/${templateId}`), {
      headers: this.nextRequestIdHeader()
        .append(Constraints.cacheNameHeader, `teacher-template-${templateId}-details`)
        .append(Constraints.syncTimeHeader, (Constraints.syncMinute).toString())
    });
  }

  listStudentGroups(schoolId: number, studentId: number, pageable: Pageable): Observable<Page<IdentifiedRoomTemplate>> {
    this.logger.log(`getting list of student groups for studentId=${studentId}`)
    const params = Pageable.appendPageableParams(new HttpParams(), pageable);
    return this.http.get<Page<IdentifiedRoomTemplate>>(this.studentUrl(schoolId, studentId, "/templates"), {
      headers: this.nextRequestIdHeader(),
      params: params
    });
  }

  listStudentRooms(schoolId: number, studentId: number, pageable: Pageable): Observable<Page<RoomDef>> {
    this.logger.log(`getting list of student rooms for studentId=${studentId}`)
    const params = Pageable.appendPageableParams(new HttpParams(), pageable);
    return this.http.get<Page<RoomDef>>(this.studentUrl(schoolId, studentId, "/rooms"), {
      headers: this.nextRequestIdHeader(),
      params: params
    });
  }

  listStudentSchedules(schoolId: number, studentId: number, focusDate: number): Observable<ScheduleRow[]> {
    this.logger.log(`getting list of student schedules for studentId=${studentId}`)
    const params = new HttpParams().append("focusDate", focusDate.toString());
    return this.http.get<ScheduleRow[]>(this.studentUrl(schoolId, studentId, "/schedules"), {
      headers: this.nextRequestIdHeader(),
      params: params
    });
  }

  listTeacherGroups(schoolId: number, teacherId: number, pageable: Pageable): Observable<Page<IdentifiedRoomTemplate>> {
    this.logger.log(`getting list of teacher groups for teacherId=${teacherId}`)
    const params = Pageable.appendPageableParams(new HttpParams(), pageable);
    return this.http.get<Page<IdentifiedRoomTemplate>>(this.teacherUrl(schoolId, teacherId, "/templates"), {
      headers: this.nextRequestIdHeader(),
      params: params
    });
  }

  listTeacherRooms(schoolId: number, teacherId: number, pageable: Pageable): Observable<Page<RoomDef>> {
    this.logger.log(`getting list of teacher rooms for teacherId=${teacherId}`)
    const params = Pageable.appendPageableParams(new HttpParams(), pageable);
    return this.http.get<Page<RoomDef>>(this.teacherUrl(schoolId, teacherId, "/rooms"), {
      headers: this.nextRequestIdHeader(),
      params: params
    });
  }

  listTeacherSchedules(schoolId: number, teacherId: number, focusDate: number): Observable<ScheduleRow[]> {
    this.logger.log(`getting list of teacher schedules for teacherId=${teacherId}`)
    const params = new HttpParams().append("focusDate", focusDate.toString());
    return this.http.get<ScheduleRow[]>(this.teacherUrl(schoolId, teacherId, "/schedules"), {
      headers: this.nextRequestIdHeader(),
      params: params
    });
  }

  listTemplateRooms(schoolId: number, teacherId: number, templateId: number, pageable: Pageable): Observable<Page<RoomDef>> {
    this.logger.log(`getting list of template rooms for templateId=${templateId}`)
    const params = Pageable.appendPageableParams(new HttpParams(), pageable);
    return this.http.get<Page<RoomDef>>(this.buildPath(`/sec/school/${schoolId}/templates/${templateId}/rooms`), {
      headers: this.nextRequestIdHeader(),
      params: params
    });
  }

  getTeacherStudents(schoolId: number, term: string, status: string[], expiryDate: number, pageable: Pageable): Observable<Page<Student>> {
    return undefined;
  }

  getStudent(schoolId: number, studentId: number): Observable<Student> {
    return undefined;
  }
}
