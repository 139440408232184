import {Injectable} from '@angular/core';
import {map, Observable, switchMap, tap} from 'rxjs';
import {ItemAvailability} from 'src/app/cspa/model/cspa/personal';
import {Chapter, ExerciseSet} from 'src/app/cspa/model/cspa/struct';
import {NativeServiceApiProvider} from 'src/app/services/native-api-provider.service';
import {Page, Pageable} from 'src/app/utils/pageable';
import {LessonType} from '../../model/calendar';
import {ApiProduct} from '../../model/products';
import {
  ApiCompetence,
  ApiLearningUnitTeacher,
  ApiLessonBundle,
  ApiLessonCommit,
  ApiLessonInstance,
  ApiLessonProgress,
  ApiPersonalProfile,
  ApiPersonTechnicalProfile,
  ApiProductContext, ApiProvaContext,
  ApiTeacherProfile,
  LessonBundleFilter,
  StudentCommitsFilter,
} from '../../model/rest-model';
import {StudentRestService} from "./student-rest.service";
import {Dates} from "../../../utils/calendar-utils";
import {
  ApiLessonEventModel,
  LessonScheduleEventReference,
  SimpleLessonScheduleRequest, SimpleScheduleEvents, SimpleTeacherProductAvailability
} from "../../model/rest-model-v2";
import {StudentBookRestBridgeService} from "./student-book-rest-bridge.service";
import {StudentBookRestServiceImpl} from "./student-book-rest.service";
import {environment} from "../../../../environments/environment";


@Injectable({
  providedIn: 'root',
})
export class StudentRestServiceImpl implements StudentRestService {
  private bookingRest: StudentBookRestBridgeService | StudentBookRestServiceImpl
  constructor(private provider: NativeServiceApiProvider,
              private bookingBridge: StudentBookRestBridgeService,
              private bookingRestV2: StudentBookRestServiceImpl) {
    this.bookingRest = environment.scheduleBridgeEnabled?
      bookingBridge : bookingRestV2
  }

  getTeachers(
    studentId: number,
    teacherIds?: number[]
  ): Observable<ApiTeacherProfile[]> {
    return this.provider
      .col()
      .pipe(switchMap((api) => api.getTeachers(studentId, teacherIds)));
  }

  findUpcomingNextLesson(studentId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) => api.findUpcomingNextLesson(studentId)),
        tap((lesson) => {
          if (!lesson) {
            return;
          }
          lesson.lessonMetric.plannedStartDate = Dates.parse(
            String(lesson.lessonMetric.plannedStartDate)
          );
        }));
  }

  findUpcomingNextLessonsMultiple(studentId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[]> {
    return this.provider.col().pipe(
      switchMap((api) => api.findUpcomingNextLessonsMultiple(studentId)),
      tap( lesson => {
        if (!lesson) {
          return;
        }
        lesson.map(lesson =>lesson.lessonMetric.plannedStartDate = Dates.parse(String(lesson.lessonMetric.plannedStartDate)));
      })
    );
  }

  getExerciseSets(): Observable<ExerciseSet[]> {
    return this.provider.col().pipe(switchMap((api) => api.getExerciseSets()));
  }

  getChapters(path: string): Observable<Chapter[]> {
    return this.provider.col().pipe(switchMap((api) => api.getChapters(path)));
  }

  getAvailabilities(
    path: string,
    depth: number
  ): Observable<ItemAvailability[]> {
    return this.provider
      .col()
      .pipe(switchMap((api) => api.getAvailabilities(path, depth)));
  }

  getProductsList(
    productCode: string,
    currency: string
  ): Observable<ApiProduct[]> {
    return this.provider
      .col()
      .pipe(switchMap((api) => api.getProductsList(productCode, currency)));
  }

  getStudentLessonBundles(
    studentId: number,
    langCode: string,
    pageable: Pageable,
    available: boolean = true
  ): Observable<Page<ApiLessonBundle>> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.getStudentLessonBundles(
            studentId,
            new LessonBundleFilter(langCode, available),
            pageable
          )
        )
      );
  }

  getCommits(
    studentId: number,
    searchFilter: StudentCommitsFilter,
    pageable: Pageable
  ): Observable<Page<ApiLessonCommit>> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) => api.getCommits(studentId, searchFilter, pageable))
      );
  }

  getStudentProgress(studentId: number): Observable<ApiLessonProgress[]> {
    return this.provider
      .col()
      .pipe(switchMap((api) => api.getStudentProgress(studentId, null, false)));
  }

  getLessons(
    studentId: number,
    pageable: Pageable,
    lessonType: LessonType,
    langCode: string
  ): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.getLessons(studentId, pageable, lessonType, false, langCode)
        ),
        tap((lessonsPage) =>
          lessonsPage.content.forEach((lesson) => {
            lesson.lessonMetric.plannedStartDate = Dates.parse(
              String(lesson.lessonMetric.plannedStartDate)
            );
            if (lessonType === LessonType.Past)
              lesson.lessonMetric.started = Dates.parse(
                String(lesson.lessonMetric.started)
              );
          })
        )
      );
  }

  getLesson(
    studentId: number,
    lessonId: number
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) => api.getLesson(studentId, lessonId)),
        tap((lesson) => {
          lesson.lessonMetric.plannedStartDate = Dates.parse(
            String(lesson.lessonMetric.plannedStartDate)
          );
          lesson.lessonMetric.started = Dates.parse(
            String(lesson.lessonMetric.started)
          );
        })
      );
  }

  squanderLesson(
    studentId: number,
    lessonId: number,
    reason: string
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) => api.squanderLesson(studentId, lessonId, reason)),
        tap((lesson) => {
          lesson.lessonMetric.plannedStartDate = Dates.parse(
            String(lesson.lessonMetric.plannedStartDate)
          );
          lesson.lessonMetric.started = Dates.parse(
            String(lesson.lessonMetric.started)
          );
        })
      );
  }

  cancelLesson(
    studentId: number,
    lessonId: number,
    reason: string
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) => api.cancelLesson(studentId, lessonId, reason)),
        tap((lesson) => {
          lesson.lessonMetric.plannedStartDate = Dates.parse(
            String(lesson.lessonMetric.plannedStartDate)
          );
          lesson.lessonMetric.started = Dates.parse(
            String(lesson.lessonMetric.started)
          );
        }));
  }

  getProductContext(
    studentId: number,
    langCode: string
  ): Observable<ApiProductContext> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) => api.getProductContext(studentId, langCode)),
        map(productContexts => {
          if(productContexts)
            productContexts.courseContexts = productContexts?.courseContexts.sort(
              (a, b) => a.course.code.localeCompare(b.course.code)
            )
          return productContexts
        })
      );
  }

  getLessonScheduleEventReference(
    studentId: number,
    scheduleId: number
  ): Observable<LessonScheduleEventReference<ApiLessonEventModel>> {
    return this.provider
      .col()
      .pipe(switchMap(_ => this.bookingRest.getLessonScheduleEventReference(studentId, scheduleId)));
  }

  reserveSchedule(
    studentId: number,
    request: SimpleLessonScheduleRequest
  ): Observable<SimpleScheduleEvents> {
    return this.provider
      .col()
      .pipe(switchMap(_ => this.bookingRest.reserveSchedule(studentId, request)));
  }

  listStudentLessonSchedules(
    studentId: number,
    focusDate: Date,
    productCode: string
  ): Observable<SimpleScheduleEvents[]> {
    return this.provider
      .col()
      .pipe(
        switchMap(_ => this.bookingRest.listStudentLessonSchedules(studentId, focusDate, productCode))
      );
  }

  listStudentSchoolTeachersAvailability(
    studentId: number,
    focusDate: Date,
    productCode: string
  ): Observable<SimpleTeacherProductAvailability[]> {
    return this.provider
      .col()
      .pipe(
        switchMap(_ =>
          this.bookingRest.listStudentSchoolTeachersAvailability(studentId, focusDate, productCode)
        )
      );
  }

  getCourseAllowedCompetences(courseCode: string): Observable<ApiCompetence[]> {
    return this.provider
      .col()
      .pipe(switchMap((api) => api.getCourseAllowedCompetences(courseCode)));
  }

  getProductProgressEstimation(
    studentId: number,
    langCode: string
  ): Observable<ApiLessonProgress[]> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.getProductProgressEstimation(studentId, langCode)
        )
      );
  }

  getProvaContext(studentId: number, productCode?: string): Observable<ApiProvaContext[]> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.getProvaContext(studentId, productCode)
        )
      )
  }


  addStudentProductContext(studentId: number, productCode: string): Observable<ApiProductContext> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.addStudentProductContext(studentId, productCode)
        )
      )
  }
  public chargeFreeCredit(studentId: number, productCode: string):
    Observable<ApiLessonBundle> {
    return this.provider
      .col()
      .pipe(
        switchMap((api) =>
          api.chargeFreeCredit(studentId, productCode)
        )
      )
  }

  loadTechnicalProfile(studentId: number): Observable<ApiPersonTechnicalProfile>{
    return this.provider.col().pipe(switchMap((api) => api.getStudentTechnicalProfile(studentId)))
  }

  updateIntroductionState(studentId: number, state: string): Observable<ApiPersonTechnicalProfile>{
    return this.provider.col().pipe(switchMap((api) => api.updateIntroductionState(studentId, state)))
  }

}
