import {Observable, Observer, tap} from "rxjs";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {switchMap} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {CspaExamApi, CspaMobileNativeApi, MobileNativeAudio} from "../cspa.api";
import {LogsService} from "../../../../utils/services/logs.service";
import {AndroidBridgeBase} from "../../../../mobile/api-services/bridge/android-bridge-base";
import {ExamSession, ExerciseSession, ExerciseSessionQuestion, ItemAvailability} from "../../../model/cspa/personal";
import {Chapter, ExerciseSet} from "../../../model/cspa/struct";
import {AnswerDefinitionBase, Question} from "../../../model/cspa/questions";
import {Constraints, Platform} from "../../../../constraints";
import { Page, Pageable } from "src/app/utils/pageable";

/**
 * Methods which may be called by native in web app
 */
interface AndroidWebBridge {
  /**
   * call after you provide native bridge implementation to the WebView
   */
  initialize();
  /**
   * Call if data state has been changed and you
   * expect the current View data should be reloaded
   */
  notifyDataUpdate();
}

/**
 * Methods which may be called by web in native
 */
interface NativeBridge {

  /**
   * store the data for the coming request. Request is identified by provided numeric value
   * which will be provided in "X-Request-Id" header
   * @param id of the request which data belongs to
   */
  storeRequestData(id: string, data: string): void;

  /**
   * clear the recording buffer
   * @param id request id
   */
  clear(id: number): void;

  /**
   * start sound recording
   * @param id request id
   */
  record(id: number): void;

  /**
   * stop sound recording or playing
   * @param id
   */
  stop(id: number): void;

  /**
   * play recorded sound (if is still recording stop recording first)
   * @param id
   */
  play(id: number): void;

  /**
   * terminate (release) the recording system (for example you may need to stop recording,
   * release a media player or media recorder
   * @param id
   */
  terminate(id: number): void;

  /**
   * Initialize sound system. I.e. ask for privileges
   * @param id
   * @return true if is initialized correctly and allows to record the sound
   */
  init(id: number): boolean;

  /**
   * close the web application. Remember check if sound recording device is terminated
   */
  close(id: number): void;

  /**
   * start bridge initialization
   */
  begin(): void;

  /**
   * check if device is offline
   */
  isOffline(id: number): boolean
}

/**
 * Temporary structure to keep requests invocation until AndroidWebBridge.initialize() method
 * call by native
 */
class WaitingCall<T> {
  constructor(
    public id: number,
    private nativeCall: (id: number) => T,
    private observer: Observer<T>
  ) {}
  public doCall() {
    try {
      const result = this.nativeCall(this.id);
      this.observer.next(result);
      this.observer.complete;
    } catch (e) {
      this.observer.error(e);
    }
  }
}

/**
 * The service is responsible for the communication between native and web part.
 * Before a full communication channel would be established the method initialize() has to be invoked first
 * by the native.
 *
 * The communication uses two different techniques. Methods related to the sound recording device
 * calls native api directly. Other uses a REST protocol which has to be intercepted by a native.
 * For data manipulation requests like POST or PUT we had to prepare a special
 * protocol for data providing (because request body can't be read during the native intercept phase).
 * For that cases api calls storeRequestData() method which provides a request body
 * before a regular request call.
 *
 * The URL scheme is formatted in typical tree structure:
 * + /sets +                                              - set definitions container
 * |       + /${exerciseSet} +                            - specific set content definitions container (chapters)
 * |                         + availability               - specific set availabilities container
 * |                         + questions                  - specific set question definitions container
 * + sessions +                                           - device stored sessions container
 *            + current                                   - device current session content
 */
@Injectable({
  providedIn: 'root'
})
export class AndroidRequestBasedNativeApi implements CspaMobileNativeApi, CspaExamApi, MobileNativeAudio, AndroidWebBridge {

  private apiEndpoint = Constraints.androidInternalApiEndpoint;

  constructor(
    private logger: LogsService,
    private http: HttpClient,
    private bridgeBase: AndroidBridgeBase
    ) {
  }

  /**
   * build full request URL value basing on the relative path
   *
   * with the current environment value the call would be the same as { return `http://iternal-api-calls/api/${rest of the path}; }
   *
   * @param path
   * @private
   */
  private buildPath(path: string) {
    return `${this.apiEndpoint}/api${path}`;
  }

  private buildPlatformPath(path){
    return `${this.apiEndpoint}/platform/${Platform.Cspa}/api${path}`;
  }

  initialize() {
    this.bridgeBase.initialize();
  }

  /**
   * generic method to store the POST, PUT request data before
   * request invocation
   * @param data
   */
  private storeRequestData(data: any): Observable<number> {
    return this.bridgeBase.callNative(id => {
      (window.nativeBridge as NativeBridge).storeRequestData(id.toString(), JSON.stringify(data));
      return id;
    })
  }

  notifyDataUpdate(): void {
    this.logger.log('notifying data update');
    this.bridgeBase.updateEventsSubject.next(null);
  }

  listenForDataUpdates(): Observable<void> {
    return this.bridgeBase.updateEventsSubject.asObservable();
  }

  log(text: string) {
    this.logger.log(`[android-api2] - ${text}`);
  }

  clear(): Observable<void> {
    this.log("calling clear");
    return this.bridgeBase.callNative<void>(
      id => (window.nativeBridge as NativeBridge).clear(id)
    );
  }

  /**
   * start recording
   */
  record() {
    this.log("calling record");
    return this.bridgeBase.callNative<void>(
      id => (window.nativeBridge as NativeBridge).record(id)
    ).subscribe();
  }

  /**
   * stop recording or playback
   */
  stop(): Observable<void> {
    this.log("calling stop");
    return this.bridgeBase.callNative<void>(id => (window.nativeBridge as NativeBridge).stop(id));
  }

  /**
   * start recording playback
   */
  play2() {
    this.log("calling play");
    return this.bridgeBase.callNative<void>(
      id => (window.nativeBridge as NativeBridge).play(id)
    ).subscribe();
  }

  /**
   * clean up and release recording device
   */
  terminate() {
    this.log("calling terminate");
    return this.bridgeBase.callNative<void>(
      id => (window.nativeBridge as NativeBridge).terminate(id)
    ).subscribe();
  }

  /**
   * initialize recording device, returns true if positive
   * @param callback
   */
  initAudio(callback: (state: boolean) => void) {
    this.log(" calling clear");
    return this.bridgeBase.callNative<boolean>(
      id => (window.nativeBridge as NativeBridge).init(id)
    ).subscribe(
      it => callback(it)
    )
  }

  private static buildRequestIdHeader(id: number){
    return new HttpHeaders({"X-Request-Id" : id.toString()});
  }

  private nextRequestIdHeader(){
    return AndroidRequestBasedNativeApi.buildRequestIdHeader(this.bridgeBase.getNextRequestId());
  }

  /**
   * read the availability JSON file for exerciseSet [GET] /sets/${exerciseSet}/availability
   * @param exerciseSet
   */
  getAvailabilities(exerciseSet: string): Observable<ItemAvailability[]> {
    this.log(`getting availabilities for ${exerciseSet}`);
    return this.http.get<ItemAvailability[]>(this.buildPath(`/sets/${exerciseSet}/availability`), {headers: this.nextRequestIdHeader()})
  }

  getTopAvailabilities(): Observable<ItemAvailability[]> {
    this.log("getting all availabilities");
    return this.http.get<ItemAvailability[]>(this.buildPath(`/sets/availability`), {headers: this.nextRequestIdHeader()})
  }

  /**
   * read chapters JSON file for exerciseSet [GET] /sets/${exerciseSet}
   * @param exerciseSet
   */
  getChapters(exerciseSet: string): Observable<Chapter[]> {
    this.log(`getting chapters for ${exerciseSet}`);
    return this.http.get<Chapter[]>(this.buildPath(`/sets/${exerciseSet}`), {headers: this.nextRequestIdHeader()});
  }

  /**
   * read current session JSON file [GET] /sessions/current
   */
  getCurrentSession(): Observable<ExerciseSession> {
    this.log(`getting current session`);
    return this.http.get<ExerciseSession>(this.buildPath(`/sessions/current`), {headers: this.nextRequestIdHeader()});
  }

  /**
   * read questions file for exerciseSet [GET] /sets/${exerciseSet}/questions
   * @param exerciseSet
   */
  getQuestions(exerciseSet: string): Observable<Question<any, any>[]> {
    this.log(`getting questions for ${exerciseSet}`);
    return this.http.get<Question<any,any>[]>(this.buildPath(`/sets/${exerciseSet}/questions`), {headers: this.nextRequestIdHeader()});
  }

  /**
   * read exercise sets file (has to be synced by the native app !!!!) [GET] /sets
   */
  listExerciseSetsWithSync(frequencyMs: number): Observable<ExerciseSet[]> {
    this.log('getting exercise sets');
    return this.http.get<ExerciseSet[]>(this.buildPath(`/sets`), {
      headers: this.nextRequestIdHeader(),
      params: AndroidRequestBasedNativeApi.prepareFreqParams(frequencyMs)
    });
  }

  /**
   * push session to the FIFO sync queue [POST] /sessions {request body - session to store, response body - stored session}
   * @param session
   */
  pushSession(session: ExerciseSession): Observable<ExerciseSession> {
    this.log(`pushing session ${session.deviceUUID}`);
    return this.storeRequestData(session).pipe(
      switchMap( id => this.http.post<ExerciseSession>(this.buildPath("/sessions"), {}, {headers: AndroidRequestBasedNativeApi.buildRequestIdHeader(id)}))
    )
  }

  /**
   * send stored sync sessions queue to the API and clean up the queue if positive. [POST] /sessions/sync {request and response body is empty}
   * Sync in FIFO order
   */
  sendStoredSessions(): Observable<any> {
    this.log(`sending sessions to the server`);
    return this.http.post<void>(this.buildPath("/sessions/sync"), {headers: this.nextRequestIdHeader()})
  }

  /**
   * store availability in the file, dont change update time [PUT] /sets/${exerciseSet}/availability {request and response body has a data to write}
   * @param exerciseSet
   * @param availability
   */
  storeAvailability(exerciseSet: string, availability: ItemAvailability[]): Observable<ItemAvailability[]> {
    this.log(`storing availabilities for ${exerciseSet}`);
    return this.storeRequestData(availability).pipe(
      switchMap( id => this.http.put<ItemAvailability[]>(this.buildPath(`/sets/${exerciseSet}/availability`), {}, {headers: AndroidRequestBasedNativeApi.buildRequestIdHeader(id)}))
    )
  }

  storeTopAvailabilities(topAvailabilities: ItemAvailability[]): Observable<any> {
    this.log("storing top availabilities")
    return this.storeRequestData(topAvailabilities).pipe(
      switchMap( id => this.http.put<ItemAvailability[]>(this.buildPath(`/sets/availability`), {}, {headers: AndroidRequestBasedNativeApi.buildRequestIdHeader(id)}))
    )
  }

  /**
   * store session in current session file [PUT] /sessions/current {bodies are session to save}
   * @param session
   */
  storeCurrentSession(session: ExerciseSession): Observable<ExerciseSession> {
    this.log(`storing current session ${session.deviceUUID}`);
    return this.storeRequestData(session).pipe(
      switchMap( id => this.http.put<ExerciseSession>(this.buildPath("/sessions/current"), {}, {headers: AndroidRequestBasedNativeApi.buildRequestIdHeader(id)}))
    )
  }

  /**
   * Append or create http parameter to pass the sync frequency value in http request
   * @param freq frequency in Ms
   * @param paramsToAppend existing request parameters or empty if non exists
   */
  private static prepareFreqParams(freq: number, paramsToAppend?: HttpParams) {
    if (!paramsToAppend) {
      paramsToAppend = new HttpParams();
    }
    if (!freq) return paramsToAppend;
    return paramsToAppend.append("freq", freq.toString());
  }

  /**
   * do the file sync if required. Check if the existing version is older than @syncFrequencyMs [POST] /sets/${exerciseSet}/availability/sync {bodies are empty}
   * @param exerciseSet
   * @param syncFrequencyMs
   */
  syncAvailabilities(exerciseSet: string, syncFrequencyMs: number): Observable<any> {
    this.log(`syncing availabilities for ${exerciseSet}`);
    return this.http.post<any>(this.buildPath(`/sets/${exerciseSet}/availability/sync`), {}, {
      headers: this.nextRequestIdHeader(),
      params: AndroidRequestBasedNativeApi.prepareFreqParams(syncFrequencyMs)
    });
  }

  syncTopAvailabilities(syncFrequencyMs: number): Observable<void> {
    this.log("syncing all availabilities")
    return this.http.post<any>(this.buildPath(`/sets/availability/sync`), {}, {
      headers: this.nextRequestIdHeader(),
      params: AndroidRequestBasedNativeApi.prepareFreqParams(syncFrequencyMs)
    });
  }

  /**
   * do the file sync if required. Check if the existing version is older than @syncFrequencyMs [POST] /sets/${exerciseSet} {request body empty, response has chapters}
   * @param exerciseSet
   * @param syncFrequencyMs
   */
  syncChapters(exerciseSet: string, syncFrequencyMs: number): Observable<Chapter[]> {
    this.log(`syncing chapters for ${exerciseSet}`);
    return this.http.post<Chapter[]>(this.buildPath(`/sets/${exerciseSet}/sync`), {}, {
      headers: this.nextRequestIdHeader(),
      params: AndroidRequestBasedNativeApi.prepareFreqParams(syncFrequencyMs)
    });
  }

  /**
   * do the file sync if required. Check if the existing version is older than @syncFrequencyMs [POST] /sets/${exerciseSet}/questions/sync {bodies are empty}
   * @param exerciseSet
   * @param syncFrequencyMs
   */
  syncQuestions(exerciseSet: string, syncFrequencyMs: number): Observable<any> {
    this.log(`syncing questions for ${exerciseSet}`);
    return this.http.post(this.buildPath(`/sets/${exerciseSet}/questions/sync`), {}, {
      headers: this.nextRequestIdHeader(),
      params: AndroidRequestBasedNativeApi.prepareFreqParams(syncFrequencyMs)
    })
  }

  /**
   * do application close
   */
  close(): void {
    this.log('calling for close the app.');
    this.bridgeBase.callNative<void>(id => (window.nativeBridge as NativeBridge).close(id)).subscribe();
  }

  // #### data management api #####
  subscribeForRefreshingEvents(): Observable<Observable<void>> {
    return this.bridgeBase.refreshingSubject.asObservable();
  }

  finishExamSession(sessionUuid: string): Observable<ExamSession> {
    this.log(`finishing exam session (${sessionUuid})`);
    return this.http.post<ExamSession>(this.buildPlatformPath(`/exam-sessions/${sessionUuid}/finish`), {}, {
      headers: this.nextRequestIdHeader()
    });
  }

  finishExamSessionPart(sessionUuid: string): Observable<ExamSession> {
    this.log(`finishing exam session (${sessionUuid}) part`);
    return this.http.post<ExamSession>(this.buildPlatformPath(`/exam-sessions/${sessionUuid}/finish-part`), {}, {
      headers: this.nextRequestIdHeader()
    });
  }

  getExamSession(sessionUuid: String): Observable<ExamSession> {
    this.log(`getting exam session (${sessionUuid})`);
    return this.http.get<ExamSession>(this.buildPlatformPath(`/exam-sessions/${sessionUuid}`), {
      headers: this.nextRequestIdHeader()
    });
  }

  isOffline(): Observable<boolean> {
    return this.bridgeBase.callNative<boolean>(id => (window.nativeBridge as NativeBridge).isOffline(id)).pipe(
      tap(id => this.logger.log(`isOffline - id = ${id}`))
    );
  }

  postExamSessionQuestionAnswer<A extends AnswerDefinitionBase>(sessionUuid: string, questionNb: number, sessionQuestion: ExerciseSessionQuestion<A, any>): Observable<ExamSession> {
    this.log(`posting question answer exam session (${sessionUuid})`);
    return this.storeRequestData(sessionQuestion).pipe(
      switchMap(id => this.http.post<ExamSession>(this.buildPlatformPath(`/exam-sessions/${sessionUuid}/questions/${questionNb}`), {}, {
        headers: AndroidRequestBasedNativeApi.buildRequestIdHeader(id)
      }))
    )
  }

  startExamSession(path: string): Observable<ExamSession> {
    this.log(`starting exam session for ${path}`);
    return this.storeRequestData(path).pipe(
      switchMap(id => this.http.post<ExamSession>(this.buildPlatformPath(`/exam-sessions/create`), {}, {
        headers: AndroidRequestBasedNativeApi.buildRequestIdHeader(id)
      }))
    )
  }

  startExamSessionPart(sessionUuid: string, partNumber: number): Observable<ExamSession> {
    this.log(`starting exam session (${sessionUuid}) part nb ${partNumber}`);
    let params = new HttpParams().append("localTime", (new Date()).getTime())
    return this.http.post<ExamSession>(this.buildPlatformPath(`/exam-sessions/${sessionUuid}/create/${partNumber}`), {}, {
      headers: this.nextRequestIdHeader(),
      params: params
    });
  }

  listPersonAvailabilities(schoolId: number, personId: number, path: string, depth: number): Observable<ItemAvailability[]>{
    this.log(`listing person availabilities for school (${schoolId}) personId (${personId}), path (${path}) depth (${depth})`);
    let params = new HttpParams().append("path", path).append("depth", depth.toString());
    return this.http.get<ItemAvailability[]>(this.buildPlatformPath(`/school/${schoolId}/teachers/person/${personId}/availability`), {
      headers: this.nextRequestIdHeader(),
      params: params
    })
  }

  findSessions(schoolId: number, personId: number, pathPrefix: string, pageable?: Pageable): Observable<Page<ExerciseSession>>{
    this.log(`getting exam sessions for school (${schoolId}) personId (${personId}) for (${pathPrefix})`);
    const params = Pageable.appendPageableParams(new HttpParams().append("pathPrefix", pathPrefix), pageable)
    return this.http.get<Page<ExerciseSession>>(this.buildPlatformPath(`/school/${schoolId}/teachers/person/${personId}/sessions`), {
      headers: this.nextRequestIdHeader(),
      params: params
    });
  }

  findExamSessions(schoolId: number, personId: number, pathPrefix: string): Observable<ExamSession[]>{
    this.log(`getting sessions for school (${schoolId}) personId (${personId}) for (${pathPrefix})`);
    const params = new HttpParams()
      if(pathPrefix)
        params.append("pathPrefix", pathPrefix)
      return this.http.get<ExamSession[]>(this.buildPlatformPath(`/school/${schoolId}/person/${personId}/exam-sessions`), {params});

  }
}
