import {Component, OnInit} from '@angular/core';
import {Student} from "../../../auth_profile/model/casa/casa-model";
import {ActivatedRoute, Event, Router, RouterEvent, UrlSegment} from "@angular/router";
import {filter, Observable, switchMap, tap} from "rxjs";
import {AuthorizationServiceProvider} from "../../../auth_profile/services/authorization-service.provider";
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {PersonUtils} from "../../../auth_profile/utils/person-utils";
import {SpinnerService} from "../../../utils/services/spinner.service";

@Component({
  selector: 'app-teacher-student-details-layout-page',
  templateUrl: './teacher-student-details-layout-page.component.html',
  styleUrls: ['./teacher-student-details-layout-page.component.scss']
})
export class TeacherStudentDetailsLayoutPageComponent implements OnInit {
  private supportedPaths = ['credits', 'lessons', 'profile', 'products', 'cspa-monitor', 'casa-profile', 'exam-results'];
  private currentFragments: string[];
  schoolId: number;
  studentId: number;
  student: Student;
  currentFragment: string;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthorizationServiceProvider,
    private provider: NativeServiceApiProvider,
    private spinnerService: SpinnerService
  ) {
    router.events.pipe(
      filter((e: Event): e is RouterEvent => e instanceof RouterEvent)
    ).subscribe((e: RouterEvent) => {
      e['urlAfterRedirects'] ? this.currentFragments = e['urlAfterRedirects'].split('/') : ''
      this.currentFragment = this.currentFragments[this.currentFragments.length - 1]
    });
  }

  ngOnInit() {
    this.activeRoute.paramMap.pipe(
      tap(params => {
        this.studentId = Number(params.get('studentId'));
      }),
      switchMap(_ => this.authService.getAuthDetailsService()),
      switchMap(api => api.getSelfSchoolId()),
      tap(schoolId => this.schoolId = schoolId),
      switchMap(_ => this.spinnerService.trace(this.loadStudent()))
    ).subscribe();
  }

  getPersonName() {
    return this.getName(this.student);
  }

  getName(student: Student) {
    return PersonUtils.getPersonName(student.person.details);
  }

  getInitials(student: Student) {
    return PersonUtils.getInitials(student.person.details)
  }


  loadStudent(): Observable<Student> {
    return this.provider.video().pipe(
      switchMap(api => api.getStudent(this.schoolId, this.studentId)),
      tap(student => {
        this.student = student;
        const childActiveStateUrl = (this.activeRoute.children && this.activeRoute.children.length > 0) ? this.activeRoute.children[0].snapshot.url : null;
        const selectedStudentPage = this.getStudentPageSelected(childActiveStateUrl);
        if (!selectedStudentPage) {
          this.redirectToStudentDefaultPage();
        }
      })
    )
  }

  getStudentPageSelected(urls: UrlSegment[]) {
    if (!urls) return null;
    if (urls.length > 0) {
      const lastPath = urls.length > 0 ? urls[urls.length - 1].path : '';

      if (this.supportedPaths.indexOf(lastPath) >= 0) return lastPath;
      else return null;
    }
    return null;
  }

  private redirectToStudentDefaultPage() {
    if (!this.schoolId || !this.studentId) return;
    this.router.navigate(["teacher", "students", this.studentId.toString(), "cspa-monitor"]).then();
  }

  getStatus() {
    if (!this.student) return '';
    return this.student.status;
  }

  getAccountEmail() {
    if (!this.student) return '';
    return this.student.person.registrationEmail;
  }
}
