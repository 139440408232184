import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Page, Pageable } from "src/app/utils/pageable";
import { environment } from "src/environments/environment";
import {IdentifiedRoomTemplate, RoomDef, RoomProgressReport, ScheduleRow} from "../models/video.model";
import {Student} from "../../auth_profile/model/casa/casa-model";

export interface VideoApi {
  getStudentRoomDetails(schoolId: number, studentId: number, roomUuid: string): Observable<RoomDef>
  listStudentRooms(schoolId: number, studentId: number, pageable: Pageable): Observable<Page<RoomDef>>
  getStudentScheduleDetails(schoolId: number, studentId: number, scheduleId: number): Observable<ScheduleRow>
  listStudentSchedules(schoolId: number, studentId: number, focusDate: number): Observable<ScheduleRow[]>
  listStudentGroups(schoolId: number, studentId: number, pageable: Pageable): Observable<Page<IdentifiedRoomTemplate>>

  getTeacherRoomDetails(schoolId: number, teacherId: number, roomUuid: string): Observable<RoomDef>
  listTeacherRooms(schoolId: number, teacherId: number, pageable: Pageable): Observable<Page<RoomDef>>
  getTeacherScheduleDetails(schoolId: number, teacherId: number, scheduleId: number): Observable<ScheduleRow>
  listTeacherSchedules(schoolId: number, teacherId: number, focusDate: number): Observable<ScheduleRow[]>
  listTeacherGroups(schoolId: number, teacherId: number, pageable: Pageable): Observable<Page<IdentifiedRoomTemplate>>
  getTeacherTemplateDetails(schoolId: number, teacherId: number, templateId: number): Observable<IdentifiedRoomTemplate>
  getProgressHistory(schoolId: number, templateId: number, pageable: Pageable): Observable<Page<RoomProgressReport>>

  listTemplateRooms(schoolId: number, teacherId: number, templateId: number, pageable: Pageable): Observable<Page<RoomDef>>

  getTeacherStudents(schoolId: number, term: string, status: string[], expiryDate: number, pageable: Pageable): Observable<Page<Student>>
  getStudent(schoolId: number, studentId: number): Observable<Student>
}

@Injectable({
  providedIn: 'root'
})
export class UnsupportedVideoApi implements VideoApi {
  getStudentRoomDetails(schoolId: number, studentId: number, roomUuid: string): Observable<RoomDef> {
    throw new Error("Unsupported API");
  }

  getStudentScheduleDetails(schoolId: number, studentId: number, scheduleId: number): Observable<ScheduleRow> {
    throw new Error("Unsupported API");
  }

  listStudentRooms(schoolId: number, studentId: number, pageable: Pageable): Observable<Page<RoomDef>> {
    throw new Error("Unsupported API");
  }

  listStudentSchedules(schoolId: number, studentId: number, focusDate: number): Observable<ScheduleRow[]> {
    throw new Error("Unsupported API");
  }

  listStudentGroups(schoolId: number, studentId: number, pageable: Pageable): Observable<Page<IdentifiedRoomTemplate>> {
    throw new Error("Unsupported API");
  }

  getTeacherRoomDetails(schoolId: number, teacherId: number, roomUuid: string): Observable<RoomDef> {
    throw new Error("Unsupported API");
  }

  getTeacherScheduleDetails(schoolId: number, teacherId: number, scheduleId: number): Observable<ScheduleRow> {
    throw new Error("Unsupported API");
  }

  listTeacherGroups(schoolId: number, teacherId: number, pageable: Pageable): Observable<Page<IdentifiedRoomTemplate>> {
    throw new Error("Unsupported API");
  }

  listTeacherRooms(schoolId: number, teacherId: number, pageable: Pageable): Observable<Page<RoomDef>> {
    throw new Error("Unsupported API");
  }

  listTeacherSchedules(schoolId: number, teacherId: number, focusDate: number): Observable<ScheduleRow[]> {
    throw new Error("Unsupported API");
  }

  getProgressHistory(schoolId: number, templateId: number, pageable: Pageable): Observable<Page<RoomProgressReport>> {
    throw new Error("Unsupported API");
  }

  getTeacherTemplateDetails(schoolId: number, teacherId: number, templateId: number): Observable<IdentifiedRoomTemplate> {
    throw new Error("Unsupported API");
  }

  listTemplateRooms(schoolId: number, teacherId: number, templateId: number, pageable: Pageable): Observable<Page<RoomDef>> {
    throw new Error("Unsupported API");
  }

  getTeacherStudents(schoolId: number, term: string, status: string[], expiryDate: number, pageable: Pageable): Observable<Page<Student>> {
    return undefined;
  }

  getStudent(schoolId: number, studentId: number): Observable<Student> {
    return undefined;
  }

}

@Injectable({
    providedIn: 'root'
})
export class VideoRestService implements VideoApi {

    constructor(private http: HttpClient) {}

    private casaUrl(method: string) {
      return `${environment.casaEndpoint}/api/v2${method}`;
    }

    private studentUrl(schoolId: number, studentId: number, path: string): string {
        return `${environment.videoEndpoint}/sec/school/${schoolId}/student/${studentId}${path}`;
    }

    private teacherUrl(schoolId: number, teacherId: number, path: string) {
      return `${environment.videoEndpoint}/sec/school/${schoolId}/teacher/${teacherId}${path}`;
    }

    public listStudentGroups(schoolId: number, studentId: number, pageable: Pageable): Observable<Page<IdentifiedRoomTemplate>> {
        const params = Pageable.appendPageableParams(new HttpParams(), pageable);
        return this.http.get<Page<IdentifiedRoomTemplate>>(this.studentUrl(schoolId, studentId, "/templates"), {params});
    }

    public listStudentRooms(schoolId: number, studentId: number, pageable: Pageable): Observable<Page<RoomDef>> {
        const params = Pageable.appendPageableParams(new HttpParams(), pageable);
        return this.http.get<Page<RoomDef>>(this.studentUrl(schoolId, studentId, "/rooms"), {params});
    }

    public listStudentSchedules(schoolId: number, studentId: number, focusDate: number) {
        const params = new HttpParams().append("focusDate", focusDate.toString());
        return this.http.get<ScheduleRow[]>(this.studentUrl(schoolId, studentId, "/schedules"), {params})
    }

    public getStudentRoomDetails(schoolId: number, studentId: number, roomUuid: string): Observable<RoomDef> {
        return this.http.get<RoomDef>(this.studentUrl(schoolId, studentId, `/rooms/${roomUuid}`));
    }

    public getStudentScheduleDetails(schoolId: number, studentId: number, scheduleId: number) {
        return this.http.get<ScheduleRow>(this.studentUrl(schoolId, studentId, `/schedules/${scheduleId}`), );
    }

    public listTeacherGroups(schoolId: number, teacherId: number, pageable: Pageable): Observable<Page<IdentifiedRoomTemplate>> {
      const params = Pageable.appendPageableParams(new HttpParams(), pageable);
      return this.http.get<Page<IdentifiedRoomTemplate>>(this.teacherUrl(schoolId, teacherId, "/templates"), {params});
    }

    public listTeacherRooms(schoolId: number, teacherId: number, pageable: Pageable): Observable<Page<RoomDef>> {
      const params = Pageable.appendPageableParams(new HttpParams(), pageable);
      return this.http.get<Page<RoomDef>>(this.teacherUrl(schoolId, teacherId, "/rooms"), {params});
    }

    public listTeacherSchedules(schoolId: number, teacherId: number, focusDate: number) {
      const params = new HttpParams().append("focusDate", focusDate.toString());
      return this.http.get<ScheduleRow[]>(this.teacherUrl(schoolId, teacherId, "/schedules"), {params})
    }

    public getTeacherRoomDetails(schoolId: number, teacherId: number, roomUuid: string): Observable<RoomDef> {
      return this.http.get<RoomDef>(this.teacherUrl(schoolId, teacherId, `/rooms/${roomUuid}`));
    }

    public getTeacherScheduleDetails(schoolId: number, teacherId: number, scheduleId: number) {
      return this.http.get<ScheduleRow>(this.teacherUrl(schoolId, teacherId, `/schedules/${scheduleId}`));
    }
    public getTeacherTemplateDetails(schoolId: number, teacherId: number, templateId: number) {
      return this.http.get<IdentifiedRoomTemplate>(`${environment.videoEndpoint}/sec/school/${schoolId}/templates/${templateId}`);
    }
    public listTemplateRooms(schoolId: number, teacherId: number, templateId: number, pageable: Pageable) {
      const params = Pageable.appendPageableParams(new HttpParams(), pageable);
      return this.http.get<Page<RoomDef>>(`${environment.videoEndpoint}/sec/school/${schoolId}/templates/${templateId}/rooms`, { params });
    }

    public getProgressHistory(schoolId: number, templateId: number, pageable: Pageable): Observable<Page<RoomProgressReport>> {
      const params: HttpParams = pageable ? pageable.getHttpParams() : null;
      return this.http.get<Page<RoomProgressReport>>(`${environment.videoEndpoint}/sec/school/${schoolId}/templates/${templateId}/progress-history`, {params} );
    }

  public getTeacherStudents(schoolId: number, term: string, status: string[], expiryDate: number, pageable: Pageable): Observable<Page<Student>> {
    let params = new HttpParams();
    params = Pageable.appendPageableParams(params, pageable)
    if (term) params = params.append("term", term);
    if (expiryDate) params = params.append("expiry", expiryDate.toString());
    if (status) params = status.reduce((p, s) => p.append("status", s), params);
    return this.http.get<Page<Student>>(this.casaUrl(`/schools/${schoolId}/teachers/students`), {params});
  }

  public getStudent(schoolId: number, studentId: number): Observable<Student> {
    return this.http.get<Student>(this.casaUrl(`/schools/${schoolId}/teachers/students/${studentId}`));
  }
}
