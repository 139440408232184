import {Injectable} from '@angular/core';
import {
  ApiCompetence,
  ApiCountry,
  ApiCourse,
  ApiLearningUnitStudent,
  ApiLearningUnitTeacher,
  ApiLessonBundle,
  ApiLessonCommit,
  ApiLessonFlag,
  ApiLessonInstance,
  ApiLessonMessage,
  ApiLessonProgress,
  ApiPerson,
  ApiPersonalProfile,
  ApiPersonTechnicalProfile,
  ApiProductContext,
  ApiProductGift, ApiProvaContext,
  ApiStudentProductContext,
  ApiStudentSchedule,
  ApiTeacherProfile,
  ApiTeacherWorktime,
  ApiWorktimeDefinition,
  EntityRelatedRow,
  LessonBundleFilter,
  StudentCommitsFilter
} from 'src/app/col/model/rest-model';
import {ColNativeServiceApi} from "../col-native-api";
import {ItemAvailability} from "../../../../cspa/model/cspa/personal";
import {Page, Pageable} from "../../../../utils/pageable";
import {Chapter, ExerciseSet} from "../../../../cspa/model/cspa/struct";
import {ApiProduct} from "../../../model/products";
import {iif, map, Observable, of, switchMap, tap, throwError} from "rxjs";
import {LessonType} from "../../../model/calendar";
import {IosBridgeBase} from "../../../../mobile/api-services/bridge/ios-bridge-base";
import {LogsService} from "../../../../utils/services/logs.service";
import {CachedHttpRequest, Constraints, HttpMethod, HttpRequest, HttpResponse, Platform} from "../../../../constraints";
import {Dates} from "../../../../utils/calendar-utils";
import {environment} from "../../../../../environments/environment";
import {HttpParams} from "@angular/common/http";
import {
  ApiLessonEventModel,
  LessonScheduleEventReference,
  ProductAvailabilityRequest,
  ScheduleReference,
  SimpleLessonScheduleRequest,
  SimpleProductAvailability,
  SimpleTeacherProductAvailability,
  StudentColLessonScheduleEvents,
  TeacherColLessonScheduleEvents
} from "../../../model/rest-model-v2";
import {Utils} from "../../../utils/lesson-utils";

@Injectable({
  providedIn: 'root'
})
export class ColIosService implements ColNativeServiceApi{

  constructor(private bridge: IosBridgeBase,
              private logger: LogsService) {}

  private handleResponse<T>(response: Observable<HttpResponse>): Observable<T> {
    return response.pipe(
      switchMap(response => iif(
        () => response.responseCode == 200,
        of(response.body),
        throwError(() => Error(`Error with code ${response.responseCode}`))
      )),
      map( body => JSON.parse(body))
    )
  }
  private getUrlLessonStudent(path: string, studentId?: number) {
    return `/student` +
      (studentId ? `/${studentId}` : '') +
      path
  }
  private getUrlLessonTeacher(path: string, teacherId?: number) {
    return `/teacher` +
      (teacherId ? `/${teacherId}` : '') +
      path
  }

  private getUrlCspa(path: string) {
    return `/api${path}`;
  }
  private getUrlBookingStudent(studentId: number, path: string) {
    return `/student/${studentId}${path}`;
  }

  private getUrlBookingV2Student(studentId: number, path: string) {
    return `/v2/student/${studentId}${path}`;
  }

  private getUrlBookingTeacher(teacherId: number, path: string) {
    return `/v2/teacher/${teacherId}${path}`;
  }

  private getUrlBookingV2Teacher(teacherId: number, path: string) {
    return `/teacher/${teacherId}${path}`;
  }

  private appendParams(url: string, key: string, value: string, isFirst: boolean): string {
    if (isFirst) {
      url = url + "?"
    } else {
      url = url + "&"
    }
    if (!value) value = "";
    url = `${url}${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    return url;
  }

  private params(url: string, params: HttpParams) {
    if (!params || params.keys().length == 0) return url;
    let isFirst = true;
    const keys = params.keys();
    for (const key of keys) {
      const values = params.get(key);
      if (!values) continue;
      url = this.appendParams(url, key, values, isFirst);
      isFirst = false;
    }

    return url;
  }

  cancelLesson(studentId: number, lessonId: number, reason: string): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`cancelling lesson with lessonId=${lessonId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.POST,
      this.getUrlLessonStudent(`/lessons/${lessonId}/cancel`, studentId),
      null,
      reason
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  cancelLessonByTeacher(teacherId: number, lessonId: number, reason: string): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`cancelling lesson by teacher with lessonId=${lessonId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.PATCH,
      this.getUrlLessonTeacher(`/lessons/${lessonId}/status/cancel`, teacherId),
      null,
      reason
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  commitLessonBooking(teacherId: number, lessonId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`committing lesson booking with lessonId=${lessonId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.PATCH,
      this.getUrlLessonTeacher(`/lessons/${lessonId}/status/due`, teacherId),
      null,
      null
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  createSkypeClassroom(teacherId: number, lessonId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`creating skype classroom with lessonId=${lessonId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.PUT,
      this.getUrlLessonTeacher(`/lessons/${lessonId}/skype`, teacherId),
      null,
      null
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  createVideoClassroom(teacherId: number, lessonId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`creating video classroom with lessonId=${lessonId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.PUT,
      this.getUrlLessonTeacher(`/lessons/${lessonId}/classroom`, teacherId),
      null,
      null
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  createWorktime(teacherId: number, definition: ApiWorktimeDefinition): Observable<ApiWorktimeDefinition> {
    this.logger.log(`creating worktime for teacherId=${teacherId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.PUT,
      this.getUrlLessonTeacher(`/worktimes`, teacherId),
      null,
      JSON.stringify(definition),
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  deleteWorktime(teacherId: number, worktimeId: number, applyRecurring: boolean): Observable<void> {
    this.logger.log(`deleting worktime with worktimeId=${worktimeId} for teacherId=${teacherId}`)
    let params = new HttpParams().append("applyRecuring", applyRecurring.toString())

    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.DELETE,
      this.params(this.getUrlLessonTeacher(`/worktimes/${worktimeId}`, teacherId), params),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  estimateNextProgress(teacherId: number, studentId: number, baseProgress: ApiLessonProgress, limit?: number): Observable<ApiLessonProgress[]> {
    this.logger.log(`estimating next progress for studentId=${studentId}`)
    let params = new HttpParams();
    if (limit !== undefined) {
      params = params.append('limit', limit.toString());
    }
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.POST,
      this.params(this.getUrlLessonTeacher(`/students/${studentId}/progress/estimate`, teacherId), params),
      null,
      JSON.stringify(baseProgress),
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  findStudentProductGift(teacherId: number, studentId: number, courseCode: string): Observable<ApiProductGift> {
    this.logger.log(`finding student product gift for studentId=${studentId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.getUrlLessonTeacher(`/students/${studentId}/gifts/${courseCode}`, teacherId),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  findUpcomingNextLesson(studentId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`finding upcoming next lesson for studentId=${studentId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.getUrlLessonStudent('/lessons/upcoming/next', studentId),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  findUpcomingNextLessonsMultiple(studentId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[]> {
    this.logger.log(`finding upcoming next lesson (multiple) for studentId=${studentId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.getUrlLessonTeacher('/lessons/upcoming/next-multiple', studentId),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  finishLesson(teacherId: number, lessonId: number, progress: ApiLessonProgress, finishDate: Date): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`finishing lesson with lessonId=${lessonId}`)
    let params = new HttpParams();
    if (finishDate) {
      params = params.append('finishDate', finishDate.toISOString());
    }
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.PATCH,
      this.params(this.getUrlLessonTeacher(`/lessons/${lessonId}/status/finish`, teacherId), params)
      ,null,
      JSON.stringify(progress),
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getAvailabilities(path: string, depth: number): Observable<ItemAvailability[]> {
    this.logger.log(`getting availabilities for path=${path}`)
    const params = new HttpParams().append('path', path).append('depth', depth.toString());
    const request = new HttpRequest(
      Platform.Cspa, HttpMethod.GET,
      this.params(this.getUrlCspa('/availability'), params),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getChapters(path: string, updatedAfter?: number): Observable<Chapter[]> {
    this.logger.log(`getting chapters for path=${path}`)
    let params = new HttpParams()
    if (updatedAfter != null) {
      params = params.set('updatedAfter', updatedAfter.toString());
    }
    const request = new HttpRequest(
      Platform.Cspa, HttpMethod.GET,
      this.params(this.getUrlCspa(`/sets/${path}`), params),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getCommits(studentId: number, filter: StudentCommitsFilter, pageable: Pageable): Observable<Page<ApiLessonCommit>> {
    this.logger.log(`getting commits for studentId=${studentId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.params(this.getUrlLessonStudent('/lessons/commits', studentId), Pageable.appendPageableParams(new HttpParams(), pageable)),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getCourseAllowedCompetences(courseCode: string): Observable<ApiCompetence[]> {
    this.logger.log(`getting course allowed competences for courseCode=${courseCode}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.getUrlLessonStudent(`/courses/${encodeURIComponent(courseCode)}/competences`),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getExerciseSets(): Observable<ExerciseSet[]> {
    this.logger.log(`getting exercise sets`)
    const request = new HttpRequest(
      Platform.Cspa, HttpMethod.GET,
      this.getUrlCspa('/sets'),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getLesson(studentId: number, lessonId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`getting lesson with lessonId=${lessonId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.getUrlLessonStudent(`/lessons/${lessonId}`, studentId),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getLessonById(teacherId: number, lessonId: number, studentId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`getting lesson with lessonId=${lessonId} for studentId=${studentId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.getUrlLessonTeacher(`/lessons/${lessonId}/students/${studentId}`, teacherId),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getLessons(studentId: number, pageable: Pageable, lessonType: LessonType, isTeacher: boolean, lang?: string): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    this.logger.log(`getting lessons for studentId=${studentId}`)
    let url = isTeacher
      ? this.getUrlLessonTeacher('/lessons', studentId)
      : this.getUrlLessonStudent('/lessons', studentId)
    switch (lessonType) {
      case LessonType.Upcoming: {
        url += '/upcoming';
        break;
      }
      case LessonType.Incomplete: {
        url += '/incomplete';
        break;
      }
      case LessonType.Past: {
        url += '/past';
        break;
      }
    }
    let params = Pageable.appendPageableParams(new HttpParams(), pageable)
    if (lang) params = params.append('productCode', lang);

    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.params(url, params),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getProductContext(studentId: number, productCode: string): Observable<ApiProductContext> {
    this.logger.log(`getting product context for studentId=${studentId}`)
    const request = new CachedHttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.getUrlLessonStudent(`/progress/${productCode}/context`, studentId),
      null,
      null,
      `student-${studentId}-progress-${productCode}-context`,
      Constraints.syncMinute,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getProductCourses(productCode: string): Observable<ApiCourse[]> {
    this.logger.log(`getting product courses for productCode=${productCode}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.getUrlLessonTeacher(`/product/${productCode}/courses`),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getProductProgressEstimation(studentId: number, productCode: string): Observable<ApiLessonProgress[]> {
    this.logger.log(`getting product progress estimation for productCode=${productCode}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.getUrlLessonStudent(`/progress/${productCode}/promote-estimate`, studentId),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getProductsList(lang: string, currency: string): Observable<ApiProduct[]> {
    this.logger.log(`getting products list for lang=${lang}`)

    let params = new HttpParams().append('lang', lang).append('currency', currency)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.params(this.getUrlLessonStudent('/products'), params),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getProfile(teacherId: number): Observable<ApiTeacherProfile> {
    this.logger.log(`getting profile for teacherId=${teacherId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.getUrlLessonTeacher('/profile', teacherId),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getStudentLessonBundles(studentId: number, filter: LessonBundleFilter, pageable: Pageable): Observable<Page<ApiLessonBundle>> {
    this.logger.log(`getting lesson bundle for studentId=${studentId}`)
    let params = Pageable.appendPageableParams(new HttpParams(), pageable)
    if (filter.productCode != null) {
      params = params.append('productCode', filter.productCode);
    }
    if (filter.lessonsAvailable != null) {
      params = params.append('lessonsAvailable', String(filter.lessonsAvailable));
    }
    const request = new CachedHttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.params(this.getUrlLessonStudent('/bundles', studentId),params),
      null,
      null,
      `student-${studentId}-bundles`,
      Constraints.syncMinute
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}))
      .pipe(
        tap((bundlesPage: Page<ApiLessonBundle>) =>
          bundlesPage.content.forEach((bundle) => {
            bundle.date = Dates.parse(String(bundle.date));
            bundle.expiryDate = Dates.parse(String(bundle.expiryDate));
          })
        )
      );
  }

  getStudentProductContext(teacherId: number, studentId: number, productCode: string): Observable<ApiStudentProductContext> {
    this.logger.log(`getting product context for studentId=${studentId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.getUrlLessonTeacher(`/students/${studentId}/product-context/${productCode}`, teacherId),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getStudentProgress(studentId: number, teacherId: number, isTeacher: boolean): Observable<ApiLessonProgress[]> {
    this.logger.log(`getting progress for studentId=${studentId}`)
    const url = isTeacher
      ? this.getUrlLessonTeacher(`/students/${studentId}/progress`, teacherId)
      : this.getUrlLessonStudent('/progress', studentId);
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      url,
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getStudentTechnicalProfileByTeacher(teacherId: number, studentId: number): Observable<ApiPersonTechnicalProfile> {
    this.logger.log(`getting technical profile for studentId=${studentId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.getUrlLessonTeacher(`/students/${studentId}/person/technical-profile`, teacherId),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getStudentTechnicalProfile(
    studentId: number
  ): Observable<ApiPersonTechnicalProfile> {
    this.logger.log(`getting technical profile for studentId=${studentId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.getUrlLessonStudent(`/${studentId}/technical-profile`),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  public updateIntroductionState(studentId: number, state: string): Observable<ApiPersonTechnicalProfile> {
    this.logger.log(`updating introductionState for studentId=${studentId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.PUT,
      this.getUrlLessonStudent(`/${studentId}/technical-profile/introduction-state`),
      null,
      state,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange', {request}))
  }

  listStudentWeekSchedules(studentId: number, focusDate: Date): Observable<ApiStudentSchedule[]> {
    this.logger.log(`getting week schedules for studentId=${studentId}`)
    let params = new HttpParams().append("focusDate", focusDate.toISOString())
    const request = new HttpRequest(
      Platform.Booking, HttpMethod.GET,
      this.params(this.getUrlBookingStudent(studentId, '/schedules'), params),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getTeachers(studentId: number, teacherIds?: number[]): Observable<ApiTeacherProfile[]> {
    this.logger.log(`getting teachers`)
    let params = new HttpParams()

    if (teacherIds?.length > 0) {
      teacherIds.forEach(
        (id) => (params = params.append('id', id.toString()))
      )
    } else {
      params = params
        .append(Constraints.cacheNameHeader, `student-${studentId}-teachers`)
        .append(Constraints.syncTimeHeader, (Constraints.syncMinute).toString())
    }
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.params(this.getUrlLessonStudent('/teachers', studentId), params),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getLessonScheduleEventReference(
    studentId: number,
    scheduleId: number
  ): Observable<LessonScheduleEventReference<ApiLessonEventModel>> {

    const request = new HttpRequest(
      Platform.Booking, HttpMethod.GET,
      this.getUrlBookingV2Student(studentId, `/schedule/${scheduleId}/event`),
      null, null
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  listTeachersWeekAvailability(studentId: number, focusDate: Date, productCode: string): Observable<ApiTeacherWorktime[]> {
    this.logger.log(`getting week availability for studentId=${studentId}`)
    let params = new HttpParams().append("focusDate", focusDate.toISOString())
    const request = new HttpRequest(
      Platform.Booking, HttpMethod.GET,
      this.params(this.getUrlBookingStudent(studentId, `/availability/product/${productCode}`), params),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  listStudentSchoolTeachersAvailability(
    studentId: number,
    focusDate: Date,
    productCode: string
  ): Observable<SimpleTeacherProductAvailability[]> {
    let weekStart = Utils.getWeekStartDate(focusDate)
    const url = this.getUrlBookingV2Student(studentId, '/teacher-availability');
    const params = new HttpParams()
      .append("dateFrom", weekStart.toISOString())
      .append("dateTo", new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString())
      .append("productCode", productCode)
    const request = new HttpRequest(
      Platform.Booking, HttpMethod.GET,
      this.params(url, params),
      null, null
    )
    return this.handleResponse<SimpleTeacherProductAvailability[]>(this.bridge.callForResponse<HttpResponse>('exchange',{request}))
      .pipe(
      tap( schedules => {
        schedules.forEach( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate))))
      }),
      map(worktimes => worktimes.map(worktime => Object.assign(new SimpleTeacherProductAvailability(), worktime)))
    );
  }

  listStudentLessonSchedules(
    studentId: number,
    focusDate: Date,
    productCode: string
  ): Observable<StudentColLessonScheduleEvents[]> {
    let weekStart = Utils.getWeekStartDate(focusDate)
    const url = this.getUrlBookingV2Student(studentId , '/schedules');
    const params = new HttpParams()
      .append("dateFrom", weekStart.toISOString())
      .append("dateTo", new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString())
      .append("productCode", productCode)
      .append("type", "lesson")
    const request = new HttpRequest(
      Platform.Booking, HttpMethod.GET,
      this.params(url, params),
      null, null
    )
    return this.handleResponse<StudentColLessonScheduleEvents[]>(this.bridge.callForResponse<HttpResponse>('exchange',{request}))
    .pipe(
      tap( schedules => {
        schedules.forEach( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate))))
      })
    );
  }

  giveStudentProductGift(teacherId: number, studentId: number, courseCode: string): Observable<void> {
    this.logger.log(`giving student product gift for studentId=${studentId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.POST,
      this.getUrlLessonTeacher(`/students/${studentId}/gifts/${courseCode}`, teacherId),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  listLessonHistory(teacherId: number, lessonId: number, studentId: number, pageable: Pageable): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    this.logger.log(`listing lesson history for lessonId=${lessonId}`)

    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.params(this.getUrlLessonTeacher(`/lessons/${lessonId}/students/${studentId}/history`, teacherId), Pageable.appendPageableParams(new HttpParams(), pageable)),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  public listAvailabilities(
    teacherId: number,
    focusDate: Date
  ): Observable<SimpleProductAvailability[]> {
    let weekStart = Utils.getWeekStartDate(focusDate)
    const url = this.getUrlBookingV2Teacher(teacherId , '/availabilities/events');
    const params = new HttpParams()
      .append("dateFrom", weekStart.toISOString())
      .append("dateTo", new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString());
    const request = new HttpRequest(
      Platform.Booking, HttpMethod.GET,
      this.params(url, params),
      null, null
    )
    return this.handleResponse<SimpleProductAvailability[]>(this.bridge.callForResponse<HttpResponse>('exchange',{request}))
    .pipe(
      tap(schedules => {
        schedules.forEach( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate))))
      }),
      map(worktimes => worktimes.map( wt => Object.assign(new SimpleProductAvailability(), wt)))
    );
  }

  public listLessons(teacherId: number, focusDate: Date): Observable<TeacherColLessonScheduleEvents[]> {
    let weekStart = Utils.getWeekStartDate(focusDate)
    const url = this.getUrlBookingV2Teacher(  teacherId , '/lessons');
    const params = new HttpParams()
      .append("dateFrom", weekStart.toISOString())
      .append("dateTo", new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString());

    const request = new HttpRequest(
      Platform.Booking, HttpMethod.GET,
      this.params(url, params),
      null, null
    )
    return this.handleResponse<TeacherColLessonScheduleEvents[]>(this.bridge.callForResponse<HttpResponse>('exchange',{request}))
    .pipe(
      tap( schedules => {
        schedules.forEach( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate))))
      })
    );
  }

  public deleteAvailability(
    teacherId: number,
    availabilityId: number,
    eventId: number,
    applyRecurring: boolean
  ): Observable<void> {
    const url =
      this.getUrlBookingV2Teacher(
      teacherId ,
      `/availabilities/${availabilityId}/events/${eventId}`)
    const params = new HttpParams()
      .append('applyFuture', applyRecurring.toString())
    const request = new HttpRequest(
      Platform.Booking, HttpMethod.DELETE,
      this.params(url,params),
      null, null
    )

    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}))
  }

  public createAvailability(
    teacherId: number,
    request: ProductAvailabilityRequest
  ): Observable<ScheduleReference> {
    const url =
      this.getUrlBookingV2Teacher(teacherId , '/availabilities');
    const r = new HttpRequest(
      Platform.Booking, HttpMethod.POST,
      url, null, JSON.stringify(request)
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{r}))
  }

  listLessonTypesReportForTeacher(teacherId: number, dateFrom: Date, dateTo: Date, productCode: string): Observable<EntityRelatedRow<ApiTeacherProfile>[]> {
    this.logger.log(`listing lesson types report for teacherId=${teacherId}`)
    const url =
      '/reports/teacher/' +
      teacherId +
      '/lesson-types';

    let params = new HttpParams();
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom.toISOString());
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo.toISOString());
    }
    if (productCode) {
      params = params.append('productCode', productCode);
    }
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.params(url, params),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  listWeekSchedules(teacherId: number, focusDate: Date): Observable<ApiStudentSchedule[]> {
    this.logger.log(`listing week schedules for teacherId=${teacherId}`)
    const request = new HttpRequest(
      Platform.Booking, HttpMethod.GET,
      this.params(this.getUrlBookingTeacher(teacherId, '/schedules'), new HttpParams().append("focusDate", focusDate.toISOString())),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  listWorktimeWeekSchedules(teacherId: number, focusDate: Date): Observable<ApiTeacherWorktime[]> {
    this.logger.log(`listing worktime week schedules for teacherId=${teacherId}`)
    const request = new HttpRequest(
      Platform.Booking, HttpMethod.GET,
      this.params(this.getUrlBookingTeacher(teacherId, '/worktimes'), new HttpParams().append("focusDate", focusDate.toISOString())),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  notifyStudentAboutLessonStart(teacherId: number, lessonId: number): Observable<void> {
    this.logger.log(`notifying student about lesson start for lessonId=${lessonId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.POST,
      this.getUrlLessonTeacher(`/lessons/${lessonId}/notifications/lesson-start`, teacherId),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  postLessonComment(teacherId: number, lessonId: number, message: ApiLessonMessage): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`posting lesson comment for lessonId=${lessonId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.POST,
      this.getUrlLessonTeacher(`/lessons/${lessonId}/messages`, teacherId),
      null,
      JSON.stringify(message),
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  postLessonMessageForStudent(teacherId: number, lessonId: number, studentId: number, message: string): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`posting lesson message for lessonId=${lessonId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.POST,
      this.getUrlLessonTeacher(`/lessons/${lessonId}/students/${studentId}/messages`, teacherId),
      null,
      JSON.stringify(message),
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  queryForStudentsById(teacherId: number, studentIds: number[]): Observable<Page<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>>> {
    this.logger.log(`querying for students`)

    if (!studentIds || studentIds.length === 0) {
      return of(Page.empty<null>());
    }

    let params = new HttpParams();
    studentIds.forEach((id) => (params = params.append('id', id.toString())));

    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.params(this.getUrlLessonTeacher('/students', teacherId), params),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  registerLessonFlag(teacherId: number, lessonId: number, lessonFlag: ApiLessonFlag): Observable<ApiLessonFlag> {
    this.logger.log(`querying for students`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.POST,
      this.getUrlLessonTeacher(`/lessons/${lessonId}/flags`, teacherId),
      null,
      JSON.stringify(lessonFlag),
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  reserveSchedule(studentId: number, schedule: ApiStudentSchedule): Observable<ApiStudentSchedule> {
    this.logger.log(`reserving schedule for studentId=${studentId}`)
    const request = new HttpRequest(
      Platform.Booking, HttpMethod.PUT,
      this.getUrlBookingStudent(studentId, '/schedules'),
      null,
      JSON.stringify(schedule)
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  saveStudentProductContext(teacherId: number, studentId: number, productCode: string, context: ApiProductContext): Observable<ApiProductContext> {
    this.logger.log(`saving student product context studentId=${studentId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.PUT,
      this.getUrlLessonTeacher(`/students/${studentId}/product-context/${productCode}`, teacherId),
      null,
      JSON.stringify(context),
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  squanderLesson(studentId: number, lessonId: number, reason: string): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`squandering lesson with lessonId=${lessonId} for studentId=${studentId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.POST,
      this.getUrlLessonStudent(`/lessons/${lessonId}/squander`, studentId),
      null,
      JSON.stringify(reason),
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  squanderLessonByTeacher(teacherId: number, lessonId: number, reason: string): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`squandering lesson with lessonId=${lessonId} by teacherId=${teacherId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.PATCH,
      this.getUrlLessonTeacher(`/lessons/${lessonId}/status/squander`, teacherId),
      null,
      JSON.stringify(reason),
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  startLesson(teacherId: number, lessonId: number, startDate: Date): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`starting lesson with lessonId=${lessonId}`)
    let params = new HttpParams();
    if (startDate) {
      params = params.append('startDate', startDate.toISOString());
    }

    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.PATCH,
      this.params(this.getUrlLessonTeacher(`/lessons/${lessonId}/status/start`, teacherId), params),
      null,
      null
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  updateLessonProgress(teacherId: number, lessonId: number, progress: ApiLessonProgress): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`updating lesson progress with lessonId=${lessonId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.PUT,
      this.getUrlLessonTeacher(`/lessons/${lessonId}/progress`, teacherId),
      null,
      JSON.stringify(progress),
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  updateLessonType(teacherId: number, lessonId: number, lessonType: string): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    this.logger.log(`updating lesson type with lessonId=${lessonId}`)
    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.PUT,
      this.getUrlLessonTeacher(`/lessons/${lessonId}/type`, teacherId),
      null,
      JSON.stringify(lessonType),
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getCountries(locale?: string): Observable<ApiCountry[]> {
    this.logger.log(`listing countries`)
    let params = new HttpParams()
    if (locale) {
      params = params.append("locale", locale)
    }

    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.params(environment.lessonEndpoint + '/public/countries', params),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  updateProductVersion(teacherId: number, studentId: number, productCode: string, version: string): Observable<ApiStudentProductContext> {
    this.logger.log(`updating product version to ${version}`)

    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.PATCH,
      `${environment.lessonEndpoint}/teacher/${teacherId}/students/${studentId}/product-context/${productCode}/product-version`,
      null,
      version
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  getProvaContext(studentId: number, productCode?: string): Observable<ApiProvaContext[]> {
    this.logger.log(`getting prova context for studentId=${studentId}`)

    let params = new HttpParams();
    if (productCode) {
      params = params.append('productCode', productCode);
    }

    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.GET,
      this.params(this.getUrlLessonStudent(`/prova-context`, studentId), params),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  addStudentProductContext(studentId: number, productCode: string): Observable<ApiProductContext> {
    this.logger.log(`adding product context (${productCode}) for studentId=${studentId}`)

    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.POST,
        this.getUrlLessonStudent('/progress/' + productCode + '/context', studentId),
      null,
      null,
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  chargeFreeCredit(studentId: number, productCode: string): Observable<ApiLessonBundle> {
    this.logger.log(`adding free credit (${productCode}) for studentId=${studentId}`)

    const request = new HttpRequest(
      Platform.Lessons, HttpMethod.POST,
      this.getUrlLessonStudent('/bundle/' + productCode + '/free', studentId),
      null,
      null
    )
    return this.handleResponse(this.bridge.callForResponse<HttpResponse>('exchange',{request}));
  }

  reserveScheduleV2(studentId: number, request: SimpleLessonScheduleRequest): Observable<StudentColLessonScheduleEvents> {
    this.logger.log(`reserving schedule for studentId=${studentId}`)
    const r = new HttpRequest(
      Platform.Booking, HttpMethod.PUT,
      this.params(this.getUrlBookingV2Student(studentId, '/schedules'), new HttpParams().append("type","col_lesson")),
      null,
      JSON.stringify(request)
    )
    return this.handleResponse<StudentColLessonScheduleEvents>(this.bridge.callForResponse<HttpResponse>('exchange',{r}))
      .pipe(
        tap( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate)))
        )
      );
  }
}
