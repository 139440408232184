import {Component, Input, OnInit} from '@angular/core';
import {ExerciseSessionQuestion} from "../../../../cspa/model/cspa/personal";
import {Images, ImagesAnswer} from "../../../../cspa/model/cspa/questions";
import {environment} from "../../../../../environments/environment";

class AnswerMap {
  img: string
  studentAnswer: string
  correctAnswer: string
  constructor(img: string, studentAnswer: string, correctAnswer: string) {
    this.img = img;
    this.studentAnswer = studentAnswer;
    this.correctAnswer = correctAnswer;
  }
}

@Component({
  selector: 'app-pictures-result',
  templateUrl: './pictures-result.component.html',
  styleUrls: ['./pictures-result.component.scss']
})
export class PicturesResultComponent implements OnInit {
  @Input() questions: ExerciseSessionQuestion<ImagesAnswer, Images> []

  questionAnswerMap: AnswerMap[] = []
  constructor() { }

  ngOnInit(): void {
    this.questions.forEach(q => {
      q.question.definition.answer.answers.forEach(a => {
        let img = a.val[0]
        let correctAnswer = a.name
        let studentAnswer = q.answer?.answers?.find(an => an.val[0] == img)?.name || "-"
        this.questionAnswerMap.push(new AnswerMap(img, studentAnswer, correctAnswer))
      })
    })
  }

  isAnswerIncorrect(q: AnswerMap) {
    return q.studentAnswer == q.correctAnswer
  }

  getImgSource(img: string) {
    return environment.awsBase + img
  }
}
