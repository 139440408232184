<ng-container *ngIf="setAvailabilities?.length == 0">
  <p class="text-center fs-5 text-muted m-0">
    {{ 'LESSONS.NO_RESULTS' | translate }}
  </p>
</ng-container>
<div class="card mb-5" *ngFor="let setAvailability of setAvailabilities">
  <div class="card-header border-bottom border-secondary pb-4">
    <h5 class="mb-0">
      {{ getSetName(setAvailability) }}
    </h5>
  </div>
  <div class="card-body">
    <div class="row pt-3 pb-4 border-bottom border-secondary">
      <h6 class="text-muted">
        {{ 'LESSONS.STUDENTS.CSPA_MONITOR.STUDENT_EXERCISE_PROGRESS' | translate }}
      </h6>
      <p class="small mb-0">
        {{ 'LESSONS.STUDENTS.CSPA_MONITOR.LAST_EXERCISE' | translate }}
        <span class="small text-muted">
          {{ getItemLastSubmit(setAvailability) | date: "dd MMM yy HH:mm" }}.
        </span>
      </p>
      <p *ngIf="countSetSessions(setAvailability) > 0" class="small mb-0">
        {{ 'LESSONS.STUDENTS.CSPA_MONITOR.COMPLETED_SESSIONS_1' | translate }}
        <span class="small text-muted">
            {{ countSetSessions(setAvailability) }} {{ 'LESSONS.STUDENTS.CSPA_MONITOR.COMPLETED_SESSIONS_2' | translate }}
          </span>
      </p>
    </div>
    <div class="row py-4" *ngFor="let stage of listAvailableStages(setAvailability.path)">
      <div class="col-12 col-md-4 col-lg-2 pb-2">
        <h6>{{ getItemShortName(stage) }}</h6>
        <p class="small">{{ 'LESSONS.STUDENTS.CSPA_MONITOR.LAST_COMPLETED' | translate }} <span
          class="small text-muted">{{ getItemLastSubmit(getItemByPath(stage.path)) | date: "dd MMM yy HH:mm" }}</span>
        </p>
      </div>
      <div class="col">
        <div class="row mb-2" *ngFor="let section of stage.sections">
          <div class="col-12 col-sm-3 col-md-4 col-lg-3 pb-2">
            <a>{{ getItemShortName(section) }}:</a>
            <p *ngIf="isExam(section.path)" class="text-muted xsmall lh-sm mb-0">
              {{ 'LESSONS.STUDENTS.CSPA_MONITOR.SELECT_EXAM' | translate }}
            </p>
          </div>
          <div class="col text-center text-md-left text-light">
            <div class="d-inline-block rounded text-center mb-1 me-1 px-1 py-0 small exercise-box"
                 *ngFor="let exercise of section.exercises"
                 [ngClass]="getStatusClass(buildPath(stage, section, exercise))"
                 [class]="isExam(section.path) ? 'cursor-pointer' : 'pe-none'"
                 [routerLink]="isExam(section.path) ? '../exam-results' : null">
              {{ getItemShortName(exercise) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="countSetSessions(setAvailability) > 0">
      <p class="text-muted">
        {{ 'LESSONS.STUDENTS.CSPA_MONITOR.RECENT_SESSIONS' | translate }}
      </p>
      <div class="table-responsive">
        <table class="table table-sm table-borderless">
          <thead>
          <tr class="table-row table-row-head">
            <th>{{ getChapterHeader(setAvailability) }}</th>
            <th>{{ 'LESSONS.STUDENTS.CSPA_MONITOR.SECTION' | translate }}</th>
            <th>{{ getExerciseNameHeader(setAvailability) }}</th>
            <th>{{ 'LESSONS.STUDENTS.CSPA_MONITOR.FINISHED' | translate }}</th>
          </tr>
          </thead>
          <tbody class="table-body">
          <tr *ngFor="let session of getSetSessions(setAvailability)" class="table-row">
            <td>
              {{ getChapter(session) }}
            </td>
            <td>
              {{ getSection(session) }}
            </td>
            <td>
              {{ getExerciseName(session) }}
            </td>
            <td class="text-muted">
              {{ getSessionDate(session) | date: "dd MMM yy HH:mm" }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
