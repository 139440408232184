<div class="bg-workspace min-vh-100">
  <div class="workspace bg-radial">
    <top-bar [titleTranslationCode]="'NAVIGATION.STUDENTS'"
             [redirectLink]="'../'"
             [defaultRedirectTranslationCodes]="true"
             class="accent"
    >
      <footer>
        <div class="row d-none d-md-flex">
          <div class="col-10">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">
                  {{ "NAVIGATION.STUDENTS" | translate }}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </footer>
    </top-bar>
    <div class="container mt-md-3">
      <div class="row position-relative mb-3">
        <spinner class="w-100 spinner"></spinner>
      </div>
      <div class="row mb-4">
        <div class="col-auto mb-3 mb-sm-0">
          <h4 class="mb-0" *ngIf="currentState?.sortingRule">{{ currentState.sortingRule.totalLabel }} <span
            class="text-primary">( {{ countTotalStudents() }} )</span>
          </h4>
        </div>
      </div>
      <div class="card mb-5">
        <form class="d-flex card-header flex-column flex-md-row border-0" (ngSubmit)="doSearch()">
          <div class="col-12 col-md-6">
            <div class="position-relative">
              <input name="search-term" type="text" class="form-control p-3 mb-3 mb-md-0" [(ngModel)]="searchTerm"
                     (ngModelChange)="searchUpdated()" placeholder="Search">
              <button type="submit" class="btn position-absolute end-0 top-0 bottom-0">
                <app-icon name="search" class="icon-blue"></app-icon>
              </button>
            </div>
          </div>
          <div class="col-12 col-md-5 col-lg-4 ms-auto" *ngIf="currentState">
            <select name="sorting-order" [(ngModel)]="currentState.sortingRule" (ngModelChange)="doSearch()"
                    class="form-select p-3"
                    [ngClass]="{ 'select-not-empty': currentState.sortingRule }">
              <option *ngFor="let rule of studentSortingRules" [ngValue]="rule">{{ rule.name }}</option>
            </select>
          </div>

        </form>
        <div class="card-body">
          <div class="table-responsive" *ngIf="hasStudents()">
            <table class="table" style=" line-height: 1.3;">
              <tbody class="table-body">
              <tr *ngFor="let student of getStudentsPage()" class="table-row table-row-hover-pointer">
                <td class="align-middle d-flex" (click)="openStudent(student)">
                  <div class="d-flex rounded-circle text-center bg-secondary me-2 initials-circle">
                    <span class="text-white m-auto small">{{ getInitials(student) }}</span>
                  </div>
                  <div class="my-auto" style="max-width: 13rem;">
                    <p class="text-muted small mb-0">
                      {{ 'LESSONS.STUDENTS.STUDENT' | translate }}
                    </p>
                    <div class="d-flex flex-column">
                      <span>{{ getName(student) }}</span>
                      <span class="text-muted small">{{ getEmail(student) }}</span>
                    </div>
                  </div>
                </td>
                <td class="align-middle td-date" (click)="openStudent(student)">
                  <p class="text-muted small mb-0">
                    {{ 'LESSONS.STUDENTS.STATUS' | translate }}
                  </p>
                  <span
                    class="text-uppercase badge xsmall rounded-2 bg-warning w-fit-content">{{ getStatus(student) }}</span>
                </td>
                <td class="align-middle td-date" (click)="openStudent(student)">
                  <p class="text-muted small mb-0">
                    {{ 'LESSONS.STUDENTS.EXPIRY' | translate }}
                  </p>
                  <span class="text-nowrap">{{ getExpiry(student) | date: "dd MMM yyyy" }}</span>
                </td>
                <td class="align-middle d-none d-md-table-cell td-date" (click)="openStudent(student)">
                  <p class="text-muted small mb-0">
                    {{ 'LESSONS.STUDENTS.CREATED' | translate }}
                  </p>
                  <span class="text-nowrap">{{ getCreated(student) | date: "dd MMM yyyy" }}</span>
                </td>
                <td class="align-middle text-end">
                  <button class="btn rounded-pill btn-outline-secondary" (click)="openStudent(student)">
                    <app-icon name="angle-right" class="icon-blue lg-icon"></app-icon>
                  </button>
                </td>
              </tr>
              <tr>
                <td colspan="4" class="text-center" *ngIf="getStudentsPage().length === 0">
                  {{ 'LESSONS.NO_RESULTS' | translate }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer d-flex px-3">
          <div class="btn-group btn-group-sm ms-auto" *ngIf="hasPages()">
            <a class="btn btn-outline-secondary rounded-pill rounded-end" [ngClass]="{disabled: !hasPrev()}"
               (click)="prev()">
              <app-icon name="angle-left" class="icon-blue"></app-icon>
            </a>
            <a class="btn btn-outline-secondary px-3">{{ getPageNumber() }}</a>
            <a class="btn btn-outline-secondary rounded-pill rounded-start" [ngClass]="{disabled: !hasNext()}"
               (click)="next()">
              <app-icon name="angle-right" class="icon-blue"></app-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
