import {HttpParams} from '@angular/common/http';

export abstract class FilterBase {
  private params: HttpParams;

  public apply(params: HttpParams): HttpParams {
    this.params = params;
    this.doApply();
    return this.params;
  }

  protected append(name: string, value: string) {
    this.params = this.params.append(name, value);
  }

  protected appendArray(name: string, value: string[]) {
    value.forEach((v) => {
      this.params = this.params.append(name, v);
    });
  }

  protected abstract doApply(): void;
}

export class LessonBundleFilter extends FilterBase {
  productCode: string;
  lessonsAvailable: boolean;

  constructor(productCode: string, available: boolean) {
    super();
    this.productCode = productCode;
    this.lessonsAvailable = available;
  }

  protected doApply(): void {
    if (this.productCode) {
      this.append('productCode', this.productCode);
    }
    if (this.lessonsAvailable != null) {
      this.append('lessonsAvailable', String(this.lessonsAvailable));
    }
  }
}

export class StudentCommitsFilter extends FilterBase {
  productCode: string;
  courseCode: string;
  commitStatus: string[];
  lessonStatus: string[];

  public constructor(
    productCode: string,
    courseCode: string,
    commitStatus: string[],
    lessonStatus: string[]
  ) {
    super();
    this.productCode = productCode;
    this.courseCode = courseCode;
    this.commitStatus = commitStatus;
    this.lessonStatus = lessonStatus;
  }

  protected doApply(): void {
    if (this.productCode) {
      this.append('productCode', this.productCode);
    }
    if (this.courseCode != null) {
      this.append('courseCode', this.courseCode);
    }
    if (this.commitStatus != null) {
      this.appendArray('commitStatus', this.commitStatus);
    }
    if (this.lessonStatus != null) {
      this.appendArray('lessonStatus', this.lessonStatus);
    }
  }
}

export enum LearningUnitType {
  School, Org, Teacher
}

export enum ApiLessonType {
  Standard, Revision, QuickStageRevision, Introduction, Prova, Exam
}

export enum ApiLessonStatus {
  Initializing,
  Booked,
  Due,
  InProgress,
  Complete,
  Cancel,
  InvalidatedByTeacher,
  InvalidatedByStudent
}

export enum LearnigUnitType {
  School,Org,Teacher
}

export enum LessonCommitStatus {
  PENDING,
  CANCELLED,
  CONFIRMED
}

export enum LessonEventType {
  Create,
  PersonAttach,
  PersonDetach,
  Move,
  StatusUpdate,
  FlagAttached,
  MessageAdded,
  FlagSolved,
}

export enum LessonFlagStatus {
  Submitted,
  Solved
}

export enum LessonFlagTargetType {
  Lesson,
  Teacher,
  Student
}

export class LessonStatusUtils {
  static plannedLessons = [
    ApiLessonStatus.Initializing,
    ApiLessonStatus.Booked,
    ApiLessonStatus.Due,
    ApiLessonStatus.InProgress,
  ];
  static isComing = [
    ApiLessonStatus.Initializing,
    ApiLessonStatus.Booked,
    ApiLessonStatus.Due,
  ];
  static completeLessons = [ApiLessonStatus.Complete];
  static cancelable = [ApiLessonStatus.Initializing, ApiLessonStatus.Booked];
  static revokedLessons = [
    ApiLessonStatus.Cancel,
    ApiLessonStatus.InvalidatedByStudent,
    ApiLessonStatus.InvalidatedByTeacher,
  ];
}

export class ApiBase {
  id: number;
}

export class ApiCasaBase {
  id: number;
  modifyDate: Date;
  lastSyncDate: Date;
}

export class ApiLessonBundle extends ApiBase {
  product: ApiCourseProduct;
  orderId: string;
  date: Date;
  available: number;
  expiryDate: Date;
  registeredLessons: number;
  name: string;
  price: string;
  bundleDuration: number;
  note: string
}

export class ApiLessonBundleUpdate {
  expiryDate: Date;
  note: string;
}

export class ApiLearningUnit extends ApiCasaBase {
  name: string;
  type: LearningUnitType;
}

export class ApiPersonBase extends ApiCasaBase {
  name: string;
  surname: string;
  accountId: number;
  emailAddress: string;
}

export class ApiPerson<T extends ApiPersonalProfileBase> extends ApiPersonBase {
  personalProfile: T;
}

export class ApiLearningUnitTeacherBase extends ApiCasaBase {
}

export class ApiLearningUnitTeacher extends ApiLearningUnitTeacherBase {
  person: ApiPerson<ApiPersonalProfileBaseWithPhoto>;
}

export class ApiLearningUnitStudentBase extends ApiCasaBase {
}

export class ApiLearningUnitStudent<P extends ApiPersonBase> extends ApiLearningUnitStudentBase {
  person: P;
}

export class ApiLessonMetric {
  plannedStartDate: Date;
  started: Date;
  plannedDuration: number;
  duration: number;
  timezone: string;
}

export class ApiTeacherScore {
  teacher: ApiLearningUnitTeacher;
  score: number;
}

export class ApiCancellationDetails {
  cancelationDate: Date;
  cancelledByTeacher: boolean;
  cancellationReason: string;
}

export class ApiLessonInstanceBase extends ApiBase {
  lessonStatus: string;
  lessonType: string;
  createDate: Date;
  lessonMetric: ApiLessonMetric;
  cancellationDetails: ApiLessonCancellationDetails;
}

export abstract class ApiLessonInstancePreviewBase extends ApiLessonInstanceBase {
  progressCommited: ApiLessonProgress;
  course: ApiCourse;
  competence: ApiCompetence;
}

export class ApiTeacherWorktime extends ApiBase {
  teacher: ApiLearningUnitTeacher;
  startDate: Date;
  duration: number;
  product: ApiCourseProduct;
  competences: ApiCompetence[];
  minimumOvertake: number;
}

export class ApiLessonInstance <S extends ApiPersonalProfileBase, T extends ApiLearningUnitTeacherBase>
  extends ApiLessonInstancePreviewBase {
  teacher: T;
  students: ApiLearningUnitStudent<ApiPerson<S>>[];
  message: ApiLessonMessage;
  flags: ApiLessonFlag[];
  studentMessage: string;
  roomUrl: string;
  productVersion: string;
}


export class ApiTeacherLessonInstance<S extends ApiPersonalProfileBase, T extends ApiLearningUnitTeacherBase> extends ApiLessonInstance<S, T> {
  override studentMessage: string;
}

export class ApiCompetence {
  name: string;
  code: string;
}

export class ApiCompetenceMapping {
  course: ApiCourse;
  competence: ApiCompetence;
}

export class ApiLessonMessage {
  lessonComment: string;
  privateLessonComment: string;
  message: string;
}

export class ApiFile extends ApiBase {
  uploadOriginalName: string;
  name: string;
  contentType: string;
  variantName: string;
  size: number;
  resourceKey: string;
  uploadDate: string;
  accessToken: string;
}

export class ReportRow {
  values: any[];
}

export class EntityRelatedRow<T> extends ReportRow {
  entity: T;
}

export class ApiLessonFlag extends ApiBase {

  static StatusSolved = 'Solved';
  static StatusSubmitted = 'Submitted';
  static TargetLesson = 'Lesson';
  static TargetTeacher = 'Teacher';
  static TargetStudent = 'Student';
  static FtPoorConnection = 'PoorConnection';
  static FtClassroomIssue = 'ClassroomIssue';
  static FtStudentNeedsHelp = 'StudentNeedsHelp';
  static FtStudentNotShow = 'StudentNotShow';
  static FtTeacherNotShow = 'TeacherNotShow';
  static FtOther = 'Other';
  static flagsTypes = [
    ApiLessonFlag.FtPoorConnection,
    ApiLessonFlag.FtClassroomIssue,
    ApiLessonFlag.FtStudentNeedsHelp,
    ApiLessonFlag.FtOther,
  ];
  static descriptionRequiringFlags = [
    ApiLessonFlag.FtStudentNeedsHelp,
    ApiLessonFlag.FtOther,
  ];
  static allFlagTypes = [
    ApiLessonFlag.FtPoorConnection,
    ApiLessonFlag.FtClassroomIssue,
    ApiLessonFlag.FtStudentNeedsHelp,
    ApiLessonFlag.FtStudentNotShow,
    ApiLessonFlag.FtTeacherNotShow,
    ApiLessonFlag.FtOther,
  ];
  flagType: string;
  date: Date;
  description: string;
  solveDate: Date;
  solveDetails: string;
  status: string;
  target: LessonFlagTargetType;

  static getFLagDescription(type: string) {
    if (type === ApiLessonFlag.FtClassroomIssue) {
      return 'Classroom issue';
    } else if (type === ApiLessonFlag.FtOther) {
      return 'Other';
    } else if (type === ApiLessonFlag.FtPoorConnection) {
      return 'Poor connection';
    } else if (type === ApiLessonFlag.FtStudentNeedsHelp) {
      return 'Student neeeds help';
    } else if (type === ApiLessonFlag.FtStudentNotShow) {
      return 'Student no Show';
    } else if (type === ApiLessonFlag.FtTeacherNotShow) {
      return 'Teacher no show';
    } else {return 'Unknown'; }
  }
}

export class ApiLessonProgress extends ApiBase{
  start: number;
  reading: number;
  nwp: number;
  hw: string;

  productCode: string;
  courseCode: string;

  courseProgress: number;
  productProgress: number;
}

export class ApiPersonTechnicalProfileBase extends ApiBase {
  lang: string;
  phone: string;
  skype: string;

  notifBookingConfirm: boolean;
  notifOffers: boolean;
  allowBookingTeacherChange: boolean;

  introductionState: IntroductionState;
}

export class ApiPersonTechnicalProfile extends ApiPersonTechnicalProfileBase {
  person: ApiPersonBase;
}

export class ApiPersonalProfileBase extends ApiBase {
  biography: string;
  name: string;
  surname: string;
  email: string;
  nationality: string;
  gender: string;
  reasonForStuding: string;
  birthPlace: string;
  birthDate: Date;
  nationalityIso: number;
  countryIso: number;
}

export class ApiPersonalProfileBaseWithPhoto extends ApiPersonalProfileBase {
  profilePhoto: ApiFile;
}

export class ApiPersonalProfile extends ApiPersonalProfileBaseWithPhoto {
  person: ApiPersonBase;
}

export class ApiCourseProduct {
  name: string;
  code: string;
}

export class ApiCourseBase {
  name: string;
  code: string;
}

export class ApiCourse extends ApiCourseBase {
  product: ApiCourseProduct;
}

export class ApiLessonCancellationDetails {
  cancelationDate: Date;
  cancelledByTeacher: boolean;
  cancellationReason: String;
}

export class ApiLessonCommit extends ApiBase {
  bundle: ApiLessonBundle;
  lesson: ApiLessonInstanceBase;
  status: string;
  createDate: Date;
  updateDate: Date;
}

export class ApiTeacherProductCompetences {
  product: ApiCourseProduct;
  competences: ApiCompetence[];
  productCompetenceDescription: string;
  accent: string;
  videoUrl: string;
}

export class ApiStudentSchedule extends ApiBase {
  startTime: Date;
  duration: number;
  lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>;
  course: ApiCourse;
  competence: ApiCompetence;
  teacher: ApiLearningUnitTeacher;
  students: ApiLearningUnitStudentBase[];
  timezone: string;
}

export class ApiTeacherProfileBase extends ApiBase {
  skype: string;
  language: string;
}

export class ApiTeacherProfileWithCompetences extends ApiTeacherProfileBase {
  competences: ApiTeacherProductCompetences[];
}

export class ApiTeacherProfile extends ApiTeacherProfileWithCompetences {
  teacher: ApiLearningUnitTeacher;
}

export class ApiProductContextBase extends ApiBase {
  product: ApiCourseProduct;
  nextLessonType: string;
  currentCourse: ApiCourse;
}

export class ApiProductContext extends ApiProductContextBase {
  courseContexts: ApiCourseContext[];
}

export class ApiStudentProductContext extends ApiProductContext {
  productVersion: string;
}

export class ApiCourseContext extends ApiBase {
  course: ApiCourseBase;
  lastRegularLesson: ApiLessonInstanceBase;
  lastExam: ApiLessonInstanceBase;
  lastRevision: ApiLessonInstanceBase;
  lastQuickStageRevision: ApiLessonInstanceBase;
  firstLesson: ApiLessonInstanceBase;
  lessons: number;
  exams: number;
  revisions: number;
  quickStageRevisions: number;
  overall: number;
  revisionProgress: number;
  lessonsProgress: number;
  quickStageRevisionProgress: number;
  estimatedUntilFinish: number;
}


export class ApiCountry {
  code: string;
  name: string;
  isoCode: number;
}

export class SchoolPeopleQueryFilter {
  query: string;

  public apply(params: HttpParams) {
    if (!this.query) {return params; }
    return params.append('query', this.query);
  }
}

export class ApiProvaContext {
  productContext: ApiProductContextBase;
  starterExpiryDate: Date;
  starterBuyDate: Date;
}

export class ApiProductGift {
  descriptor: ApiGiftDescriptor;
  product: ProductMapping;
  currency: string;
  amount: number;
}

class ApiGiftDescriptor {
  expiryDate: number;
  name: string;
  description: string;
  metadata: string;
  created: number;
  assigned: number;
}

export class ProductMapping {
  id: string;
  platform: string;
}

export class ApiWorktimeDefinition extends ApiBase {
  static days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  recurring: RecurringType;
  recurringFinish: Date;
  baseWorktime: ApiTeacherWorktime;
  timezoneOffset: number;
  weekDays: string[];
}

export enum RecurringType {
  Single,
  Daily,
  Weekly,
  Monthly,
}

export enum IntroductionState {
  Language = 'language',
  Course = 'course',
  PersonalProfile = 'personalProfile',
  FreeCredit = 'freeCredit',
  ReserveProva = 'reserveProva',
  ReserveSkipped = 'reserveSkipped',
  FutureLesson = 'futureLesson', 
  Cspa = 'cspa', 
  Welcome = 'welcome', 
  Finished = 'finished',
  InvitedToColNew = 'invited',
  WelcomedOnColNew = 'welcomedOnColNew'
}

export class RecurringTypeUtils {
  public static values = [
    RecurringType.Single,
    RecurringType.Daily,
    RecurringType.Weekly,
    RecurringType.Monthly,
  ];

  static days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  public static getName(type: RecurringType) {
    switch (type) {
      case RecurringType.Single:
        return 'COL.TEACHER.CREATE_WORKTIME.RECURRING_TYPES.SINGLE';
      case RecurringType.Daily:
        return 'COL.TEACHER.CREATE_WORKTIME.RECURRING_TYPES.DAILY';
      case RecurringType.Weekly:
        return 'COL.TEACHER.CREATE_WORKTIME.RECURRING_TYPES.WEEKLY';
      case RecurringType.Monthly:
        return 'COL.TEACHER.CREATE_WORKTIME.RECURRING_TYPES.MONTHLY';
    }
  }

  public static getRecurring(recurringType: RecurringType, weekDays: string[]) {
    let recurring: string
    let type = this.values[+recurringType]
    switch (type) {
      case RecurringType.Single:
        recurring = null;
        break
      case RecurringType.Monthly:
        recurring = "m:1";
        break
      case RecurringType.Daily:
        recurring = "w:1";
        break
      case RecurringType.Weekly:
        recurring = "w:1";
        break
    }
    if(type == RecurringType.Daily && weekDays.length > 0) {
      weekDays.forEach(d => {
        let dayIndex = this.days.indexOf(d)
        if(dayIndex > -1)
          recurring = recurring.concat(`:${dayIndex + 1}`)
      })
    }
    return recurring
  }
}
