<ng-container *ngIf="exams?.length > 0; else noResults">
  <ng-container *ngFor="let el of exerciseSetExams | keyvalue; let i = index">
    <div class="card mb-5" *ngIf="el.value.length > 0">
      <div class="card-header border-bottom border-secondary pb-4">
        <h5 class="mb-0">
          <h5>{{ el.key.name }}</h5>
        </h5>
      </div>
      <div class="card-body">
        <div class="accordion accordion-flush" [id]="'examsList' + i">
          <div *ngFor="let exam of el.value; let j = index" class="accordion-item mb-3">
            <h2 class="accordion-header" [id]="'heading' + i + j">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#collapse' + i + j" aria-expanded="true"
                      [attr.aria-controls]="'collapse' + i + j">
                {{ exam.chapterName }} {{ exam.examName }} - {{ getExamScore(exam) | percent }}
              </button>
            </h2>
            <div [id]="'collapse' + i + j" class="accordion-collapse collapse"
                 [attr.aria-labelledby]="'heading' + i + j"
                 [attr.data-bs-parent]="'#examsList' + i">
              <div class="accordion-body p-2">
                <div class="accordion" id="examsPartList">
                  <div *ngFor="let examPart of exam.parts; let i = index" class="accordion-item mb-2">
                    <app-exam-part-result [examPart]="examPart"></app-exam-part-result>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #noResults>
  <p class="text-center fs-5 text-muted m-0">
    {{ 'LESSONS.NO_RESULTS' | translate }}
  </p>
</ng-template>
