import {ApiPerson, ApiPersonalProfileBaseWithPhoto} from 'src/app/col/model/rest-model';
import { environment } from 'src/environments/environment';
import {Person, PersonDetails, StudentPersonDetails} from '../model/casa/casa-model';

export class PersonUtils {
  static getPersonName(
    person: PersonDetails | StudentPersonDetails | ApiPerson<ApiPersonalProfileBaseWithPhoto>
  ): string {
    const fullName: string[] = [];
    if (person.name != null) fullName.push(person.name);
    if (person.surname != null) fullName.push(person.surname);
    return fullName.join(' ');
  }

  static getInitials(person: ApiPerson<ApiPersonalProfileBaseWithPhoto> | PersonDetails) {
    if (!person) {return ''; }
      const nameComponents: string[] = [];
      if (person instanceof ApiPerson && person.personalProfile) {
        if (person.personalProfile.name) {
          nameComponents.push(person.personalProfile.name);
        }
        if (person.personalProfile.surname) {
          nameComponents.push(person.personalProfile.surname);
        }
      } else {
        if (person.name) {
          nameComponents.push(person.name);
        }
        if (person.surname) {
          nameComponents.push(person.surname);
        }
      }
      return nameComponents.map(part => {return part[0]}).join('').toUpperCase()
  }

  public static getProfilePhoto(personalProfile: ApiPersonalProfileBaseWithPhoto) {
    return environment.fileEndpoint + '/img/'
    + personalProfile.profilePhoto.id
    + '?fileAccessToken='
    + personalProfile.profilePhoto.accessToken ;
}

  public static getPersonProfilePhoto(person: ApiPerson<ApiPersonalProfileBaseWithPhoto>) {
    if (!person || !person.personalProfile || !person.personalProfile.profilePhoto) {
        return null;
    }
    return this.getProfilePhoto(person.personalProfile);
  }

  static getName<T extends PersonDetails>(person: Person<T>) {
    const components = [];
    if (person.details.name) components.push(person.details.name);
    if (person.details.surname) components.push(person.details.surname);
    return components.join(" ");
  }
}
