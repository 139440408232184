import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ebook, OwnershipLocalState } from '../../model/book-model';
import { map, Observable, Subscription, switchMap, tap, last, finalize, delay } from 'rxjs';
import { NativeServiceApiProvider } from 'src/app/services/native-api-provider.service';
import { LogsService } from 'src/app/utils/services/logs.service';
import { BookDetailsService } from '../../services/book-details.service';
import { LangCode } from 'src/app/utils/text-compare';
import { AuthorizationServiceProvider } from 'src/app/auth_profile/services/authorization-service.provider';

@Component({
  selector: 'app-book-details',
  templateUrl: './book-details.component.html',
  styleUrls: ['./book-details.component.scss']
})
export class BookDetailsComponent implements OnInit {
  ebookId: string
  ebook: Ebook
  actionDisabled = false;
  isImgLoading = true
  isContentLoading = true
  ebookCover = null
  audioDisabled = false;
  dictDisabled = false;
  downloadProgress: number = null;
  audioProgress: number = null;
  dictProgress: number = null;
  localState: OwnershipLocalState;
  updatesSubscription: Subscription;
  isAppNative: boolean

  constructor(
    private route: ActivatedRoute,
    private apiProvider: NativeServiceApiProvider,
    private router: Router,
    private logger: LogsService,
    public bookDetails: BookDetailsService,
    private authServiceProv: AuthorizationServiceProvider
    ) {
      this.authServiceProv.getCommonRoutines().subscribe(auth =>
        this.isAppNative = auth.isNativeAuthorization()
      )
    }

  ngOnInit(): void {
      this.route.paramMap.pipe(
        tap(params => {
          this.ebookId = params.get("ebookId");
        }),
        switchMap(_ => this.loadBook()),
        delay(100),
        tap(_ => this.traceDownloadableContentProgress())
      ).subscribe()

      this.apiProvider.books().pipe(
        map( api => api.listenForDataUpdates())
      ).subscribe(emitter =>  {
        this.updatesSubscription = emitter.subscribe(() => this.loadBook().subscribe())
      });
  }

  ngOnDestroy(): void {
    this.updatesSubscription.unsubscribe();
  }

  traceDownloadableContentProgress() {
    this.apiProvider.books().subscribe(
      api => {
        this.attachDictProgress(api.downloadingDictationProgress(+this.ebookId, this.localState.ownership.eBook.release.id))
        this.attachAudioProgress(api.downloadingAudioProgress(+this.ebookId, this.localState.ownership.eBook.release.id))
      }
    )

  }

  attachDictProgress(progressEmitter: Observable<number>) {
    this.dictDisabled = true;
    this.dictProgress = 0;
    this.doWithProgress(
        progressEmitter,
        (progress) => this.dictProgress = progress,
        () => {
          this.dictDisabled = false;
          this.dictProgress = null;
        }
    );
  }

  attachAudioProgress(progressEmitter: Observable<number>) {
    this.audioDisabled = true;
    this.audioProgress = 0;
    this.doWithProgress(
        progressEmitter,
        progress => {
          this.logger.log(`got audio progress report ${progress}`)
          this.audioProgress = progress;
        },
        () => {
          this.audioDisabled = false;
          this.audioProgress = null;
        });
  }

  doWithProgress(
    progressObservable: Observable<number>,
    progressHandler: (progress: number) => void, completeCallback: () => void) {

      progressObservable.pipe(
        finalize(() => {
          completeCallback();
          this.loadBook().subscribe();
        })
      ).subscribe(progress =>
        progressHandler(progress)
      );
  }

  loadBook(): Observable<any> {
    return this.apiProvider.books().pipe(
      switchMap( api =>
        api.getProductById(+this.ebookId).pipe(
          map(ownershipLS => {
            this.localState = ownershipLS
            this.ebook = this.localState.ownership.eBook
            this.isContentLoading = false
            return this.ebook
          }),
          switchMap( ebook =>
            api.getImageUrl(0, ebook.release.id)
          ),
          tap(data => {
            this.ebookCover = data;
            this.isImgLoading = false
          })
      )
    ))
  }

  onAction() {
    const action = this.bookDetails.getAction(this.localState);

    if (action === this.bookDetails.ACTION_REGISTER_DOWNLOAD || action === this.bookDetails.ACTION_DOWNLOAD) {
      this.actionDisabled = true;
      this.downloadProgress = 0;
      this.apiProvider.books().pipe(
        switchMap( api =>
          api.register(+this.ebookId, this.localState.ownership.eBook.release.id).pipe(
            switchMap( () => api.download(+this.ebookId, this.localState.ownership.eBook.release.id)),
            tap( progress => this.downloadProgress = progress as number),
            last(),
            switchMap( () => this.loadBook()),
            switchMap( () => api.open(+this.ebookId, this.localState.ownership.eBook.release.id)),
            finalize(() => {
              this.logger.log('on finalize');
              this.actionDisabled = false;
              this.downloadProgress = null;
            }),
        )
      )
      ).subscribe(_ =>
          this.logger.log('got some response from download event')
      );
    } else if ( action === this.bookDetails.ACTION_OPEN) {
      this.actionDisabled = true;
      this.apiProvider.books().pipe(
        switchMap( api => api.open(+this.ebookId, this.localState.ownership.eBook.release.id))
      ).subscribe( _ =>
        this.actionDisabled = false
      );
    }
  }

  downloadDict() {
    if (this.dictDisabled) {
      return;
    }

    this.attachDictProgress(
      this.apiProvider.books().pipe(
        switchMap( api => api.downloadDict(+this.ebookId, this.localState.ownership.eBook.release.id))
      ));
  }

  deleteDict() {
    if (this.dictDisabled) {
      return;
    }

    this.dictDisabled = true;
    this.doWithProgress(
      this.apiProvider.books().pipe(
        switchMap( api => api.deleteDict(+this.ebookId, this.localState.ownership.eBook.release.id))
      ),
      () => {},
      () => this.dictDisabled = false
    );
  }

  open() {
    const action = this.bookDetails.getAction(this.localState);
    if ( action === this.bookDetails.ACTION_OPEN) {
      this.actionDisabled = true;
      this.apiProvider.books().pipe(
        switchMap(api => api.open(+this.ebookId, this.localState.ownership.eBook.release.id))
      ).subscribe(_ =>
        this.actionDisabled = false
      );
    }
  }

  downloadAudio() {
    if (this.audioDisabled) {
      return;
    }
    this.attachAudioProgress(
      this.apiProvider.books().pipe(
        switchMap(api => api.downloadAudio(+this.ebookId, this.localState.ownership.eBook.release.id))
      ));
  }

  deleteAudio() {
    if (this.audioDisabled) {
      return;
    }

    this.audioDisabled = true;
    this.doWithProgress(
      this.apiProvider.books().pipe(
        switchMap( api => api.deleteAudio(+this.ebookId, this.localState.ownership.eBook.release.id))
      ),
      () => {},
      () => this.audioDisabled = false
    );
  }

  getShares() {
    return {
      used: this.bookDetails.getUsedShares(this.localState),
      total: this.bookDetails.getAvailableShares(this.localState) + this.bookDetails.getUsedShares(this.localState)
    }
  }

  getActionName() {
    if (!this.ebook) return null;
    else if(this.bookDetails.getAction(this.localState) === this.bookDetails.ACTION_REGISTER_DOWNLOAD)
      return 'EBOOKS.ACTION.' + 'REGISTER_DOWNLOAD'
    return 'EBOOKS.ACTION.' + this.bookDetails.getAction(this.localState).toUpperCase()
  }

  getAccent() {
    return `stg-${this.ebook?.name.slice(this.ebook.name.length - 2).trim()}`
  }

  getEbookName() {
    return this.ebook?.name
  }

  getEbookLanguage() {
    return this.ebook?.categories[0]
  }
  getEbookLanguageTranslation() {
    return this.ebook? 'EBOOKS.FILTER_LANGS.' + LangCode[this.ebook.categories[0]] : ''
  }

  getEbookDescription() {
    return this.ebook?.description
  }

  getEbookCover() {
    return this.ebookCover
  }

  getAudioSize() {
    return { size: this.bookDetails.getAudioSize(this.localState)}
  }

  getDictationSize() {
    return { size: this.bookDetails.getDictationSize(this.localState)}
  }

  hasProduct() {
    return this.localState?.ownership.hasProduct
  }

  goToEbooksListWithFilter(language: string) {
    this.router.navigate(
      ['../'],
      {
        relativeTo: this.route,
        queryParams: { language: `${language}`},
        queryParamsHandling: 'merge'
      }
    )
  }

  goToDownload(app: string) {
    if(app==='android')
      return 'https://callan.co.uk/downloads/callan-method-app-3.9.2.apk'
    else if(app==='iOS')
      return 'https://itunes.apple.com/gb/app/callan-method-app/id1099785360'
    else
      return ''
  }

  isDownloading() {
    return this.downloadProgress !== null
  }

  isNative() {
    return this.isAppNative
  }
}
