import { Injectable, Injector, Type } from '@angular/core';
import {EMPTY, map, Observable, of, ReplaySubject, Subject, take} from 'rxjs';
import { environment } from 'src/environments/environment';
import { PlatformDescriptor } from '../utils/platform-descriptor';
import { LogsService } from '../utils/services/logs.service';
import { DesktopNativeApi } from '../books/services/api/impl/desktop-books-native-api';
import { EmulatedNativeServiceApi } from '../books/services/api/impl/emulated-books-native-api';
import { IosNativeApi } from '../books/services/api/impl/ios-books-native-api';
import { WebNativeServiceApi } from '../books/services/api/impl/web-native-api.service';
import {WebCasaNativeService} from "../auth_profile/services/api/impl/rest/web-casa-native.service";
import {UnsupportedVideoApi, VideoApi, VideoRestService} from "../lessons/services/video-rest.service";
import { CspaRestServiceNew} from "../cspa/services/api/impl/rest/cspa.service";
import {EmptyRecorder, Recorder} from "../cspa/model/recorder";
import {WebMobileKindEmulatedService} from "../cspa/services/api/impl/web-mobile-kind-emulated.service";
import {CasaNativeApi} from "../auth_profile/services/api/casa-native-api";
import {UnsupportedCasaNativeApi} from "../auth_profile/services/api/impl/rest/unsupported-casa-native-api.service";
import {BooksNativeServiceApi} from "../books/services/api/books-native-api";
import {AndroidBooksNativeApi} from "../books/services/api/impl/android-books-native-api";
import {IosNewNativeApi} from "../cspa/services/api/impl/ios-new-native-api";
import {AndroidRequestBasedNativeApi} from "../cspa/services/api/impl/AndroidRequestBasedNativeApi";
import {MobileNativeAudioRecorderAdapter} from "../cspa/services/api/mobile-native-audio-recorder-adapter";
import {CspaApi, CspaMobileBridgeServicesApi} from "../cspa/services/api/cspa.api";
import {NewMobileBridgeService} from "../cspa/services/api/new-mobile-bridge.service";
import { PropertiesNativeServiceApi } from './api/properties-native-api';
import { WebPropertiesNativeApi } from './api/impl/web-properties-native-api';
import { IosPropertiesNativeApi } from './api/impl/ios-properties-native-api';
import { AndroidPropertiesNativeApi } from './api/impl/android-properties-native-api';
import { EmulatedPropertiesNativeApi } from './api/impl/emulated-properties-native-api';
import { DesktopPropertiesNativeApi } from './api/impl/desktop-properties-native-api';
import { WebColNativeServiceApi } from '../col/services/api/impl/web-col-native-api.service';
import { UnsupportedColNativeServiceApi } from '../col/services/api/impl/unsupported-col-native-api.service';
import { ColNativeServiceApi } from '../col/services/api/col-native-api';
import {CasaEmulatedService} from "../auth_profile/services/api/impl/casa-emulated.service";
import {CasaAndroidService} from "../auth_profile/services/api/impl/casa-android.service";
import {CasaIosService} from "../auth_profile/services/api/impl/casa-ios.service";
import {VideoAndroidService} from "../lessons/services/api/impl/rest/video-android.service";
import {VideoIosService} from "../lessons/services/api/impl/rest/video-ios.service";
import {ColAndroidService} from "../col/services/api/impl/col-android.service";
import {ColIosService} from "../col/services/api/impl/col-ios.service";


/**
 * This is a dummy mobile bridge services which has simple responses for mobile app lifecycle methods
 */
class VoidMobileBridgeServices implements CspaMobileBridgeServicesApi {
  syncTopStructure(): Observable<void> {
    return of(null)
  }
  close(): void {
  }

  isDataValid(exerciseSet: string): Observable<boolean> {
    return of(true);
  }

  syncSessions(): Observable<void> {
    return of(null);
  }

  syncStructure(exerciseSet: string): Observable<void> {
    return of(null);
  }
}

@Injectable({
  providedIn: 'root'
})
export class NativeServiceApiProvider {
  caches: {[cacheName: string]: Subject<any>} = {}

  constructor(
    private injector: Injector,
    private logger: LogsService) {}

  cacheChain<T>(name: string, instanceBuilder: () => Observable<T>): Observable<T> {
    let instanceSubject: Subject<T> = this.caches[name];
    if (instanceSubject) return instanceSubject.asObservable().pipe(take(1))
    instanceSubject = new ReplaySubject<T>(1)
    this.caches[name] = instanceSubject
    instanceBuilder().subscribe(result =>
      instanceSubject.next(result)
    )
    return instanceSubject.asObservable().pipe(take(1))
  }

  books(): Observable<BooksNativeServiceApi> {
    return this.cacheChain<BooksNativeServiceApi>("books", () =>
      of(this.injector.get(this.matchApiClass(environment.platform)))
    );
  }

  casa(): Observable<CasaNativeApi> {
    return this.cacheChain<CasaNativeApi>("casa", () =>
      of(this.injector.get(this.matchCasaApiClass(environment.platform)))
    )
  }

  video(): Observable<VideoApi> {
    return this.cacheChain<VideoApi>("video", () =>
      of(this.injector.get(this.matchVideoApiClass(environment.platform)))
    )
  }

  properties(): Observable<PropertiesNativeServiceApi> {
    return this.cacheChain<PropertiesNativeServiceApi>("properties", () =>
      of(this.injector.get(this.matchPropertiesApiClass(environment.platform)))
    )
  }

  col(): Observable<ColNativeServiceApi> {
    return this.cacheChain<ColNativeServiceApi>("col", () =>
      of(this.injector.get(this.matchColApiClass(environment.platform)))
    )
  }

  private cspaRest(): Observable<CspaRestServiceNew> {
    return this.cacheChain("cspaRest", () =>
      of(this.injector.get(CspaRestServiceNew))
    );
  }

  private iosCspaNative(): Observable<IosNewNativeApi> {
    return this.cacheChain("iosCspaNative", () =>
      of(this.injector.get(IosNewNativeApi))
    )
  }

  private androidCspaNative(): Observable<AndroidRequestBasedNativeApi> {
    return this.cacheChain("androidCspaNative", () =>
      of(this.injector.get(AndroidRequestBasedNativeApi))
    )
  }

  cspaMobile(): Observable<NewMobileBridgeService> {
    return this.cacheChain("cspaMobile", () => {
      const platform = environment.platform;
      if (platform.name === PlatformDescriptor.emulated.name) {
        return this.cspaRest().pipe(
          map( cspa =>
            new NewMobileBridgeService(new WebMobileKindEmulatedService(cspa, this.logger), cspa, this.logger)
          )
        )
      }
      if (platform.name === PlatformDescriptor.iOS.name) {
        return this.iosCspaNative().pipe(
          map( native =>
            new NewMobileBridgeService(native, native, this.logger)
          )
        )
      }
      if (platform.name === PlatformDescriptor.Android.name) {
        return this.androidCspaNative().pipe(
          map( native =>
            new NewMobileBridgeService(native, native, this.logger)
          )
        )
      }
      throw Error("api not supported")
    })
  }

  cspaBridge(): Observable<CspaMobileBridgeServicesApi> {
    return this.cacheChain<CspaMobileBridgeServicesApi>("cspaBridge",() => {
      const platform = environment.platform;
      if (platform.name === PlatformDescriptor.web.name) {
        return of(new VoidMobileBridgeServices());
      }
      return this.cspaMobile();
    });
  }

  cspa(): Observable<CspaApi> {
    return this.cacheChain<CspaApi>("cspa", () => {
        const platform = environment.platform;
        if (platform.name === PlatformDescriptor.web.name) {
          return this.cspaRest();
        }
        return this.cspaMobile();
      }
    )
  }


  private iosNativeAudio(): Observable<Recorder> {
    return this.cacheChain("iosNativeAudio", () => {
      return new Observable<Recorder>( observer => {
          this.iosCspaNative().subscribe( iosNative => {
            iosNative.initAudio( state => {
              if (state) {
                observer.next(new MobileNativeAudioRecorderAdapter(iosNative));
                observer.complete();
              } else {
                observer.next(new EmptyRecorder());
                observer.complete();
              }
            })
          })
        }
      )
    })
  }

  private androidNativeAudio(): Observable<Recorder> {
    return this.cacheChain("androidNativeAudio", () => {
      return new Observable<Recorder>( observer => {
          this.androidCspaNative().subscribe( androidNative => {
            androidNative.initAudio( state => {
              if (state) {
                observer.next(new MobileNativeAudioRecorderAdapter(androidNative));
                observer.complete();
              } else {
                observer.next(new EmptyRecorder());
                observer.complete();
              }
            })
          })
        }
      )
    })
  }

  public getAlternativeAudioApi(): Observable<Recorder> {
    const platform = environment.platform;
    if (platform.name === PlatformDescriptor.web.name
      || platform.name === PlatformDescriptor.emulated.name
    ) {
      return EMPTY;
    }
    if (platform.name === PlatformDescriptor.Android.name) {
      return this.androidNativeAudio();
    }
    if (platform.name === PlatformDescriptor.iOS.name) {
      return this.iosNativeAudio();
    }
    throw new Error("not supported platform for video api");

  }

  private matchVideoApiClass(platform: PlatformDescriptor): Type<any> {
    if (platform.name === PlatformDescriptor.web.name) {
      return VideoRestService;
    } else if (platform.name === PlatformDescriptor.emulated.name) {
      return VideoRestService;
    } else if (environment.ssmiEnabled && platform.name === PlatformDescriptor.Android.name) {
      return VideoAndroidService;
    } else if (environment.ssmiEnabled && platform.name === PlatformDescriptor.iOS.name) {
      return VideoIosService;
    }
    return UnsupportedVideoApi;
  }

  private matchCasaApiClass(platform: PlatformDescriptor): Type<any> {
    if (platform.name === PlatformDescriptor.web.name) {
      return WebCasaNativeService;
    } else if (platform.name === PlatformDescriptor.emulated.name) {
      return CasaEmulatedService;
    } else if (environment.ssmiEnabled && platform.name === PlatformDescriptor.Android.name) {
      return CasaAndroidService
    } else if (environment.ssmiEnabled && platform.name === PlatformDescriptor.iOS.name) {
      return CasaIosService
    }
    return UnsupportedCasaNativeApi;
  }

  private matchApiClass(platform: PlatformDescriptor): Type<any> {
    if (platform.name === PlatformDescriptor.iOS.name) {
      return IosNativeApi;
    } else if (platform.name === PlatformDescriptor.Android.name) {
      return AndroidBooksNativeApi;
    } else if (platform.name === PlatformDescriptor.web.name) {
      return WebNativeServiceApi;
    } else if (platform.name === PlatformDescriptor.emulated.name) {
      return EmulatedNativeServiceApi;
    } else if (platform.name === PlatformDescriptor.Desktop.name) {
      return DesktopNativeApi;
    }
    return null
  }

  private matchPropertiesApiClass(platform: PlatformDescriptor): Type<any> {
    if (platform.name === PlatformDescriptor.iOS.name) {
      return IosPropertiesNativeApi;
    } else if (platform.name === PlatformDescriptor.Android.name) {
      return AndroidPropertiesNativeApi;
    } else if (platform.name === PlatformDescriptor.web.name) {
      return WebPropertiesNativeApi;
    } else if (platform.name === PlatformDescriptor.emulated.name) {
      return EmulatedPropertiesNativeApi;
    } else if (platform.name === PlatformDescriptor.Desktop.name) {
      return DesktopPropertiesNativeApi;
    }
    return null
  }

  private matchColApiClass(platform: PlatformDescriptor): Type<any> {
    if (platform.name === PlatformDescriptor.web.name) {
      return WebColNativeServiceApi;
    } else if (platform.name === PlatformDescriptor.emulated.name) {
      return WebColNativeServiceApi;
    } else if (platform.name === PlatformDescriptor.Android.name) {
      return ColAndroidService;
    } else if (platform.name === PlatformDescriptor.iOS.name) {
      return ColIosService;
    }
    return UnsupportedColNativeServiceApi;
  }
}
