import {ContactUsEntryPointComponent} from "./mobile/contact-us-entry-point/contact-us-entry-point.component";
import {NotificationDetailsComponent} from "./pages/notifications/notification-details/notification-details.component";
import {RouterModule, Routes} from "@angular/router";
import {LoginEntryPointComponent} from "./mobile/login-entry-point/login-entry-point.component";
import {BookListEntryPointComponent} from "./mobile/book-list-entry-point/book-list-entry-point.component";
import {
  TeacherDashboardLessonsDetailsPageComponent
} from "./col/pages/teacher/teacher-dashboard-lessons-details-page/teacher-dashboard-lessons-details-page.component";
import {NotificationListComponent} from "./pages/notifications/notification-list/notification-list.component";
import {StylingComponent} from "./pages/styling/styling.component";
import {StudentProfilePageComponent} from "./auth_profile/pages/student-profile-page/student-profile-page.component";
import {
  TeacherGroupScheduleDetailsPageComponent
} from "./lessons/pages/teacher-group-schedule-details-page/teacher-group-schedule-details-page.component";
import {ContactUsResultPageComponent} from "./pages/contact-us/contact-us-result-page/contact-us-result-page.component";
import {ScheduleDetailsPageComponent} from "./lessons/pages/schedule-details-page/schedule-details-page.component";
import {OrderFailComponent} from "./col/pages/order-fail/order-fail.component";
import {CourseDetailsPageComponent} from "./col/pages/course-details-page/course-details-page.component";
import {FaqListComponent} from "./pages/faq/faq-list/faq-list.component";
import {TypographyComponent} from "./pages/typography/typography.component";
import {
  UnaffiliatedDashboardLayoutComponent
} from "./layouts/unaffiliated-dashboard-layout/unaffiliated-dashboard-layout.component";
import {
  ForgotPasswordEntryPointComponent
} from "./mobile/forgot-password-entry-point/forgot-password-entry-point.component";
import {BookDetailsComponent} from "./books/pages/book-details/book-details.component";
import {AccessListGuardService} from "./auth_profile/services/access-list-guard.service";
import {TeacherProfilePageComponent} from "./auth_profile/pages/teacher-profile-page/teacher-profile-page.component";
import {AuthGuardService} from "./auth_profile/services/auth-guard.service";
import {
  StudentCourseLessonDetailsPageComponent
} from "./col/pages/student-course-lesson-details-page/student-course-lesson-details-page.component";
import {RoleGuardService} from "./auth_profile/services/role-guard.service";
import {ExerciseDetailsComponent} from "./cspa/pages/exercise-details/exercise-details.component";
import {
  StudentLessonDetailsPageComponent
} from "./col/pages/student-lesson-details-page/student-lesson-details-page.component";
import {
  TeacherRoomDetailsPageComponent
} from "./lessons/pages/teacher-room-details-page/teacher-room-details-page.component";
import {RoomDetailsPageComponent} from "./lessons/pages/room-details-page/room-details-page.component";
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {TeacherDashboardLayoutComponent} from "./layouts/teacher-dashboard-layout/teacher-dashboard-layout.component";
import {ExerciseListComponent} from "./cspa/components/exercise-list/exercise-list.component";
import {
  TeacherGroupDetailsPageComponent
} from "./lessons/pages/teacher-group-details-page/teacher-group-details-page.component";
import {inject, NgModule} from "@angular/core";
import {
  TeacherLessonDetailsPageComponent
} from "./col/pages/teacher/teacher-lesson-details-page/teacher-lesson-details-page.component";
import {ContactUsPageComponent} from "./pages/contact-us/contact-us-page/contact-us-page.component";
import {StudentDashboardLayoutComponent} from "./layouts/student-dashboard-layout/student-dashboard-layout.component";
import {LogoutPageComponent} from "./auth_profile/pages/logout-page/logout-page.component";
import {OrderSuccessComponent} from "./col/pages/order-success/order-success.component";
import {AccessRequired} from "./utils/services/functional-access.service";
import {TeacherGroupsPageComponent} from "./lessons/pages/teacher-groups-page/teacher-groups-page.component";
import {UnderDevelopmentComponent} from "./lessons/pages/under-development/under-development.component";
import {LessonsPageComponent} from "./col/pages/lessons-page/lessons-page.component";
import {LoginPageComponent} from "./auth_profile/pages/login-page/login-page.component";
import {SchoolGuardService} from "./col/services/school-guard.service";
import {ForgotPasswordComponent} from "./auth_profile/pages/forgot-password/forgot-password.component";
import {
  TeacherDashboardPageComponent
} from "./col/pages/teacher/teacher-dashboard-page/teacher-dashboard-page.component";
import {ExerciseHostComponent} from "./cspa/pages/exercise-host/exercise-host.component";
import {TeacherLessonsPageComponent} from "./col/pages/teacher/teacher-lessons-page/teacher-lessons-page.component";
import {BookListComponent} from "./books/pages/book-list/book-list.component";
import {AppEntryPointComponent} from "./pages/app-entry-point/app-entry-point.component";
import {RootComponent} from "./auth_profile/pages/root/root.component";
import {
  TeacherScheduleDetailsPageComponent
} from "./lessons/pages/teacher-schedule-details-page/teacher-schedule-details-page.component";
import {TeacherLessonsPlanerComponent} from "./lessons/pages/teacher-lessons-planer/teacher-lessons-planer.component";
import {
  TeacherContactUsPageComponent
} from "./pages/contact-us/teacher-contact-us-page/teacher-contact-us-page.component";
import {
  StudentContactUsPageComponent
} from "./pages/contact-us/student-contact-us-page/student-contact-us-page.component";
import {UserRole} from "./auth_profile/services/api/authorization.api";
import {BookDetailsEntryPointComponent} from "./mobile/book-details-entry-point/book-details-entry-point.component";
import {ForbiddenComponent} from "./pages/forbidden/forbidden.component";
import {LessonsPlanerComponent} from "./lessons/pages/lessons-planer/lessons-planer.component";
import {OauthComponent} from "./auth_profile/pages/oauth/oauth.component";
import {
  TeacherGroupRoomDetailsPageComponent
} from "./lessons/pages/teacher-group-room-details-page/teacher-group-room-details-page.component";
import {environment} from "../environments/environment";
import {DocumentsBrowserPageComponent} from "./lessons/pages/documents-browser-page/documents-browser-page.component";
import {ProgressPageComponent} from "./col/pages/student/progress-page/progress-page.component";
import {ReserveLessonPageComponent} from "./col/pages/student/reserve-lesson-page/reserve-lesson-page.component";
import {CreditsPageComponent} from "./col/pages/credits-page/credits-page.component";
import {CourseProductsPageComponent} from "./col/pages/course-products-page/course-products-page.component";
import {TeacherListPageComponent} from "./col/pages/teacher-list-page/teacher-list-page.component";
import {CoursesPageComponent} from "./col/pages/courses-page/courses-page.component";
import {SchoolGuardRedirectService} from "./col/services/school-guard-redirect.service";
import {TeacherSchoolGuardRedirectService} from "./col/services/teacher-school-guard-redirect.service";
import {ExamDetailsComponent} from "./cspa/pages/exam-details/exam-details.component";
import {ExamPartDetailsComponent} from "./cspa/pages/exam-part-details/exam-part-details.component";
import {ExamHostComponent} from "./cspa/pages/exam-host/exam-host.component";
import {
  TeacherStudentsListPageComponent
} from "./lessons/pages/teacher-students-list-page/teacher-students-list-page.component";
import {
  TeacherStudentDetailsLayoutPageComponent
} from "./lessons/pages/teacher-student-details-layout-page/teacher-student-details-layout-page.component";
import {
  TeacherStudentExamResultsComponent
} from "./lessons/components/teacher-student-exam-results/teacher-student-exam-results.component";
import {
  TeacherStudentCspaMonitorComponent
} from "./lessons/components/teacher-student-cspa-monitor/teacher-student-cspa-monitor.component";

const routes: Routes = [
  { path: '', component: RootComponent },
  { path: 'oauth', component: OauthComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: 'logout', component: LogoutPageComponent },
  { path: 'order-success', component: OrderSuccessComponent },
  { path: 'order-fail', component: OrderFailComponent },
  { path: 'unaffiliated', component: UnaffiliatedDashboardLayoutComponent,
    canActivate: [AuthGuardService, RoleGuardService],
    data: {roles: [UserRole.Unaffiliated]},
      children: [
        { path: '', redirectTo:'ebooks', pathMatch: 'full'},
        { path: 'ebooks', component: BookListComponent },
        { path: 'ebooks/:ebookId', component: BookDetailsComponent, data: {hideShortcutBarSource: true}},
        { path: 'contact-us', component: StudentContactUsPageComponent,
          children: [
            { path: '', component: ContactUsPageComponent },
            { path: 'result', component: ContactUsResultPageComponent }
          ]
        }
      ]
    },
  { path: 'student', component: StudentDashboardLayoutComponent,
    canActivate: [AuthGuardService, RoleGuardService], //defile guards services
    data: { roles: [UserRole.Student] }, // define roles
      children: [
        { path: '', component: DashboardComponent, canActivate: [SchoolGuardRedirectService]},
        { path: 'dashboard', component: DashboardComponent, canActivate: [SchoolGuardService], data: { schoolId: 1 } },
        { path: 'ebooks', component: BookListComponent },
        { path: 'ebooks/:ebookId', component: BookDetailsComponent, data: {hideShortcutBarSource: true}},
        { path: 'exercises', component: ExerciseListComponent},
        { path: 'exercises/:exerciseSet/:chapter',
          component: ExerciseDetailsComponent, data: {hideShortcutBarSource: true} },
        { path: 'exercises/:exerciseSet/:chapter/:section/:exerciseNb/session/:sessionId/:qNb',
          component: ExerciseHostComponent, data: {hideShortcutBarSource: true} },
        { path: 'exercises/:exerciseSet/:chapter/:section/:exerciseNb/exam-session/:sessionId',
          component: ExamDetailsComponent, data: {hideShortcutBarSource: true} },
        { path: 'exercises/:exerciseSet/:chapter/:section/:exerciseNb/exam-session/:sessionId/finish',
          component: ExamDetailsComponent, data: {hideShortcutBarSource: true} },
        { path: 'exercises/:exerciseSet/:chapter/:section/:exerciseNb/exam-session/:sessionId/part/:sessionPartId',
          component: ExamPartDetailsComponent, data: {hideShortcutBarSource: true} },
        { path: 'exercises/:exerciseSet/:chapter/:section/:exerciseNb/exam-session/:sessionId/part/:sessionPartId/:qNb',
          component: ExamHostComponent, data: {hideShortcutBarSource: true} },
        // lessons for callan online student
        {
          path: 'col-lessons/school/1/student/:studentId',
          component: LessonsPageComponent,
          canActivate: [SchoolGuardService],
          data: { schoolId: 1 },
        },
        {
          path: 'col-lessons/school/1/student/:studentId/lesson/:lessonId',
          component: StudentLessonDetailsPageComponent,
          canActivate: [SchoolGuardService],
          data: { schoolId: 1 },
        },
        {
          path: 'lessons/school/:schoolId',
          component: LessonsPlanerComponent,
          canActivate: [AccessListGuardService],
          data: { require: ['video'] },
        },
        { path: 'lessons/under-development', component: UnderDevelopmentComponent,
          canActivate: [AccessListGuardService], data: {require: ["video"]}},
        { path: 'lessons/school/:schoolId/student/:studentId', component: LessonsPlanerComponent,
        canActivate: [AccessListGuardService], data: {require: ["video"]} },
        { path: 'lessons/school/:schoolId/student/:studentId/room/:roomId', component: RoomDetailsPageComponent,
          canActivate: [AccessListGuardService], data: {require: ["video"]} },
        { path: 'lessons/school/:schoolId/student/:studentId/schedule/:scheduleId', component: ScheduleDetailsPageComponent,
          canActivate: [AccessListGuardService], data: {require: ["video"]} },
        { path: 'notifications', component: NotificationListComponent },
        { path: 'notifications/:notificationId', component: NotificationDetailsComponent },
        { path: 'faq', component: FaqListComponent },
        { path: 'profile',
          children: [
            {path: '', redirectTo:'profile-form', pathMatch: 'prefix' },
            { path: ':profileOption', component: StudentProfilePageComponent }
          ]},
        { path: 'contact-us', component: StudentContactUsPageComponent,
          children: [
            { path: '', component: ContactUsPageComponent },
            { path: 'result', component: ContactUsResultPageComponent }
          ]
        },
        { path: 'courses', component: CoursesPageComponent,
          canActivate: [SchoolGuardService], data: { schoolId: 1 } },
        {
          path: 'courses/details/:langCode/:chosenSection',
          component: CourseDetailsPageComponent,
          canActivate: [SchoolGuardService], data: { schoolId: 1 } },
        {
          path: 'courses/details/:langCode/lessons/lesson/:lessonId',
          component: StudentCourseLessonDetailsPageComponent,
          canActivate: [SchoolGuardService], data: { schoolId: 1 }
},
        { path: 'progress', component: ProgressPageComponent,
          canActivate: [SchoolGuardService], data: { schoolId: 1 } },
        { path: 'reserve-lesson', component: ReserveLessonPageComponent,
          canActivate: [SchoolGuardService], data: { schoolId: 1 } },
        { path: 'credits', component: CreditsPageComponent,
          canActivate: [SchoolGuardService], data: { schoolId: 1 } },
        { path: 'plans', component: CourseProductsPageComponent,
          canActivate: [SchoolGuardService], data: { schoolId: 1 } },
        { path: 'teachers', component: TeacherListPageComponent,
          canActivate: [SchoolGuardService], data: { schoolId: 1 } },
      ]
  },
  { path: 'teacher', component: TeacherDashboardLayoutComponent,
    canActivate: [AuthGuardService, RoleGuardService], // defile guards services
    data: {roles: [UserRole.Teacher]}, // define roles
      children: [
        // { path: '', redirectTo:'dashboard', pathMatch: 'full'},
        { path: '', component: TeacherDashboardPageComponent, canActivate: [TeacherSchoolGuardRedirectService]},
        { path: 'dashboard', component: DashboardComponent},
        { path: 'ebooks', component: BookListComponent },
        { path: 'ebooks/:ebookId', component: BookDetailsComponent, data: {hideShortcutBarSource: true}},
        { path: 'exercises', component: ExerciseListComponent},
        { path: 'exercises/:exerciseSet/:chapter',
          component: ExerciseDetailsComponent, data: {hideShortcutBarSource: true} },
        { path: 'exercises/:exerciseSet/:chapter/:section/:exerciseNb/session/:sessionId/:qNb',
          component: ExerciseHostComponent, data: {hideShortcutBarSource: true} },
        { path: 'exercises/:exerciseSet/:chapter/:section/:exerciseNb/exam-session/:sessionId',
          component: ExamDetailsComponent, data: {hideShortcutBarSource: true} },
        { path: 'exercises/:exerciseSet/:chapter/:section/:exerciseNb/exam-session/:sessionId/finish',
          component: ExamDetailsComponent, data: {hideShortcutBarSource: true} },
        { path: 'exercises/:exerciseSet/:chapter/:section/:exerciseNb/exam-session/:sessionId/part/:sessionPartId',
          component: ExamPartDetailsComponent, data: {hideShortcutBarSource: true} },
        { path: 'exercises/:exerciseSet/:chapter/:section/:exerciseNb/exam-session/:sessionId/part/:sessionPartId/:qNb',
          component: ExamHostComponent, data: {hideShortcutBarSource: true} },
        // lessons for callan online teacher
        {
          path: 'col-lessons/school/1/teacher/:teacherId',
          component: TeacherLessonsPageComponent,
          canActivate: [SchoolGuardService],
          data: { schoolId: 1 },
        },
        {
          path: 'col-lessons/school/1/teacher/:teacherId/lesson/:lessonId/student/:studentId',
          component: TeacherLessonDetailsPageComponent,
          canActivate: [SchoolGuardService],
          data: { schoolId: 1 },
        },

        { path: 'lessons', component: TeacherLessonsPlanerComponent,
          canActivate: [AccessListGuardService], data: {require: [AccessRequired.Video]} },
        { path: 'lessons/under-development', component: UnderDevelopmentComponent,
          canActivate: [AccessListGuardService], data: {require: ["video"]}},
        { path: 'lessons/school/:schoolId/teacher/:teacherId/:selectedSection', component: TeacherLessonsPlanerComponent,
          canActivate: [AccessListGuardService], data: {require: [AccessRequired.Video]} },
        { path: 'lessons/school/:schoolId/teacher/:teacherId/room/:roomId', component: TeacherRoomDetailsPageComponent,
          canActivate: [AccessListGuardService], data: {require: [AccessRequired.Video]} },
        { path: 'lessons/school/:schoolId/teacher/:teacherId/schedule/:scheduleId', component: TeacherScheduleDetailsPageComponent,
          canActivate: [AccessListGuardService], data: {require: [AccessRequired.Video]} },
        { path: 'groups/school/:schoolId', component: TeacherGroupsPageComponent },
        { path: 'groups/school/:schoolId/group/:groupId', component: TeacherGroupDetailsPageComponent },
        { path: 'groups/school/:schoolId/group/:groupId/room/:roomId', component: TeacherGroupRoomDetailsPageComponent,
          canActivate: [AccessListGuardService], data: {require: [AccessRequired.Video]} },
        { path: 'groups/school/:schoolId/group/:groupId/schedule/:scheduleId', component: TeacherGroupScheduleDetailsPageComponent,
          canActivate: [AccessListGuardService], data: {require: [AccessRequired.Video]} },
        { path: 'notifications', component: NotificationListComponent },
        { path: 'notifications/:notificationId', component: NotificationDetailsComponent },
        { path: 'faq', component: FaqListComponent },
        { path: 'profile',
          children: [
            {path: '', redirectTo:'profile-form', pathMatch: 'prefix' },
            { path: ':profileOption', component: TeacherProfilePageComponent }
          ]
        },
        { path: 'contact-us', component: TeacherContactUsPageComponent,
          children: [
            { path: '', component: ContactUsPageComponent },
            { path: 'result', component: ContactUsResultPageComponent }
          ]
        },
        {
          path: 'panel/:chosenSection',
          component: TeacherDashboardPageComponent,
          canActivate: [SchoolGuardService],
          data: { schoolId: 1 },
        },
        {
          path: 'panel/lessons/lesson/:lessonId/student/:studentId',
          component: TeacherDashboardLessonsDetailsPageComponent,
          canActivate: [SchoolGuardService],
          data: { schoolId: 1 },
        },
        {
          path: 'students',
          component: TeacherStudentsListPageComponent
        },
        {
          path: 'students/:studentId',
          component: TeacherStudentDetailsLayoutPageComponent,
          children: [
            {
              path: 'cspa-monitor',
              component: TeacherStudentCspaMonitorComponent,
            },
            {
              path: 'exam-results',
              component: TeacherStudentExamResultsComponent,
            },
          ]
        },
        {
          path: 'documents',
          children: [
            {path: '**', component: DocumentsBrowserPageComponent}
          ],
        }
      ]
  },
  {
    path: 'in-app',
    children: [
      { path: 'student', redirectTo: '/dashboard', pathMatch: 'full' },
      { path: 'teacher', redirectTo: '/dashboard', pathMatch: 'full' },
      { path: 'login', component: LoginPageComponent },
      { path: 'logout', component: LoginPageComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'styling', component: StylingComponent },
      { path: 'typography', component: TypographyComponent },
      { path: 'contact-us', component: ContactUsPageComponent },
      { path: 'contact-us/result', component: ContactUsResultPageComponent },
    ]
  },
  {
    path: 'entry-points',
    children: [
      { path: '', redirectTo: 'ebooks', pathMatch: 'full' },
      { path: 'main', component: AppEntryPointComponent },
      { path: 'ebooks', component: BookListEntryPointComponent },
      { path: 'ebooks/:ebookId', component: BookDetailsEntryPointComponent },
      { path: 'login', component: LoginEntryPointComponent },
      { path: 'forgot-password', component: ForgotPasswordEntryPointComponent },
      { path: 'contact-us', component: ContactUsEntryPointComponent },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: environment.platform.useHash }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
