<div class="workspace bg-radial">
  <top-bar class="d-none d-md-block">
    <content class="pt-0 pt-sm-2 mb-md-4 mx-2 mx-sm-4 justify-content-start justify-content-md-center">
      <button
        (click)="endSession()"
        class="btn btn-outline-secondary rounded-pill btn-back btn-back-size"
        type="button"
      >
        <app-icon class="icon-sm" name="arrow-back"></app-icon>
        <span class="d-none d-lg-block ms-3">
          {{ "NAVIGATION.EXERCISE_SET" | translate }}
        </span>
      </button>
      <div class="d-md-none d-flex flex-column">
        <span>{{ getExerciseType() }}</span>
        <span>{{ getChapter() }}, {{ getExerciseName() }}</span>
      </div>
      <div class="d-none d-md-block text-center">
        <h5>{{ getExerciseType() }}</h5>
        <span class="d-md-none text-muted">
          {{ getChapter() }}, {{ getExerciseName() }}
        </span>
      </div>
    </content>
    <footer class="d-none d-md-block">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a (click)="onFinish()" [routerLink]="getExerciseRouterLink()">
              {{ "NAVIGATION.EXERCISES" | translate }}
            </a>
          </li>
          <li class="breadcrumb-item">
            <a (click)="onFinish()" [routerLink]="getExerciseRouterLink()">{{ getExerciseSet() }}</a>
          </li>
          <li class="breadcrumb-item">
            <a (click)="onFinish()" [routerLink]="getChapterRouterLink()">{{
                getChapter()
              }}</a>
          </li>
          <li aria-current="page" class="breadcrumb-item active">
            {{ getExerciseName() }}
          </li>
        </ol>
      </nav>
    </footer>
  </top-bar>

  <div class="d-md-none">
    <div class="exercise-navbar d-flex mx-3 mx-sm-4 justify-content-between align-items-center">
      <button
        (click)="endSession()"
        *ngIf="!isSummaryDisplayed()"
        class="btn btn-outline-secondary rounded-pill btn-back-size"
        type="button"
      >
        <app-icon name="arrow-back"></app-icon>
      </button>
      <div class="d-flex flex-column">
        <span>{{ getExerciseType() }}</span>
        <span>{{ getChapter() }}, {{ getExerciseName() }}</span>
      </div>
      <button
        aria-controls="offcanvasExerciseOptions"
        class="d-md-none border-0 bg-transparent px-0"
        data-bs-target="#offcanvasExerciseOptions"
        data-bs-toggle="offcanvas"
        type="button"
      >
        <app-icon name="dots-options"></app-icon>
      </button>
    </div>
  </div>
  <div class="mt-4 mx-4 d-md-none">
    <app-progress-bar *ngIf="!isSummaryDisplayed()">
    </app-progress-bar>
  </div>
  <div class="container mt-3 mt-sm-4 mb-5 px-5">
    <div *ngIf="!isFinished" class="question-nav row mb-md-4 pb-2">
      <div *ngIf="!isSummaryDisplayed()" class="col d-none d-md-block my-auto">
        <app-progress-bar *ngIf="!isSummaryDisplayed()">
        </app-progress-bar>
      </div>
      <div class="btn-nav col-auto d-flex ms-auto">
        <button
          (click)="getHint()"
          *ngIf="isHintShown()"
          class="btn btn-round px-2 ms-3"
          type="button"
        >
          <app-icon name="help"></app-icon>
        </button>
        <button
          aria-controls="offcanvasExerciseOptions"
          class="d-none d-md-block border-0 bg-transparent px-0"
          data-bs-target="#offcanvasExerciseOptions"
          data-bs-toggle="offcanvas"
          type="button"
        >
          <app-icon name="dots-options"></app-icon>
        </button>
      </div>
    </div>
    <div class="mb-4">
      <p [innerHTML]="getInstruction()" class="my-2 my-md-3 text-center text-muted">
      </p>
      <ng-template loadQuestion></ng-template>
    </div>

    <div *ngIf="!isFinished" class="navbar-nav bottom-bar-padding">
      <div class="nav-item my-auto">
        <app-icon
          (click)="goToPrevious()"
          [ngClass]="{ 'icon-filled-gray pe-none' : +questionNb == 1 }"
          class="md-icon"
          name="arrow-back"
        ></app-icon>
      </div>
      <div
        *ngFor="let questionRequest of questionRequests"
        class="nav-item my-auto"
      >
        <div *ngIf="questionRequest" class="position-relative">
          <app-icon
            (click)="onShortcutClick(questionRequest.text);
                      questionRequest.text == 'speaker' ? isVolumeRangeShown = !isVolumeRangeShown : ''"
            *ngIf="questionRequest.assetUrl"
            [class]="questionRequest.assetUrl === 'mic' ? '' : 'icon-filled-blue' "
            [name]="questionRequest.assetUrl"
            class="icon-sm"
          ></app-icon>
          <ng-container *ngIf="questionRequest.text == 'speaker' && isVolumeRangeShown">
            <input (input)="onShortcutClick(questionRequest.text + ':' + $event.target['value']); setRangeStyle($event.target['value'])" [value]="getQaRequestValue(questionRequest)" class="form-range speaker-range"
                   id="volumeRange"
                   type="range"
            >
          </ng-container>
        </div>
      </div>
      <div *ngIf="!questionRequests" class="nav-item pe-none">
      </div>
      <div class="nav-item my-auto justify-content-center">
        <app-icon
          *ngIf="isBtnBlocked()"
          class="icon-filled-gray tooltip md-icon"
          id="buttonNext"
          name="arrow-next"
        >
        <span class="tooltip-text">
          {{ getTooltipInfo() | translate }}
        </span>
        </app-icon>

        <app-icon
          (click)="onCheck()"
          *ngIf="!isBtnBlocked()"
          class="icon-stroke-blue md-icon"
          name="arrow-next"
        >
        </app-icon>
      </div>
    </div>
  </div>
</div>
<div
  aria-labelledby="offcanvasExerciseOptionsLabel"
  class="offcanvas-exercises offcanvas offcanvas-menu offcanvas-end"
  id="offcanvasExerciseOptions"
  tabindex="-1"
>
  <div class="offcanvas-body p-0 pt-4 pt-sm-0 d-flex">
    <app-exercises-options class="w-100">
      <content>
        <button
          (click)="endSession()"
          class="btn btn-primary rounded-pill"        
          data-bs-dismiss="offcanvas"
          type="button"
        >
          {{ "EXERCISES.END.END_SESSION_EXERCISE.YES" | translate }}
        </button>
      </content>
    </app-exercises-options>
  </div>
</div>
