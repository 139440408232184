import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LessonType } from '../../model/calendar';
import {FilterDefinition} from "../../../model/CourseFilters";
import { CourseFilterComponent } from '../../../utils/course-filter/course-filter.component';
import {Page, Pageable} from "../../../utils/pageable";
import {merge, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {ApiLearningUnitTeacher, ApiLessonInstance, ApiPersonalProfile} from "../../model/rest-model";
import {SpinnerService} from "../../../utils/services/spinner.service";
import {StudentRestServiceImpl} from "../../services/student/student-rest-impl.service";
import { WebSocketService } from '../../services/web-socket.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lessons-page',
  templateUrl: './lessons-page.component.html',
  styleUrls: ['./lessons-page.component.scss']
})
export class LessonsPageComponent implements OnInit, AfterViewInit, OnDestroy {

  _chosenCourse: FilterDefinition
  set chosenCourse(lang: FilterDefinition) {
    this._chosenCourse = lang
    this.getFutureLessons().subscribe()
  }
  get chosenCourse() {
    return this._chosenCourse
  }

  studentId
  lessonTypes = LessonType;
  CourseFilterComponent = CourseFilterComponent;
  lessons: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;
  wsSubscription: Subscription

  constructor(private route: ActivatedRoute,
              private spinner: SpinnerService,
              private studentRest: StudentRestServiceImpl,
              private cdr: ChangeDetectorRef,
              private webSocket: WebSocketService) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      tap(params => { this.studentId = params.get("studentId")}),
      switchMap(_ => this.getFutureLessons()),
      tap(_ => this.wsSubscription = this.listenToWebSocket())
    ).subscribe()
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.wsSubscription?.unsubscribe()
  }

  getFutureLessons(): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    if (!this.studentId || !this._chosenCourse)
      return of(null);
    return this.spinner.trace<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(
      this.studentRest
        .getLessons(
          this.studentId,
          new Pageable(0, 10, [
            'lessonStudent.metricDetails.plannedStartDate,ASC',
          ]),
          LessonType.Upcoming,
          this._chosenCourse.filter
        )
        .pipe(
          tap((lessons) => (this.lessons = lessons)),
        )
    );
  }

  listenToWebSocket() {
    if (!this.studentId) return null
    if(!this.wsSubscription) {
      return this.webSocket.establish(environment.lessonWsEndpoint).pipe(
        switchMap(connection => merge(
          connection.subscribe(`/col/student/${this.studentId}/LessonListUpdate`),
          connection.subscribe(`/col/student/${this.studentId}/LessonUpdate`)
        )),
        switchMap(() => this.getFutureLessons())
      ).subscribe()
    }
    else return this.wsSubscription
  }
}
