<div class="workspace bg-linear-radial">
  <top-bar class="accent" [ngClass]="getAccent()"
           [titleTranslationCode]="'EBOOKS.TITLE'"
           [redirectLink]="'../'"
           [redirectTranslationCodes]="'NAVIGATION.EBOOKS'">
    <footer>
      <div class="d-none d-md-block">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a routerLink="../">
                {{ "EBOOKS.TITLE" | translate }}
              </a>
            </li>
            <li class="breadcrumb-item">
              <a routerLink="../">
                {{ "EBOOKS.FILTER_LANGS.ALL" | translate }}
              </a>
            </li>
            <li class="breadcrumb-item">
              <a (click)="goToEbooksListWithFilter(getEbookLanguage())">
                {{ getEbookLanguageTranslation() | translate }}
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ getEbookName() }}
            </li>
          </ol>
        </nav>
      </div>
    </footer>
  </top-bar>
  <div class="container">
    <div class="row mb-4 mb-md-0 justify-content-between">
      <div
        class="col-6 col-md-12 col-xl-6 order-md-2 order-xl-1 placeholder-glow d-flex flex-column justify-content-between align-items-start"
      >
        <div class="col-12 col-md-8">
          <!-- ebook language -->
          <span
            *ngIf="isContentLoading"
            class="placeholder bg-secondary col-5 col-md-4 rounded mb-2"
          ></span>
          <span *ngIf="!isContentLoading" class="text-muted">
            {{ getEbookLanguage() | translate }}
          </span>
          <!-- ebook name -->
          <span
            *ngIf="isContentLoading"
            class="placeholder bg-light col-8 col-md-9 rounded mb-3 mb-md-4"
          ></span>
          <h3 *ngIf="!isContentLoading" class="mb-3">{{ getEbookName() }}</h3>
        </div>
        <!-- available shares -->
        <span
          *ngIf="isContentLoading"
          class="placeholder bg-light col-9 rounded mb-3"
        ></span>
        <span *ngIf="hasProduct() && !isContentLoading" class="mb-3">
          {{ "EBOOKS.DOWNLOADED_COPIES" | translate : getShares() }}
        </span>
        <span
          *ngIf="isContentLoading && !isNative()"
          class="placeholder bg-danger col-9 rounded mb-3"
        ></span>
        <span
          *ngIf="!isContentLoading && !isNative()"
          class="text-danger lh-sm mb-3"
        >
          {{ "EBOOKS.CANT_OPEN_VIA_BROWSER" | translate }}
        </span>
        <!-- description -->
        <span
          *ngIf="isContentLoading"
          class="placeholder bg-secondary d-none d-md-block col-12 rounded mb-1"
        ></span>
        <span
          *ngIf="isContentLoading"
          class="placeholder bg-secondary d-none d-md-block col-12 rounded mb-1"
        ></span>
        <span
          *ngIf="isContentLoading"
          class="placeholder bg-secondary d-none d-md-block col-12 rounded mb-1"
        ></span>
        <span
          *ngIf="isContentLoading"
          class="placeholder bg-secondary d-none d-md-block col-12 rounded mb-1"
        ></span>
        <span
          *ngIf="isContentLoading"
          class="placeholder bg-secondary d-none d-md-block col-12 rounded mb-3"
        ></span>
        <p *ngIf="!isContentLoading" class="text-muted d-none d-md-block">
          {{ getEbookDescription() }}
        </p>
      </div>
      <div
        class="col-6 col-xl-auto placeholder-glow text-end text-md-start order-md-0 mb-md-4"
      >
        <span
          *ngIf="isImgLoading"
          class="placeholder bg-primary book-cover rounded"
        ></span>
        <div *ngIf="!isImgLoading" class="position-relative">
          <circular-progress
            *ngIf="isDownloading()"
            [progress]="downloadProgress"
            class="d-block position-absolute circular-progress"
          >
          </circular-progress>
          <img
            class="book-cover"
            [src]="getEbookCover()"
            [ngClass]="{ 'opacity-75': isDownloading() }"
          />
        </div>
      </div>
      <div
        class="d-none d-md-block col-4 col-xl-auto offset-2 offset-xl-0 order-md-1 order-xl-2"
        *ngIf="isNative()"
      >
        <a
          class="nav-link text-center mb-3"
          (click)="onAction()"
          *ngIf="bookDetails.hasAction(localState)"
        >
          {{ getActionName() | translate }}
        </a>
      </div>
      <div
        class="hidden shown-md col-6 col-xl-3 d-md-flex mb-4 flex-column order-md-1 order-xl-2 gap-2"
        *ngIf="!isNative()"
      >
        <p class="text-muted text-center mb-0">
          {{ "EBOOKS.DOWNLOAD_APP" | translate }}
        </p>
        <a
          type="button"
          class="btn btn-secondary btn-lg px-4"
          [href]="goToDownload('iOS')"
        >
          <app-icon name="download-app-store"></app-icon>
        </a>
        <a
          type="button"
          class="btn btn-secondary btn-lg px-4"
          [href]="goToDownload('android')"
        >
          <app-icon name="download-google-play"></app-icon>
        </a>
      </div>
    </div>
    <ul *ngIf="bookDetails.mayOpen(localState)" class="list-group d-flex mb-5">
      <p *ngIf="bookDetails.hasAudio(localState) || bookDetails.hasDictations(localState)"
         class="xsmall mb-1">
        {{ "EBOOKS.TO_DOWNLOAD.HEADER" | translate }}
      </p>
      <!-- audio -->
      <li class="list-group-item" *ngIf="bookDetails.hasAudio(localState)">
        <div class="download-content">
          <h4 class="m-0 white-text">
            {{ "EBOOKS.TO_DOWNLOAD.AUDIO.TITLE" | translate }}
          </h4>
          <span class="text-muted xsmall">
            {{
              "EBOOKS.TO_DOWNLOAD.AUDIO.CONTENT" | translate : getAudioSize()
            }}
          </span>
        </div>
        <circular-progress
          *ngIf="audioDisabled"
          [progress]="audioProgress"
          class="d-block circular-progress"
        >
        </circular-progress>
        <button
          type="button"
          class="btn btn-outline-secondary rounded-pill btn-sm"
          *ngIf="
            !audioDisabled &&
            !audioProgress &&
            !bookDetails.getAudioDownloaded(localState)
          "
          (click)="downloadAudio()"
        >
          <app-icon class="icon-font-color" name="download"></app-icon>
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary rounded-pill btn-sm"
          *ngIf="
            !audioDisabled &&
            !audioProgress &&
            bookDetails.getAudioDownloaded(localState)
          "
          (click)="deleteAudio()"
        >
          <app-icon class="icon-font-color" name="delete"></app-icon>
        </button>
      </li>
      <!-- dictation -->
      <li class="list-group-item" *ngIf="bookDetails.hasDictations(localState)">
        <div class="download-content">
          <h4 class="m-0 white-text">
            {{ "EBOOKS.TO_DOWNLOAD.DICTATION.TITLE" | translate }}
          </h4>
          <span class="text-muted xsmall">
            {{
              "EBOOKS.TO_DOWNLOAD.DICTATION.CONTENT"
                | translate : getDictationSize()
            }}
          </span>
        </div>
        <circular-progress
          *ngIf="dictDisabled"
          [progress]="dictProgress"
          class="d-block circular-progress"
        >
        </circular-progress>
        <button
          type="button"
          class="btn btn-outline-secondary rounded-pill btn-sm"
          *ngIf="
            !dictDisabled &&
            !dictProgress &&
            !bookDetails.getDictationDownloaded(localState)
          "
          (click)="downloadDict()"
        >
          <app-icon class="icon-font-color" name="download"></app-icon>
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary rounded-pill btn-sm"
          *ngIf="
            !dictDisabled &&
            !dictProgress &&
            bookDetails.getDictationDownloaded(localState)
          "
          (click)="deleteDict()"
        >
          <app-icon class="icon-font-color" name="delete"></app-icon>
        </button>
      </li>
    </ul>
    <div class="btn-bottom d-flex d-md-none">
      <button
        type="button"
        (click)="onAction()"
        [disabled]="actionDisabled"
        *ngIf="bookDetails.hasAction(localState) && isNative()"
        class="btn btn-primary rounded-pill btn-lg fs-6 text-uppercase w-100"
      >
        {{ getActionName() | translate }}
      </button>
      <div class="w-100" *ngIf="!isNative()">
        <h5 class="text-muted text-center mb-1 py-2 px-3">
          {{ "EBOOKS.DOWNLOAD_APP" | translate }}
        </h5>
        <a
          type="button"
          class="btn btn-secondary btn-lg px-4 mb-2 w-100"
          [href]="goToDownload('iOS')"
        >
          <app-icon name="download-app-store"></app-icon>
        </a>
        <a
          type="button"
          class="btn btn-secondary btn-lg px-4 mb-2 w-100"
          [href]="goToDownload('android')"
        >
          <app-icon name="download-google-play"></app-icon>
        </a>
      </div>
    </div>
  </div>
</div>
