import {Component, ComponentRef} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {switchMap, tap} from 'rxjs';
import {ExerciseSession} from '../../model/cspa/personal';
import {QuestionService} from '../../services/api/impl/rest/question.service';
import {ShortcutService} from '../../services/shortcut.service';
import {NativeServiceApiProvider} from "../../../services/native-api-provider.service";
import {ConfirmDialogService} from 'src/app/utils/services/confirm-dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {OffcanvasMenuService} from 'src/app/services/offcanvas-menu.service';
import {SessionInfoService} from 'src/app/services/session-info.service';
import {HostBaseComponent} from "../../model/hostBase/host-base.component";
import {DictComponent} from "../../components/exercises/dict/dict.component";
import { FontSizeService } from 'src/app/services/font-size.service';

@Component({
  selector: 'app-exercise-host',
  templateUrl: './exercise-host.component.html',
  styleUrls: ['../../model/hostBase/host-base.component.scss']
})
export class ExerciseHostComponent extends HostBaseComponent<ExerciseSession> {
  constructor(
    route: ActivatedRoute,
    confirmDialogService: ConfirmDialogService,
    translateService: TranslateService,
    offcanvasMenuService: OffcanvasMenuService,
    shortcut: ShortcutService,
    questionService: QuestionService,
    progressInfoService: SessionInfoService,
    private provider: NativeServiceApiProvider,
    private router: Router,
    fontSizeService: FontSizeService
  ) {
    super(
      route,
      confirmDialogService,
      translateService,
      offcanvasMenuService,
      shortcut,
      questionService,
      progressInfoService,
      fontSizeService
    )
  }

  override ngOnInit(): void {
    super.ngOnInit()
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy()
  }

  retrieveFromParamMap(_: ParamMap) {
  }

  getQuestions() {
    return this.session?.questions
  }

  getSession() {
    return this.provider.cspa().pipe(
      switchMap(api =>
        api.getSession(this.uuId)
      ),
      tap(session => {
        this.session = session
        this.question = this.getQuestion()
        this.question && this.setupQuestionTimings(this.question)
        this.goToQuestion(this.questionNb)
      })
    )
  }

  getExerciseName() {
    return this.session?.exerciseName
  }

  getInstruction(){
    return this.question?.question?.definition?.instructions
  }

  goToQuestion(index: number | string) {
    if (index === 'finish' || +index > this.getQuestions().length) {
      this.isCheckBtnBlocked = true
      this.router.navigate(
        [`../finish`],
        {
          relativeTo: this.route,
          queryParamsHandling: 'merge'
        }
      ).then()
    } else {
      this.onLoadQuestion(this.getQuestions()[+index - 1])
      this.router.navigate(
        [`../${index}`],
        {
          relativeTo: this.route,
          queryParamsHandling: 'merge'
        }
      ).then()
    }
  }

  finishSession() {
    return this.provider.cspa().pipe(
      switchMap(api => {
        return api.finishSession(this.uuId)
      })
    );
  }

  goBack() {
    this.finishSession().subscribe(_ => {
      let redirectPath = this.questionNb !== 'finish' ?
        '../finish'
        : '../../../../../'
      this.router.navigate(
        [`${redirectPath}`],
        {
          relativeTo: this.route,
          queryParamsHandling: 'merge'
        }
      ).then()
    });
  }


  postAnswerAndGoToQuestion() {
    this.provider.cspa().pipe(
      switchMap(api =>
        api.postSessionQuestionAnswer(
          this.uuId, +this.questionNb - 1, this.question)
      )
    ).subscribe(_ => {
      this.goToQuestion(+this.questionNb + 1)
    })
  }

  prepareAudioProgressEmitter(_: ComponentRef<DictComponent>) {}
}
