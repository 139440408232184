import {PlatformDescriptor} from "../app/utils/platform-descriptor";

export enum Env {
  DEV, PROD, STAGE
}

export const environment = {
  production: false,
  env: Env.STAGE,
  debug: true,

  // ....

  platform: PlatformDescriptor.web,
  casaClientId: 'zxfwe09f3j34jq9q0jfqwejfoiqj9430fjfqjfqiwoefj',
  callanonlineId: 1,
  serverBase: 'https://student-area.callanworks.co.uk',
  authEndpoint: 'https://casa-dev.callanonline.com',
  casaEndpoint : 'https://casa-dev.callanonline.com',
  cspaEndpoint: 'https://cspa-dev.callanonline.com',
  appEndpoint: 'https://app-dev.callanonline.com',
  awsBase: 'https://s3.amazonaws.com',
  videoEndpoint:'https://api.video.stage.callanworks.co.uk/api',
  videoWebBase:'https://video.callanworks.co.uk',
  callanAppUrl: null,
  lessonWsEndpoint: 'wss://api.lessons.stage.callanworks.co.uk/ws',
  lessonEndpoint: 'https://api.lessons.stage.callanworks.co.uk/api',
  bookingEndpoint: 'https://api.booking.stage.callanworks.co.uk/api',
  cspaWsEndpoint: 'wss://cspa-dev.callanonline.com/cspa-websocket',
  fileEndpoint: 'https://api.lessons.stage.callanworks.co.uk/files',

  oldCallanServerBase: 'https://col2.stage.callanworks.co.uk',

  // ...

  sentryTarget: 'https://student-area.callanworks.co.uk',
  sentryDsn: "https://fe7ac6315fdf4d00aaaa9b8f51642810@o334018.ingest.sentry.io/4504321124401152",
  logToSentry: true,
  envName: 'web-stage',

  styleClass: 'web',

  colFunctionality: true,
  ssmiEnabled: false,
  scheduleBridgeEnabled: false
}
