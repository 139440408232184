import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {ExamSession, ExerciseSession, ItemAvailability} from '../../model/cspa/personal';
import {Chapter, Exam, Exercise, Section, SectionType} from '../../model/cspa/struct';
import { NativeServiceApiProvider } from '../../../services/native-api-provider.service';
import {combineLatest, of, switchMap} from 'rxjs';
import { AuthorizationServiceProvider } from 'src/app/auth_profile/services/authorization-service.provider';

@Component({
  selector: 'exercise-square',
  templateUrl: './exercise-square.component.html',
  styleUrls: ['./exercise-square.component.scss'],
})
export class ExerciseSquareComponent implements OnInit {
  private _section: Section
  @Input() availability: ItemAvailability;
  @Input() exercise: Exercise | Exam;
  @Input()
  get section(): Section {
    return this._section
  }
  set section(section: Section) {
    this._section = section
    this.sectionPath = section.path
  }
  sectionPath: string;
  @Input() chapter?: Chapter;
  @Input() exerciseSetPath?: string;

  path: string;
  fullpath: string;
  role: string;

  constructor(
    private provider: NativeServiceApiProvider,
    private router: Router,
    private route: ActivatedRoute,
    private authServiceProvider: AuthorizationServiceProvider
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.authServiceProvider.getAuthDetailsService().pipe(
        switchMap( detailsApi => detailsApi.getUserRole())
      ),
      this.route.paramMap
    ]).subscribe(([role, params]) => {
      this.role = role
      if (this.chapter && this.exerciseSetPath) {
        this.path = `${this.chapter.path}_${this.sectionPath}_${this.exercise.path}`;
        this.fullpath = (this.role === "Teacher" ? '/teacher/' : '/student/').concat('', `exercises/${this.exerciseSetPath}/${this.chapter.path}/${this.sectionPath}/${this.exercise.path}`)
      }
      else {
        this.path = `${params.get('chapter')}_${this.sectionPath}_${this.exercise.path}`;
        this.fullpath = (this.role === "Teacher" ? '/teacher/' : '/student/').concat('', `exercises/${params.get('exerciseSet')}/${params.get('chapter')}/${this.sectionPath}/${this.exercise.path}`)
      }
    })
  }

  getStatusClass(): string {
    if (!this.availability) return 'locked';
    if (!this.availability.assigned || !this.availability.available)
      return 'locked';
    if (this.availability.score === 0) return 'ready';
    else if (this.availability.score < 0.5) return 'bad';
    else if (this.availability.score < 0.95) return 'enough';
    else return 'perfect';
  }

  getName() {
    return this.exercise.shortName;
  }

  routeToTheSession(session: ExerciseSession) {
    this.router.navigate([`${this.fullpath}/session/${session.deviceUUID}/1`]).then();
  }

  routeToTheExamSession(session: ExamSession) {
    this.router.navigate([`${this.fullpath}/exam-session/${session?.uuid}`]).then();
  }

  startSession() {
    if (this.getStatusClass() == 'locked') return
    this.section.type == SectionType.S_EXAM
    ? this.startExamSession()
    : this.startExerciseSession()
  }

  private startExerciseSession() {
    this.provider
      .cspa()
      .pipe(switchMap((api) => api.startSession(this.path)))
      .subscribe((session) => {
        this.routeToTheSession(session);
      });
  }

  private startExamSession() {
    this.provider
      .cspa()
      .pipe(
        switchMap((api) => api.isOffline().pipe(
          switchMap(isOffline => isOffline ? of(null) : api.startExamSession(this.path))
        ))
      )
      .subscribe((session) => {
        this.routeToTheExamSession(session);
      });
  }
}
